<template>
  <div>
    <div class="d-flex flex-column flex-grow-1">
      <div class="animated fadeIn">
        <b-row>
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body class="card-themeview">
                <b-collapse id="collapse1" visible>
                  <b-card-body class="card-body-themeview">
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col v-for="(item, index) in items" :key="index" sm="4">
                          <div class="card-box active">
                            <div class="card-box-section">
                              <div class="card-box-content add-on-image">
                                <div class="add-on-screenshot" style="min-height: 15.2rem">
                                  <img :src="item.imageUrl" alt="Theme screenshot" class="theme-screenshot">
                                  <!--<img src="https://getallgpl.com/wp-content/uploads/2020/10/ANALYTICS-2-600x449-1-1.png" alt="Theme screenshot" class="theme-screenshot">-->
                                </div>
                              </div>
                              <div class="card-box-footer">
                                <div class="add-on-info">
                                  <div class="text-container">
                                    <h2 class="theme-name">{{ item.name }}</h2>
                                    <p class="theme-tagline">{{ item.tagline1 }}</p>
                                    <p class="theme-tagline">{{ item.tagline2 }}</p>
                                  </div>
                                </div>
                              </div>
                              <b-row class="add-on-actions">
                                <b-col sm="8">
                                  <span>Price: {{item.priceByPeriod}}</span>
                                </b-col>
                                <b-col sm="4">
                                  <div v-if="item.active">
                                    <h5>Active</h5>
                                  </div>
                                  <div v-else>
                                    <b-button type="submit" variant="secondary" @click="activeAddOn(item)" >Buy Now</b-button>
                                  </div>
                                </b-col>
                              </b-row>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'AddOnPage',
  data () {
    return {
      item: {},
      items: []
    }
  },
  created () {
    this.$loading.show()
    this.axios.get('add-on/list')
      .then(response => {
        this.items = response.data
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  methods: {
    activeAddOn (item) {
      if (item) {
        const axios = this.axios.put('add-on/active', item)
        axios.then(response => {
          if (response.status === 200) {
            this.$toastr.success(response.data)
            this.$router.go(this.$router.currentRoute)
          } else {
            this.$toastr.error(response.data.message)
          }
          this.$loading.hide()
        }).catch(error => {
          if (error.response && error.response.status === 400) {
            this.$toastr.error(error.response.data.errors.message)
          }
          this.$loading.hide()
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
