<template>
  <div>
    <b-modal ref="manufacturer-modal" hide-header hide-footer size="lg">
      <div class="d-block manufacturer-modal">
        <ManufacturerAddModal ref="customer" @manufacturerModalClose="manufacturerModalClose"/>
      </div>
    </b-modal>
    <b-modal ref="vendor-modal" hide-header hide-footer size="lg">
      <div class="d-block manufacturer-modal">
        <VendorAddModal ref="vendor" @vendorModalClose="vendorModalClose"/>
      </div>
    </b-modal>
    <div class="d-flex flex-column flex-grow-1">
      <div class="animated fadeIn">
        <b-row>
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>General Details
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="3" id="product_images">
                          <label>Image</label>
                          <div>
                            <ImageBanner ref="banner"/>
                            <input type="checkbox" id="checkbox1" v-model="item.protectWithWatermark"> Protect with Watermark
                          </div>
                        </b-col>
                        <b-col sm="3">
                          <TextBox v-model="item.name" @input="onChangeName" placeholder="Name" required :addClass="{'is-invalid': $v.item.name.$error}">
                            Name
                          </TextBox>
                          <TextBox v-model="item.number" disabled>
                            Number
                          </TextBox>
                          <SelectBox :items="types" v-model="selectedType">Type</SelectBox>
                          <TextBox v-model="item.sku" id="SKU_NUMBER">
                            SKU
                          </TextBox>
                          <LookUp url="manufacturers/items" v-model="selectedManufacturer">
                            Manufacturer
                            <template #add-btn>
                              <button type="button" @click="openModalManufacturer" v-b-tooltip.hover :title="'New Manufacturer'"
                                      class="w-10 btn btn-icon lookup-add-value-btn">
                                <i class="fas fa-plus"></i>
                              </button>
                            </template>
                          </LookUp>
                        </b-col>
                        <b-col sm="3">
                          <Textarea v-model="item.description" @input="onChangeDescription" customStyle="height: 111px">Description</Textarea>
                          <Textarea v-model="item.shortDescription" customStyle="height: 111px">Short Description</Textarea>
                          <LookUp url="vendors/items" v-model="selectedVendor">
                            Vendor
                            <template #add-btn>
                              <button type="button" @click="openModalVendor" v-b-tooltip.hover :title="'New Vendor'"
                                      class="w-10 btn btn-icon lookup-add-value-btn">
                                <i class="fas fa-plus"></i>
                              </button>
                            </template>
                          </LookUp>
                        </b-col>
                        <b-col sm="3">
                          <TextBox v-model="item.salesPrice" inputtype="number" min="0">
                            Sales Price
                          </TextBox>
                          <TextBox v-model="item.purchasePrice" inputtype="number" min="0">
                            Purchase price
                          </TextBox>
                          <SelectBox :items="taxes" v-model="selectedTax">Tax Class</SelectBox>
                          <div>
                            <label>Categories </label>
                            <VSelect multiple v-model="selectedCategory" :options="categories" />
                          </div>
                          <SelectBox :items="statuses" v-model="selectedStatus" style="padding-top: 11px;">Status</SelectBox>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row v-if="selectedType === 'INVENTORY'" style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i> Inventory
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="6">
                          <TextBox v-model="item.qty" inputtype="number" min="0">
                            Stock Quantity
                          </TextBox>
                        </b-col>
                        <b-col sm="6">
                          <SelectBox :items="stockStatuses" v-model="selectedStockStatus">Stock Status</SelectBox>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row v-show="selectedType === 'VARIABLE'" style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i> Variations
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="12">
                          <table class="table table-bordered table-sm">
                            <thead class="thead-light">
                            <tr>
                              <th width="4%" scope="col" class="text-center">#</th>
                              <th width="10%" scope="col">Image</th>
                              <th width="15%" scope="col">Variation</th>
                              <th width="10%" scope="col">Any</th>
                              <th width="10%" scope="col">SKU</th>
                              <th width="9%" scope="col">Sales Price</th>
                              <th width="10%" scope="col">Quantity</th>
                              <th width="14%" scope="col">Status</th>
                              <th width="4%" style="min-width: 90px" scope="col" class="text-center">Action</th>
                            </tr>
                            </thead>
                            <tbody v-for="(variationItem, index) in variationItems" :key="index">
                            <tr>
                              <td class="index text-center" style="vertical-align: middle;"><span>{{ +index + 1 }}</span></td>
                              <td>
                                <div class="stylish-org-images row">
                                  <div
                                    v-for="(item, index) in variationItem.images"
                                    :key="index"
                                  >
                                    <div>
                                      <img :src="item.url" alt="img" style="max-height:60px">
                                    </div>
                                  </div>
                                  <div v-if="!variationItem.images.length > 0" class="col-3 stylish-org-images-add">
                                    <div class="org-img-wrap-table">
                                    <div class="stylish-org-image-bg-layer-table">
                                    </div>
                                    <i class="fas fa-plus stylish-org-images-add-btn"></i>
                                    <img src="../../../static/images/placeholder-image.jpg" alt="img">
                                    <input id="ProductItemTableUpload" type="file" class="custom-file-input"
                                           accept="image/*"  @change="onChoose($event, variationItem, true)">
                                  </div>
                                  </div>
                                </div>
                              </td>
                              <td></td>
                              <td></td>
                              <td>
                                <TextBox v-model="variationItem.sku"></TextBox>
                              </td>
                              <td>
                                <TextBox v-model="variationItem.salesPrice" inputtype="number"></TextBox>
                              </td>
                              <td>
                                <TextBox v-model="variationItem.qty" inputtype="number"></TextBox>
                              </td>
                              <td>
                                <SelectBox v-model="variationItem.status" :items="variationStatus" ></SelectBox>
                              </td>

                              <td class="text-center" style="vertical-align: middle;">
                                <b-button variant="success" size="sm" class="m-2" @click="addRow(index, true)">
                                  <i class="fa fa-plus"></i>
                                </b-button>
                                <b-button variant="danger" size="sm" @click="removeRow(index,true)">
                                  <i class="fa fa-window-close"></i>
                                </b-button>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row v-show="selectedType === 'DIGITAL'" style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i> Downloadable Files
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="12">
                          <table class="table table-bordered table-sm">
                            <thead class="thead-light">
                            <tr>
                              <th width="4%" scope="col" class="text-center">#</th>
                              <th width="15%" scope="col">File </th>
                              <!--<th width="10%" scope="col">Name</th>-->
                              <th width="30%" scope="col">Download Limit</th>
                              <th width="30%" scope="col">Download Expiry</th>
                              <th width="4%" style="min-width: 90px" scope="col" class="text-center">Action</th>
                            </tr>
                            </thead>
                            <tbody v-for="(uploadItem, index) in uploadItems" :key="index">
                            <tr>
                              <td class="index text-center" style="vertical-align: middle;"><span>{{ +index + 1 }}</span></td>
                              <td>
                                <div class="text-center" style="vertical-align: middle;">
                                  <div
                                    v-for="(item, index) in uploadItem.files"
                                    :key="index"
                                  >
                                    <div>
                                      <a :href="item.url" target="_blank" style="max-height:60px">{{ item.name }}</a>
                                    </div>
                                  </div>
                                  <div v-if="!uploadItem.files.length > 0">
                                    <input id="ProductItemTableFileUpload" type="file"
                                           accept="image/*"  @change="onChoose($event, uploadItem, false)">
                                  </div>
                                </div>
                              </td>
                              <td>
                                <TextBox v-model="uploadItem.downloadLimit" inputtype="number"></TextBox>
                              </td>
                              <td>
                                <div style="margin-top: 0.5rem">
                                  <b-input-group append="minutes">
                                    <b-form-input v-model="uploadItem.downloadExpiry" type="number"></b-form-input>
                                  </b-input-group>
                                </div>
                              </td>

                              <td class="text-center" style="vertical-align: middle;">
                                <b-button variant="success" size="sm" class="m-2" @click="addRow(index, false)">
                                  <i class="fa fa-plus"></i>
                                </b-button>
                                <b-button variant="danger" size="sm" @click="removeRow(index, false)">
                                  <i class="fa fa-window-close"></i>
                                </b-button>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row v-show="enableMultiStore" style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i> Stores
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="6">
                          <table class="table table-bordered table-sm">
                            <thead class="thead-light">
                            <tr>
                              <th width="83%" scope="col">Store</th>
                              <th width="17%" scope="col"></th>
                            </tr>
                            </thead>
                            <tbody v-for="(store, index) in storeList" :key="index">
                            <tr>
                              <td>
                                <SelectBox v-model="store.id" :items="stores" ></SelectBox>
                              </td>
                              <td class="text-center" style="vertical-align: middle;">
                                <b-button size="sm" class="m-2" @click="addStoreRow(index)">
                                  <i class="fa fa-plus"></i>
                                </b-button>
                                <b-button size="sm" @click="removeStoreRow(index)">
                                  <i class="fas fa-minus"></i>
                                </b-button>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row v-if="selectedType === 'INVENTORY' || selectedType === 'NON_INVENTORY' || selectedType === 'VARIABLE' || selectedType === 'GROUPED'" style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i> Shipping
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="6">
                          <TextBox v-model="item.weight" inputtype="number" min="0">
                            Weight
                          </TextBox>
                          <b-row>
                            <label style="padding-left: 17px;">Dimensions</label>
                            <b-col sm="4">
                              <TextBox v-model="item.width" inputtype="number" min="0" placeholder="width"></TextBox>
                            </b-col>
                            <b-col sm="4">
                              <TextBox v-model="item.height" inputtype="number" min="0" placeholder="height"></TextBox>
                            </b-col>
                            <b-col sm="4">
                              <TextBox v-model="item.length" inputtype="number" min="0" placeholder="length"></TextBox>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col sm="6">
                          <label>Shipping class</label>
                          <SelectBox style="padding-top: 3.3rem;" :items="processingTimes" v-model="item.processingTime">Processing Time</SelectBox>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i> Search Engine Optimization
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="12">
                          <SearchEngineOptimization ref="SEO" />
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
                <div class="add-edit-fields-action-bar">
                  <div class="form-actions">
                    <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
                    <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/product'">
                      Cancel
                    </router-link>
                  </div>
                </div>
              </b-card>
            </transition>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import ImageBanner from '../../components/ImageBanner'
import ManufacturerAddModal from '../product/ManufacturerAddModal'
import VendorAddModal from '../vendor/VendorAddModal'
import SearchEngineOptimization from '../../components/SearchEngineOptimization'
import VSelect from 'vue-select'

export default {
  name: 'ProductAddEditPage',
  data () {
    return {
      item: {
        name: '',
        number: '',
        description: '',
        shortDescription: '',
        sku: '',
        salesPrice: '0',
        purchasePrice: '0',
        weight: '0',
        width: null,
        height: null,
        length: null,
        categoryId: null,
        longNumber: null,
        qty: '0',
        processingTime: '',
        protectWithWatermark: false
      },
      categories: [],
      storeList: [
        { id: null }
      ],
      storeItem: { id: null },
      stores: [],
      enableMultiStore: false,
      selectedCategory: [],
      types: [
        { text: 'Inventory', value: 'INVENTORY' },
        { text: 'Non-Inventory', value: 'NON_INVENTORY' },
        { text: 'Variable', value: 'VARIABLE' },
        { text: 'Digital', value: 'DIGITAL' },
        { text: 'Grouped', value: 'GROUPED' },
        { text: 'Service', value: 'SERVICE' }
      ],
      selectedType: 'NON_INVENTORY',
      taxes: [],
      selectedTax: null,
      selectedManufacturer: null,
      selectedVendor: null,
      statuses: [
        { text: 'Active', value: 'ACTIVE' },
        { text: 'Inactive', value: 'INACTIVE' }
      ],
      variationStatus: [
        { text: 'Active', value: 'ACTIVE' },
        { text: 'Inactive', value: 'INACTIVE' }
      ],
      selectedStatus: 'ACTIVE',
      stockStatuses: [
        { text: 'Select', value: '' },
        { text: 'In stock', value: 'IN_STOCK' },
        { text: 'Out of Stock', value: 'OUT_STOCK' }
      ],
      selectedStockStatus: '',
      processingTimes: [
        { text: 'Select', value: '' },
        { text: '1 business day', value: '1_BUSINESS_DAY' },
        { text: '1-3 business days', value: '1_3_BUSINESS_DAYS' },
        { text: '3-5 business days', value: '3_5_BUSINESS_DAYS' },
        { text: '1-2 weeks', value: '1_2_WEEKS' },
        { text: '2-3 weeks', value: '2_3_WEEKS' }
      ],
      variationItems: [
        { id: null, images: [], imageId: null, imageUrl: null, sku: null, salesPrice: null, qty: null, status: 'ACTIVE' }
      ],
      variationItem: { id: null, images: [], imageId: null, imageUrl: null, sku: null, salesPrice: null, qty: null, status: 'ACTIVE' },
      uploadItems: [
        { id: null, files: [], name: null, downloadLimit: null, downloadExpiry: null, openUploadModal: false, uploadPercentage: 0 }
      ],
      uploadItem: { id: null, files: [], name: null, downloadLimit: null, downloadExpiry: null, openUploadModal: false, uploadPercentage: 0 }
    }
  },
  created () {
    this.loadRelatedData()
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      this.$loading.show()
      this.axios.get('products/generate-number')
        .then(response => {
          if (response && response.data) {
            this.item.longNumber = response.data.id
            this.item.number = response.data.name
            this.enableMultiStore = response.data.enableMultiStore
            if (response.data.lookUpItemDto) {
              this.storeList[0].id = response.data.lookUpItemDto.value
            }
          }
          this.$loading.hide()
        })
        .catch(error => {
          if (error.response && error.response.data.status === 400) {
            this.$toastr.error(error.response.data.errors)
          }
          this.$loading.hide()
        })

      return
    }
    this.$loading.show()
    this.axios.get('products/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.selectedStatus = this.item.status
        this.item.salesPrice = this.item.salesPrice + ''
        this.item.purchasePrice = this.item.purchasePrice + ''
        this.item.weight = this.item.weight + ''
        this.item.sku = this.item.code
        this.selectedType = this.item.type != null ? this.item.type : ''
        this.selectedStockStatus = this.item.stockStatus
        this.enableMultiStore = this.item.enableMultiStore
        this.$refs.banner.setImages(this.item.images)
        if (this.item.categories) {
          const list = []
          this.item.categories.forEach(function (item) {
            list.push({
              label: item.name,
              value: item.id
            })
          })
          this.selectedCategory = list
        }
        if (this.item.manufacturerId) {
          this.selectedManufacturer = {
            label: this.item.manufacturerName,
            value: this.item.manufacturerId
          }
        }
        if (this.item.stores) {
          this.storeList = this.item.stores
        }
        if (this.item.vendorId) {
          this.selectedVendor = {
            label: this.item.vendorName,
            value: this.item.vendorId
          }
        }
        if (this.selectedType === 'VARIABLE') {
          if (this.item.productVariations) {
            this.variationItems = this.item.productVariations
          }
        }
        if (this.selectedType === 'DIGITAL' && this.item.productDigital) {
          this.uploadItems = this.item.productDigital
        }
        this.$refs.SEO.setItem(this.item)
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return this.$route.params.id ? 'Edit Product' : 'Add Product'
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if (this.$v.item.$invalid) {
        this.$v.$touch()
        this.$toastr.error('Please, fill required fields')
        return
      }
      this.$loading.show()
      const _item = { ...this.item }
      if (this.selectedCategory) {
        const list = []
        this.selectedCategory.forEach(function (item) {
          list.push({
            id: item.value,
            name: item.label
          })
        })
        _item.categories = list
      }
      if (this.storeList && this.enableMultiStore) {
        _item.stores = this.storeList
      }
      if (this.selectedVendor) {
        _item.vendorId = this.selectedVendor.value
      }
      _item.type = this.selectedType
      this.$refs.SEO.getItem(_item)
      _item.status = this.selectedStatus
      _item.stockStatus = this.selectedStockStatus
      _item.code = this.item.sku
      const bannerItems = this.$refs.banner.getImages()
      if (bannerItems.length > 0) {
        _item.images = bannerItems
      }
      if (this.selectedType === 'VARIABLE' && this.variationItems) {
        _item.productVariations = this.variationItems
      }
      if (this.selectedType === 'DIGITAL' && this.uploadItems) {
        _item.productDigital = this.uploadItems
      }
      let axios
      if (_item.id) {
        axios = this.axios.put('products', _item)
      } else {
        axios = this.axios.post('products', _item)
      }
      axios.then(response => {
        if (response.status === 200) {
          this.$toastr.success(response.data)
          this.$router.push('/product')
        } else {
          this.$toastr.error(response.data.message)
        }
        this.$loading.hide()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$toastr.error(error.response.data.errors.message)
        }
        this.$loading.hide()
      })
    },
    async loadRelatedData () {
      this.$loading.show()
      const [categoryReq, storeReq] = await Promise.all([
        this.axios.get('categories/items'),
        this.axios.get('store/items')
      ])
      if (categoryReq && categoryReq.data) {
        this.setSelectedLookUpItems(categoryReq.data, 'categories')
      }
      if (storeReq && storeReq.data) {
        this.setSelectedItems(storeReq.data, 'stores')
      }
      this.$loading.hide()
    },
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    },
    setSelectedLookUpItems (items, code) {
      const _items = items.map(item => {
        return { label: item.name, value: item.id }
      })
      this[code] = _items
    },
    openModalManufacturer () {
      return this.$refs['manufacturer-modal'].show()
    },
    manufacturerModalClose (item) {
      if (item) {
        this.selectedManufacturer = item
      }
      this.$refs['manufacturer-modal'].hide()
    },
    openModalVendor () {
      return this.$refs['vendor-modal'].show()
    },
    vendorModalClose (item) {
      if (item) {
        this.selectedVendor = item
      }
      this.$refs['vendor-modal'].hide()
    },
    onChangeName () {
      this.$refs.SEO.setObjectName(this.item.name)
    },
    onChangeDescription () {
      this.$refs.SEO.setObjectDescription(this.item.description)
    },
    removeRow (index, isVariable) {
      if (isVariable) {
        if (this.variationItems.length > 1) {
          const itemIndex = this.variationItems[index]
          if (itemIndex) {
            this.variationItems.splice(index, 1)
          }
        } else {
          this.$toastr.error(this.$t('youCannotDeleteYouHave1Message'))
        }
      } else {
        if (this.uploadItems.length > 1) {
          const itemIndex = this.uploadItems[index]
          if (itemIndex) {
            this.uploadItems.splice(index, 1)
          }
        } else {
          this.$toastr.error(this.$t('youCannotDeleteYouHave1Message'))
        }
      }
    },
    addRow (index, isVariable) {
      if (isVariable) {
        this.variationItems = [
          ...this.variationItems.slice(0, index + 1),
          Object.assign({}, this.variationItem),
          ...this.variationItems.slice(index + 1)
        ]
      } else {
        this.uploadItems = [
          ...this.uploadItems.slice(0, index + 1),
          Object.assign({}, this.uploadItem),
          ...this.uploadItems.slice(index + 1)
        ]
      }
    },
    addStoreRow (index) {
      this.storeList = [
        ...this.storeList.slice(0, index + 1),
        Object.assign({}, this.storeItem),
        ...this.storeList.slice(index + 1)
      ]
    },
    removeStoreRow (index) {
      if (this.storeList.length > 1) {
        const itemIndex = this.storeList[index]
        if (itemIndex) {
          this.storeList.splice(index, 1)
        }
      } else {
        this.$toastr.error(this.$t('youCannotDeleteYouHave1Message'))
      }
    },
    onChoose (event, variationItem, isImage) {
      const formData = new FormData()
      formData.append('file', event.target.files[0])
      this.axios.post('image-banner/upload', formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      ).then((response) => {
        if (response.data) {
          const images = isImage ? variationItem.images.slice() : variationItem.files.slice()
          const item = {
            id: response.data.id,
            url: response.data.url,
            key: response.data.key,
            name: response.data.name,
            contentType: response.data.contentType,
            description: response.data.description
          }
          images.push(item)
          if (isImage) {
            variationItem.images = images
          } else {
            variationItem.files = images
          }
        }
      })
    },
    resetModal (uploadItem) {
      uploadItem.files = []
    },
    calculatePercentage (file, uploadPercentage) {
      var interval = file.size / 1000
      setInterval(() => {
        uploadPercentage = Math.min(uploadPercentage + 5, 100)
      }, interval)
      return uploadPercentage
    },
    onChooseFile (uploadItem) {
      uploadItem.files = this.$refs.filesTable.files
    },
    submitFiles (uploadItem) {
      // this.$loading.show()
      const formData = new FormData()
      for (var i = 0; i < uploadItem.files.length; i++) {
        const file = uploadItem.files[i]
        formData.append('uploadFile', file)
      }

      this.axios.post('file',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
          }.bind(this)
        }
      ).then((response) => {
        if (response.data) {
          const list = this.fileList
          const item = {
            id: response.data.id,
            url: response.data.url,
            key: response.data.key,
            name: response.data.name,
            contentType: response.data.contentType,
            description: response.data.description
          }
          list.push(item)
          uploadItem.files = list
        }
      })
        .catch(e => {
          // this.$loading.hide()
          this.$toastr.error('500 - Internal Server Error')
        })
    },
    openModalUpload (uploadItem) {
      uploadItem.openUploadModal = true
    }
  },
  validations: {
    item: {
      name: {
        required
      }
    }
  },
  components: {
    ImageBanner,
    ManufacturerAddModal,
    VendorAddModal,
    SearchEngineOptimization,
    VSelect
  }
}
</script>

<style scoped>

</style>
