<template>
  <div class="not-found-page-wrapper d-flex justify-content-center align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col>
          <div class="error-description d-flex align-items-center justify-content-center flex-column">
            <h1 class="error-code">404</h1>
            <h4 class="">Oops! You're lost.</h4>
            <p class="">The page you are looking for was not found.</p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFoundPage'
}
</script>
<style scoped>
  .not-found-page-wrapper {
    height: 100vh;
    background-image: url("../../static/images/not-found-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }
  .error-description {
    color: rgba(255,255,255,0.7);
  }
  .error-description h1 {
    font-size: 250px;
    font-weight: bold;
  }
  .error-description h4 {
    font-size: 50px;
    font-weight: bold;
  }
  .error-description p {
    font-size: 30px;
    font-weight: bold;
  }
</style>
