<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> General Details
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Name</label>
                          <div class="summary-field">{{ item.name }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Type</label>
                          <div class="summary-field">{{ item.type === 'ANNOUNCEMENT' ? 'Announcement' : item.type === 'FREE_SHIPPING' ? 'Free Shipping' : item.type === 'DISCOUNT' ? 'Discount' : ''}}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Theme</label>
                          <div class="summary-field">{{ item.themeName }}</div>
                        </b-form-group>
                        <label>Location</label>
                      </b-col>
                      <b-col sm="4">
                        <b-row>
                          <b-col sm="6">
                            <b-form-group>
                              <label>From date</label>
                              <div class="summary-field">{{ item.fromDate != null ? item.fromDate[0] + '/' + item.fromDate[1] + '/' + item.fromDate[2] : '' }}</div>
                            </b-form-group>
                          </b-col>
                          <b-col sm="6">
                            <b-form-group>
                              <label>To date</label>
                              <div class="summary-field">{{ item.toDate != null ? item.toDate[0] + '/' + item.toDate[1] + '/' + item.toDate[2] : '' }}</div>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-form-group>
                          <label>Status</label>
                          <div class="summary-field">{{ item.status === 'ACTIVE' ? 'Active' : item.status === 'SCHEDULED' ? 'Scheduled' : item.status === 'INACTIVE' ? 'Inactive' : item.status === 'EXPIRED' ? 'Expired' : '' }}</div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> Display Settings
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Title</label>
                          <div class="summary-field">{{ item.title }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Banner Message</label>
                          <div class="summary-field">{{ item.bannerMessage }}</div>
                        </b-form-group>
                        <label>Background Color </label>
                        <ColourPicker disabled
                          v-model="item.backgroundColour"/>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Button text</label>
                          <div class="summary-field">{{ item.buttonText }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Button Destination URL</label>
                          <div class="summary-field">{{ item.destinationUrl + ': ' + item.selectedDesctinationText}}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>URL Target</label>
                          <div class="summary-field">{{ item.urlTarget === 'OPEN_CURRENT_WINDOW' ? 'Open in current Window' : item.urlTarget === 'OPEN_NEW_TAB' ? 'Open in new Tab' :''  }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <label>Image</label>
                        <div style="margin-top: 0.5rem">
                          <ImageBanner ref="banner" :isSummary="true"/>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
              <div class="add-edit-fields-action-bar">
                <div class="form-actions">
                  <router-link :to="'/storefront/banner/item/' + item.id">
                    <b-button type="button" variant="primary" class="mr-2">Edit</b-button>
                  </router-link>
                  <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/storefront/banner'">
                    Cancel
                  </router-link>
                </div>
              </div>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ImageBanner from '../../components/ImageBanner'
import ColourPicker from 'vue-native-color-picker'
export default {
  name: 'BannerViewPage',
  data () {
    return {
      item: {
        name: '',
        description: '',
        parentId: null
      }
    }
  },
  created () {
    // this.loadRelatedData()
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('banner/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.$refs.banner.setImages(this.item.images)
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return 'Banner Summary'
    }
  },
  methods: {
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    }
  },
  components: {
    ImageBanner,
    ColourPicker
  }
}
</script>

<style scoped>

</style>
