<template>
  <div class="login-page-wrapper d-flex align-items-center justify-content-center">
    <b-jumbotron header="" :lead="!isloading ? 'Create a new account' : 'Your store is being created. Please wait for a while...'">
      <div v-if="isloading">    <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
      </div></div>
      <a-form :form="form" layout="vertical" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" @submit="onSubmit" v-show="!isloading">
        <TextBoxFormItem name="username" label="Email or Phone number"  required error-message="Please input your Email or Phone number!">
          <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
        </TextBoxFormItem>
        <label v-if="errorCode === 304" class="ant-form-item-error mb-3" style="position: absolute;margin-top: -1.5rem;">A user already exists with this username</label>
        <label class="ant-form-item-required">Password</label>
        <password   @score="showScore" class="passwordDiffic" v-model="password" >
          <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)"/>
        </password>
        <div class="ant-form-item-error mb-3" v-if="score !== null && score < 2">Password is too weak</div>
        <div class="ant-row ant-form-item mt-3">
          <div class="ant-col ant-col-24 ant-form-item-label"><label for="coordinated_confirmPassword" title="Confirm password" class="ant-form-item-required">Confirm password</label></div>
          <div class="ant-col ant-col-24 ant-form-item-control-wrapper"><div class=" ant-form-item-control"><span class="ant-form-item-children"><span class="required-field"></span><input   :disabled="password == ''" type="password" v-model="confirmPassword"  id="coordinated_confirmPassword" class="passwordDiffic ant-input"></span><!----></div></div>
          <div class="ant-form-item-error" v-if="password !== confirmPassword && confirmPassword != ''" >Password did not match</div>
        </div>
        <TextBoxFormItem  name="subdomain" label="Store URL" addon-before="http://" :addon-after="domain" required error-message="Please input your Store URL!">
        </TextBoxFormItem>
        <label v-if="errorCode === 208" class="ant-form-item-error mb-3" style="position: absolute;margin-top: -1.5rem;">Domain is already in use on another account</label>
        <div style="margin-top: 1.5rem">
          <a-form-item  class="m-0">
            <vue-recaptcha  @verify="recaptchaOnVerify"  class="recaptcha" sitekey="6LeYPaAbAAAAANZgRcUfg_jfH0ZCjHmyIewTfq93" :loadRecaptchaScript="true"></vue-recaptcha>
            <a-button  :disabled="password !== confirmPassword || score < 2  || !recaptchaIsVerified" block type="primary" html-type="submit">
              Sign Up
            </a-button>
          </a-form-item>
          <div class="my-3 text-center">Already have an account? <span @click="redirectToLogin" style="color: #1890ff;cursor: pointer">Log In</span></div>
        </div>
      </a-form>
    </b-jumbotron>
  </div>
</template>

<script>import { AUTHORIZATION } from '../constants'
import Password from 'vue-password-strength-meter'
import VueRecaptcha from 'vue-recaptcha'
import TextBoxFormItem from '../components/ui/TextBoxFormItem'
export default {
  name: 'SignUpPage',
  components: { TextBoxFormItem, Password, VueRecaptcha },
  data () {
    return {
      sitekey: '6LeYPaAbAAAAAFNt4hhcop1H5J2Ywf22m7kMlA8U',
      formLayout: 'vertical',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      username: '',
      domain: '.mybec.uz',
      subdomain: '',
      password: '',
      passwordfgr: '',
      confirmPassword: '',
      errorCode: '',
      score: null,
      recaptchaIsVerified: false,
      isloading: false
    }
  },
  created () {

  },
  methods: {
    recaptchaOnVerify (r) {
      console.log('verifed', r)
      this.recaptchaIsVerified = true
    },
    showScore (score) {
      console.log(score)
      this.score = score
    },
    onSubmit (event) {
      event.preventDefault()
      this.$loading.show(true)
      this.form.validateFields((err, values) => {
        if (!err) {
          values.password = this.password
          this.$store.dispatch('signUpValidation', {
            username: values.username,
            subdomain: values.subdomain,
            password: values.password
          }, { root: true }).then(response => {
            if (response && response.status === 200 && response.data) {
              this.isloading = true
              this.$store.dispatch('signUp', {
                username: values.username,
                subdomain: values.subdomain,
                password: values.password
              }, { root: true }).then(response => {
                if (response && response.status === 200 && response.data) {
                  this.$store.dispatch('fetch',
                    localStorage.getItem(AUTHORIZATION), { root: true })
                    .then(response => {
                      if (response.status === 200 && response.data) {
                        this.$router.push('/')
                        console.log('dsadas')
                      }
                    })
                } else {
                  this.$toastr.error(response.data.message || 'Incorrect params')
                }
                this.$loading.hide()
                this.isloading = false
              }, () => {
                this.$loading.hide()
                this.isloading = false
                this.$toastr.error('Incorrect params')
              })
            } else if (response && response.status === 304) {
              this.errorCode = 304
              // this.$toastr.error('Username with this name already exists. Try another username')
            } else if (response && response.status === 208) {
              this.errorCode = 208
              // this.$toastr.error('Domain with this name already exists. Try another domain')
            } else {
              this.$toastr.error(response.data.message || 'Incorrect params')
            }
            this.$loading.hide()
          }, () => {
            this.$loading.hide()
            this.$toastr.error('Incorrect params')
          })
        }
      })
    },
    redirectToLogin () {
      this.$router.push('/login')
    }
  }
}
</script>

<style  lang="scss">
  .ant-input{
    z-index: 99;
    border-radius: 4px;
    border-left: 5px solid #ff6c6c;
  }
  .ant-form-item-error{
    color: #ff0000;
    font-family: Roboto,helvetica,arial,sans-serif;
    font-size: 12px !important;
    font-weight: 400;
  }
  .ant-form-vertical .ant-form-item{
    padding-bottom:0px;
  }
  .recaptcha{
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .passwordDiffic{
    .Password__strength-meter{
      margin-bottom: 0;
    }
    .Password__group{
      .Password__field{
        background: #ffff;
        outline: none;
        border-radius: 4px;
        border-left: 5px solid #ff6c6c;
        height: 35px;
        &:focus{
          -webkit-box-shadow: 0px 0px 1px 3px rgba(62, 151, 219, 0.18);
          -moz-box-shadow: 0px 0px 1px 3px rgba(62, 151, 219, 0.18);
          box-shadow: 0px 0px 1px 3px rgba(62, 151, 219, 0.18);
          border-radius: 4px;
          border-left: 5px solid #ff6c6c;
          outline: none;
        }
      }
    }
  }
  .login-page-wrapper {
    height: 100vh;
    background-image: url("../../static/images/user-login-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .jumbotron{
    padding-bottom:20px;
  }
  .ant-input-group-addon + .ant-input {
    border-left: 1px #d9d9d9 solid;
  }
</style>
