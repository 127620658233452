<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fas fa-link mr-2"></i>General Details
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="6">
                        <b-form-group>
                          <label>Name</label>
                          <div class="summary-field">{{ item.name}}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Store URL</label>
                          <div class="summary-field">{{ item.url + item.currentDomain }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6">
                        <b-form-group>
                          <label>Store Information</label>
                          <div class="summary-field">{{ item.storeInformation}}</div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px">
        <b-col lg="6" style="display: grid">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="far fa-address-book mr-2"></i>Store address
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="12">
                        <AddressPanel ref="storeAddress" :isSummary="true"/>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
        <!--<b-col lg="6">-->
          <!--<transition name="fade">-->
            <!--<b-card no-body>-->
              <!--<div slot="header">-->
                <!--<i class="far fa-address-book mr-2"></i>Store Settings-->
                <!--<div class="card-header-actions">-->
                  <!--<b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>-->
                    <!--<i class="icon-arrow-up"></i>-->
                  <!--</b-link>-->
                <!--</div>-->
              <!--</div>-->
              <!--<b-collapse id="collapse1" visible>-->
                <!--<b-card-body>-->
                  <!--<div class="add-edit-fields-wrap">-->
                    <!--<b-row>-->
                      <!--<b-col sm="8">-->
                        <!--<label>Image</label>-->
                        <!--<div style="margin-top: 0.5rem">-->
                          <!--<ImageBanner ref="banner" :isSummary="true"/>-->
                        <!--</div>-->
                      <!--</b-col>-->
                      <!--<b-col sm="4">-->
                        <!--<div class="form-group">-->
                          <!--<label>Main Store</label>-->
                          <!--<div class="summary-field">{{ item.mainStore === true ? 'Yes' : 'No'}}</div>-->
                        <!--</div>-->
                      <!--</b-col>-->
                    <!--</b-row>-->
                    <!--<b-row>-->
                      <!--<b-col sm="12" style="margin-top: 1.4rem">-->
                        <!--<AttachmentComponent ref="uploadItem" :isSummary="true"/>-->
                      <!--</b-col>-->
                    <!--</b-row>-->
                  <!--</div>-->
                <!--</b-card-body>-->
              <!--</b-collapse>-->
            <!--</b-card>-->
          <!--</transition>-->
        <!--</b-col>-->
        <b-col lg="6">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="far fa-address-book mr-2"></i>Store Settings
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <b-row>
                    <b-col sm="6">
                      <div class="form-group">
                        <label>Logo</label>
                        <div class="d-flex justify-content-center p-4">
                          <div class="profile-img-upload">
                            <img v-if="item.logoUrl" :src="item.logoUrl" alt="img">
                            <img v-else src="../../assets/p3.png" alt="img">
                          </div>
                        </div>
                      </div>
                    </b-col>
                    <b-col sm="6">
                      <div class="form-group">
                        <label>Favicon</label>
                        <div class="d-flex justify-content-center p-4">
                          <div class="profile-img-upload">
                            <img v-if="item.faviconUrl" :src="item.faviconUrl" alt="img">
                            <img v-else src="../../assets/favicon.png" alt="img">
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col sm="6">
                      <b-form-group>
                        <label>Main Store</label>
                        <div class="summary-field">{{ item.mainStore == true ? 'Yes' :  'No'}}</div>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i>Contact details
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="3">
                        <b-form-group>
                          <label>Phone</label>
                          <div class="summary-field">{{ item.phone}}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Email</label>
                          <div class="summary-field">{{ item.email}}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="3">
                        <label>Youtube</label>
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fab fa-youtube"></i></span>
                          </div>
                          <input v-model="item.youtube" type="text" class="form-control" disabled>
                        </div>
                        <label>Facebook</label>
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fab fa-facebook"></i></span>
                          </div>
                          <input v-model="item.facebook" type="text" class="form-control" disabled>
                        </div>
                      </b-col>
                      <b-col sm="3">
                        <label>Instagram</label>
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fab fa-instagram"></i></span>
                          </div>
                          <input v-model="item.instagram" type="text" class="form-control" disabled>
                        </div>
                        <label>Twitter</label>
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fab fa-twitter"></i></span>
                          </div>
                          <input v-model="item.twitter" type="text" class="form-control" disabled>
                        </div>
                      </b-col>
                      <b-col sm="3">
                        <label>Telegram</label>
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fab fa-telegram"></i></span>
                          </div>
                          <input v-model="item.telegram" type="text" class="form-control" disabled>
                        </div>
                        <label>Tiktok</label>
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fab fa-tiktok"></i></span>
                          </div>
                          <input v-model="item.tiktok" type="text" class="form-control" disabled>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row >
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div class="add-edit-fields-action-bar">
                <div class="form-actions">
                  <router-link :to="'/store/item/' + item.id">
                    <b-button type="button" variant="primary" class="mr-2">Edit</b-button>
                  </router-link>
                  <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/store'">
                    Cancel
                  </router-link>
                </div>
              </div>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import AddressPanel from '../../components/AddressPanel'
export default {
  name: 'StoreViewPage',
  data () {
    return {
      item: {
        name: ''
      }
    }
  },
  created () {
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('store/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        if (this.item.storeAddress) {
          this.$refs.storeAddress.setItem(this.item.storeAddress)
        }
        this.$refs.banner.setImages(this.item.images)
        this.$refs.uploadItem.setData(this.item.files)
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return 'Store Summary'
    }
  },
  components: {
    AddressPanel
  }
}
</script>

<style scoped>

</style>
