<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit"></i>General Details
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="3">
                        <label>Image</label>
                        <div style="margin-top: 0.5rem">
                          <ImageBanner ref="banner" :isSummary="true"/>
                        </div>
                      </b-col>
                      <b-col sm="3">
                        <b-form-group>
                          <label>Name</label>
                          <div class="summary-field">{{ item.name }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Number</label>
                          <div class="summary-field">{{ item.number }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Type</label>
                          <div class="summary-field">{{ item.type === 'INVENTORY' ? 'Inventory' : item.type === 'NON_INVENTORY' ? 'Non-Inventory' : item.type === 'VARIABLE' ? 'Variable' : item.type === 'GROUPED' ? 'Grouped' : item.type === 'SERVICE' ? 'Service' : item.type === 'DIGITAL' ? 'Digital' : '' }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>SKU</label>
                          <div class="summary-field">{{ item.code }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Manufacturer</label>
                          <div class="summary-field">{{ item.manufacturerName }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="3">
                        <b-form-group>
                          <label>Description</label>
                          <div class="summary-field">{{ item.description }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Short Description</label>
                          <div class="summary-field">{{ item.shortDescription }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Weight</label>
                          <div class="summary-field">{{ item.weight }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="3">
                        <b-form-group>
                          <label>Sales Price</label>
                          <div class="summary-field">{{ item.salesPrice }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Purchase Price</label>
                          <div class="summary-field">{{ item.purchasePrice }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Tax Class</label>
                          <div class="summary-field">{{ item.taxName }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Categories</label>
                          <div class="summary-field">{{ item.categoryName }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Status</label>
                          <div class="summary-field">{{ item.status && item.status === 'ACTIVE' ? 'Active' : 'Inactive' }}</div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row v-if="item.type === 'INVENTORY'" style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> Inventory
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="6">
                        <b-form-group>
                          <label>Stock Quantity</label>
                          <div class="summary-field">{{ item.qty }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6">
                        <b-form-group>
                          <label>Stock Status</label>
                          <div class="summary-field">{{ item.stockStatus === 'IN_STOCK' ? 'In stock' : item.stockStatus === 'OUT_STOCK' ? 'Out of Stock' : '' }}</div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row v-show="item.type === 'VARIABLE'" style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> Variations
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="12">
                        <table class="table table-bordered table-sm">
                          <thead class="thead-light">
                          <tr>
                            <th width="4%" scope="col" class="text-center">#</th>
                            <th width="10%" scope="col">Image</th>
                            <th width="15%" scope="col">Variation</th>
                            <th width="10%" scope="col">Any</th>
                            <th width="10%" scope="col">SKU</th>
                            <th width="9%" scope="col">Sales Price</th>
                            <th width="10%" scope="col">Quantity</th>
                            <th width="14%" scope="col">Status</th>
                          </tr>
                          </thead>
                          <tbody v-for="(variationItem, index) in variationItems" :key="index">
                          <tr>
                            <td class="index text-center" style="vertical-align: middle;"><span>{{ +index + 1 }}</span></td>
                            <td>
                              <div>
                                <div
                                  v-for="(item, index) in variationItem.images"
                                  :key="index"
                                >
                                  <div>
                                    <img :src="item.url" alt="img" style="max-height:60px">
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td></td>
                            <td></td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ variationItem.sku }}</span>
                            </td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ variationItem.salesPrice }}</span>
                            </td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ variationItem.qty }}</span>
                            </td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ variationItem.status === 'ACTIVE' ? 'Active' : 'Inactive' }}</span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row v-show="item.type === 'DIGITAL'" style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> Downloadable Files
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="12">
                        <table class="table table-bordered table-sm">
                          <thead class="thead-light">
                          <tr>
                            <th width="4%" scope="col" class="text-center">#</th>
                            <th width="15%" scope="col">File </th>
                            <!--<th width="10%" scope="col">Name</th>-->
                            <th width="30%" scope="col">Download Limit</th>
                            <th width="30%" scope="col">Download Expiry</th>
                          </tr>
                          </thead>
                          <tbody v-for="(uploadItem, index) in item.productDigital" :key="index">
                          <tr>
                            <td class="index text-center" style="vertical-align: middle;"><span>{{ +index + 1 }}</span></td>
                            <td>
                              <div class="text-center" style="vertical-align: middle;">
                                <div
                                  v-for="(item, index) in uploadItem.files"
                                  :key="index"
                                >
                                  <div>
                                    <a :href="item.url" target="_blank" style="max-height:60px">{{ item.name }}</a>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ uploadItem.downloadLimit }}</span>
                            </td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ uploadItem.downloadExpiry != null ? uploadItem.downloadExpiry + 'minutes' : ''}}</span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row v-show="item.enableMultiStore" style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> Stores
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="6">
                        <b-form-group>
                          <label>Store</label>
                          <div class="summary-field">{{ item.storeNames }}</div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row v-if="item.type === 'INVENTORY' || item.type === 'NON_INVENTORY' || item.type === 'VARIABLE' || item.type === 'GROUPED'" style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> Shipping
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="6">
                        <b-form-group>
                          <label>Weight</label>
                          <div class="summary-field">{{ item.weight }}</div>
                        </b-form-group>
                        <b-row>
                          <label style="padding-left: 17px;">Dimensions</label>
                          <b-col sm="4">
                            <b-form-group>
                              <label>Width</label>
                              <div class="summary-field">{{ item.width }}</div>
                            </b-form-group>
                          </b-col>
                          <b-col sm="4">
                            <b-form-group>
                              <label>Height</label>
                              <div class="summary-field">{{ item.height }}</div>
                            </b-form-group>
                          </b-col>
                          <b-col sm="4">
                            <b-form-group>
                              <label>Length</label>
                              <div class="summary-field">{{ item.length }}</div>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col sm="6">
                        <label>Shipping class</label>
                        <b-form-group style="padding-top: 3.3rem;">
                          <label>Processing Time</label>
                          <div class="summary-field">{{ item.processingTime === '1_BUSINESS_DAY' ? '1 business day' : item.processingTime === '1_3_BUSINESS_DAYS' ? '1-3 business days' : item.processingTime === '3_5_BUSINESS_DAYS' ? '3-5 business days' : item.processingTime === '1_2_WEEKS' ? '1-2 weeks' : item.processingTime === '2_3_WEEKS' ? '2-3 weeks' : '' }}</div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit"></i> Search Engine Optimization
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="12">
                        <SearchEngineOptimization :isSummary="true" ref="SEO" />
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
              <div class="add-edit-fields-action-bar">
                <div class="form-actions">
                  <router-link :to="'/product/item/' + item.id">
                    <b-button type="button" variant="primary" class="mr-2">Edit</b-button>
                  </router-link>
                  <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/product'">
                    Cancel
                  </router-link>
                </div>
              </div>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ImageBanner from '../../components/ImageBanner'
import SearchEngineOptimization from '../../components/SearchEngineOptimization'
export default {
  name: 'ProductAddEditPage',
  data () {
    return {
      item: {
        name: '',
        number: '',
        description: '',
        shortDescription: '',
        sku: '',
        salesPrice: '0',
        purchasePrice: '0',
        weight: '0',
        categoryId: null
      },
      variationItems: []
    }
  },
  created () {
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('products/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        if (this.item.productVariations) {
          this.variationItems = this.item.productVariations
        }
        if (this.item.images) {
          this.$refs.banner.setImages(this.item.images)
        }
        this.$refs.SEO.setItem(this.item)
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return 'Product Summary'
    }
  },
  components: {
    ImageBanner,
    SearchEngineOptimization
  }
}
</script>

<style scoped>

</style>
