<template>
  <a-card style="width: 100%">
    <TextBox slot="title" v-model="item.title" @input="onChangeName" :addClass="{'is-invalid': $v.item.title.$error}" required>
      Name
    </TextBox>
    <TextBox slot="title" style="padding-left: 15px" v-model="item.identifier" :addClass="{'is-invalid': $v.item.identifier.$error}" required>
      Identifier
    </TextBox>
    <a slot="extra" href="#">more</a>
    <a-tree
      class="draggable-tree menu-creator"
      :default-expanded-keys="expandedKeys"
      draggable
      :tree-data="data"
      ref="tree"
      @dragenter="onDragEnter"
      @drop="onDrop"
    >
      <template slot="custom" slot-scope="item">
        <span class="h-100 d-flex align-items-center justify-content-between">
          <span>{{ item.title }}</span>
          <span>
            <a-icon class="mr-2" type="edit" @click.prevent="() => onEdit(item)" />
            <a-icon type="delete" @click.prevent="() => onRemove(item)" />
          </span>
        </span>
      </template>
    </a-tree>
  </a-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'RightSide',
  props: {
    treeData: Array
  },
  data () {
    return {
      item: {
        title: '',
        identifier: ''
      },
      data: this.treeData,
      expandedKeys: ['title1', 'title2', 'title3']
    }
  },
  methods: {
    onRemove (data) {
      this.$emit('onRemove', data)
    },
    onEdit (data) {
      this.$emit('onEdit', data)
    },
    onDragEnter (info) {
    },
    onDrop (info) {
      // console.log(info)
      const dropKey = info.node.eventKey
      const dragKey = info.dragNode.eventKey
      const dropPos = info.node.pos.split('-')
      const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])
      const loop = (data, key, callback) => {
        data.forEach((item, index, arr) => {
          if (item.key === key) {
            return callback(item, index, arr)
          }
          if (item.children) {
            return loop(item.children, key, callback)
          }
        })
      }
      const data = [...this.data]

      let dragObj
      loop(data, dragKey, (item, index, arr) => {
        arr.splice(index, 1)
        dragObj = item
      })
      if (!info.dropToGap) {
        loop(data, dropKey, item => {
          item.children = item.children || []
          dragObj.parentKey = item.key
          item.children.push(dragObj)
        })
      } else if (
        (info.node.children || []).length > 0 && // Has children
          info.node.expanded && // Is expanded
          dropPosition === 1 // On the bottom gap
      ) {
        loop(data, dropKey, item => {
          item.children = item.children || []
          item.children.unshift(dragObj)
        })
      } else {
        data.parentKey = null
        let ar
        let i
        loop(data, dropKey, (item, index, arr) => {
          ar = arr
          i = index
        })
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj)
        } else {
          ar.splice(i + 1, 0, dragObj)
        }
      }
      this.data = data
    },
    setData (item) {
      this.setScopedSlots(item.treeData)
      this.data = item.treeData
      this.item.title = item.title
      this.item.identifier = item.urlSlug
    },
    setScopedSlots (children) {
      children.forEach(leaf => {
        if (leaf.children) {
          this.setScopedSlots(leaf.children) //  recursive call
        }
        leaf.scopedSlots = { title: 'custom' }
      })
    },
    onChangeName () {
      let identifier = ''
      identifier = this.item.title
        .replace(/\u0401/g, 'YO')
        .replace(/\u0419/g, 'I')
        .replace(/\u0426/g, 'TS')
        .replace(/\u0423/g, 'U')
        .replace(/\u041A/g, 'K')
        .replace(/\u0415/g, 'E')
        .replace(/\u041D/g, 'N')
        .replace(/\u0413/g, 'G')
        .replace(/\u0428/g, 'SH')
        .replace(/\u0429/g, 'SCH')
        .replace(/\u0417/g, 'Z')
        .replace(/\u0425/g, 'H')
        .replace(/\u042A/g, '')
        .replace(/\u0451/g, 'yo')
        .replace(/\u0439/g, 'i')
        .replace(/\u0446/g, 'ts')
        .replace(/\u0443/g, 'u')
        .replace(/\u043A/g, 'k')
        .replace(/\u0435/g, 'e')
        .replace(/\u043D/g, 'n')
        .replace(/\u0433/g, 'g')
        .replace(/\u0448/g, 'sh')
        .replace(/\u0449/g, 'sch')
        .replace(/\u0437/g, 'z')
        .replace(/\u0445/g, 'h')
        .replace(/\u044A/g, "'")
        .replace(/\u0424/g, 'F')
        .replace(/\u042B/g, 'I')
        .replace(/\u0412/g, 'V')
        .replace(/\u0410/g, 'a')
        .replace(/\u041F/g, 'P')
        .replace(/\u0420/g, 'R')
        .replace(/\u041E/g, 'O')
        .replace(/\u041B/g, 'L')
        .replace(/\u0414/g, 'D')
        .replace(/\u0416/g, 'ZH')
        .replace(/\u042D/g, 'E')
        .replace(/\u0444/g, 'f')
        .replace(/\u044B/g, 'i')
        .replace(/\u0432/g, 'v')
        .replace(/\u0430/g, 'a')
        .replace(/\u043F/g, 'p')
        .replace(/\u0440/g, 'r')
        .replace(/\u043E/g, 'o')
        .replace(/\u043B/g, 'l')
        .replace(/\u0434/g, 'd')
        .replace(/\u0436/g, 'zh')
        .replace(/\u044D/g, 'e')
        .replace(/\u042F/g, 'Ya')
        .replace(/\u0427/g, 'CH')
        .replace(/\u0421/g, 'S')
        .replace(/\u041C/g, 'M')
        .replace(/\u0418/g, 'I')
        .replace(/\u0422/g, 'T')
        .replace(/\u042C/g, "'")
        .replace(/\u0411/g, 'B')
        .replace(/\u042E/g, 'YU')
        .replace(/\u044F/g, 'ya')
        .replace(/\u0447/g, 'ch')
        .replace(/\u0441/g, 's')
        .replace(/\u043C/g, 'm')
        .replace(/\u0438/g, 'i')
        .replace(/\u0442/g, 't')
        .replace(/\u044C/g, "'")
        .replace(/\u0431/g, 'b')
        .replace(/\u044E/g, 'yu')

      this.item.identifier = identifier != null && identifier.length > 0 ? identifier.trim().toLocaleLowerCase().replaceAll(' ', '-') : ''
    }
  },
  validations: {
    item: {
      title: {
        required
      },
      identifier: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>
