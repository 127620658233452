<template>
  <div>
    <a-row :gutter="24">
      <a-col span="8">
        <a-card>
          <a-directory-tree multiple default-expand-all @select="onSelect" @load="onLoad" :treeData="data">
          </a-directory-tree>
        </a-card>
      </a-col>
      <a-col span="16">
        <a-row>
          <a-card title="Editor" style="width: 100%; height: 100%">
            <codemirror v-model="item.html" :options="cmOption" style="width: 100%; height: 100%"></codemirror>
          </a-card>
        </a-row>
        <a-row >
          <a-card no-body>
            <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
          </a-card>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>

import { codemirror } from 'vue-codemirror'
import 'codemirror/mode/xml/xml.js'
import 'codemirror/mode/css/css.js'
import 'codemirror/mode/htmlmixed/htmlmixed.js'
import 'codemirror/addon/selection/active-line.js'
import 'codemirror/addon/edit/closetag.js'
// import base style
import 'codemirror/lib/codemirror.css'

export default {
  name: 'TemplateEditorPage',
  components: {
    codemirror
  },
  data () {
    return {
      cmOption: {
        mode: 'text/html',
        lineWrapping: true,
        lineNumbers: true,
        theme: 'default'
      },
      data: [],
      item: { path: null, name: null, html: null }
    }
  },
  created () {
    this.$loading.show()
    this.axios.get('template/tree')
      .then(response => {
        if (response.data) {
          this.data = response.data
        }
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  methods: {
    onSelect (keys, event) {
      this.item = {}
      this.item.name = keys[0]

      this.$loading.show()
      this.axios.get('template', { params: this.item })
        .then(response => {
          if (response.status === 200) {
            this.$toastr.success(response.data)
            this.item = response.data
            if (this.item.name.endsWith('.css')) {
              console.log(this.item.name)
              this.cmOption.mode = 'text/css'
            } else {
              this.cmOption.mode = 'text/html'
            }
          } else {
            this.$toastr.error(response.data.message)
          }
          this.$loading.hide()
        }).catch(error => {
          if (error.response && error.response.status === 400) {
            this.$toastr.error(error.response.data.errors.message)
          }
          this.$loading.hide()
        })
    },
    onLoad (keys, event) {
      event.preventDefault()
      console.log('loaded keys', keys)
      /* var key = keys.filter(function (key) {
        if (key.contains('index.html')) return key
      }) */
    },
    onSubmit (event) {
      event.preventDefault()
      this.$loading.show()
      this.axios.post('template', this.item)
        .then(response => {
          if (response.status === 200) {
            this.$toastr.success(response.data)
            // this.$router.push('/storefront/template/editor')
          } else {
            this.$toastr.error(response.data.message)
          }
          this.$loading.hide()
        }).catch(error => {
          if (error.response && error.response.status === 400) {
            this.$toastr.error(error.response.data.errors.message)
          }
          this.$loading.hide()
        })
    }
  }
}
</script>

<style scoped>

</style>
