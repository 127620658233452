<template>
  <div class="d-flex flex-column flex-grow-1">
    <ListingComponent class="bg-white"
                      ref="productList"
                      :addNewButton="addNewButton"
                      :requestProvider="requestProvider"
                      :columnConfig="columnConfig">
    </ListingComponent>
    <ConfirmationModal ref="confirmationModal"
                       content="Do you really want to delete"
                       @cancel="closeDeleteModal"
                       @yes="removeItem">
    </ConfirmationModal>
  </div>
</template>

<script>
import ListingComponent from '../../components/listing/ListingComponent'
export default {
  name: 'ProductListPage',
  data () {
    return {
      columnConfig: [
        {
          columnCode: 'action',
          columnName: 'Action',
          value: item => {
            return (
              <b-dropdown variant="button" toggle-class="btn btn-sm btn-light " class="dropdown-options">
                <b-dropdown-item href={'#/sales-order/summary/' + item.id}>Summary</b-dropdown-item>
                <b-dropdown-item href={'#/sales-order/item/' + item.id}>Edit</b-dropdown-item>
                <b-dropdown-item onClick={() => this.showDeleteModal(item.id)}>Delete</b-dropdown-item>
              </b-dropdown>
            )
          }
        },
        {
          columnCode: 'number',
          columnName: 'Number',
          value: item => {
            return item.number
          },
          link: item => {
            return '/sales-order/summary/' + item.id
          }
        },
        {
          columnCode: 'customer',
          columnName: 'Customer',
          value: item => {
            if (item.customer != null) {
              return item.customer.firstName + ' ' + item.customer.lastName
            } else {
              return item.customerId
            }
          },
          link: item => {
            return '/sales-order/summary/' + item.id
          }
        },
        {
          columnCode: 'date',
          columnName: 'Date',
          value: item => {
            return item.orderDate
          }
        },
        {
          columnCode: 'source',
          columnName: 'Source',
          value: item => {
            return item.source ? item.source === 'STOREFRONT' ? 'Storefront' : item.source === 'BACKEND' ? 'Backend' : '' : ''
          }
        },
        {
          columnCode: 'total',
          columnName: 'Total',
          value: item => {
            return item.total
          }
        },
        {
          columnCode: 'currency',
          columnName: 'Currency',
          value: item => {
            if (item.currency != null) {
              return item.currency.name
            } else {
              return item.currencyId
            }
          }
        },
        {
          columnCode: 'status',
          columnName: 'Status',
          value: item => {
            if (item.status === 'NEW') {
              return 'New'
            } else if (item.status === 'PROCESSING') {
              return 'Processing'
            } else if (item.status === 'PENDING_PAYMENT') {
              return 'Pending Payment'
            } else if (item.status === 'PAYMENT_REVIEW') {
              return 'Payment Review'
            } else if (item.status === 'ON_HOLD') {
              return 'On Hold'
            } else if (item.status === 'OPEN') {
              return 'Open'
            } else if (item.status === 'COMPLETED') {
              return 'Completed'
            } else if (item.status === 'SUSPECTED') {
              return 'Suspected'
            } else if (item.status === 'CANCELLED') {
              return 'Canceled'
            }
            return ''
          }
        }
      ]
    }
  },
  methods: {
    requestProvider (payload) {
      return this.axios.get('orders', payload)
    },
    addNewButton () {
      return (
        <router-link class="btn btn-success mr-3" to="/sales-order/item">
          <div class="fa fa-plus"></div>
        </router-link>
      )
    },
    showDeleteModal (id) {
      if (!id) return
      this.id = id
      this.$refs.confirmationModal.show()
    },
    closeDeleteModal () {
      this.id = null
      this.$nextTick(() => {
        this.$refs.confirmationModal.hide()
      })
    },
    removeItem () {
      if (!this.id) {
        return
      }
      this.axios.delete('orders/' + this.id)
        .then((response) => {
          this.$refs.productList.doRequest()
          if (response.data.success) {
            this.$toastr.success('Successfully deleted')
          }
          this.$refs.confirmationModal.hide()
        })
    }
  },
  components: {
    ListingComponent
  }
}
</script>

<style scoped>

</style>
