import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Vuelidate from 'vuelidate'
import VueToastr2 from 'vue-toastr-2'
import loading from './plugins/loading'
import BootstrapVue from 'bootstrap-vue'
import axios from './plugins/axios-plugin'
import components from './plugins/component-plugin'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import VueCodemirror from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import CKEditor from 'ckeditor4-vue'

window.toastr = require('toastr')

Vue.use(axios)
Vue.use(loading)
Vue.use(Vuelidate)
Vue.use(VueToastr2)
Vue.use(components)
Vue.use(BootstrapVue)
Vue.use(Antd)
Vue.use(VueCodemirror)
Vue.use(CKEditor)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
