<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i>General Details
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Number</label>
                          <div class="summary-field">{{ item.number}}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Customer</label>
                          <div class="summary-field">{{ item.customer != null ? item.customer.firstName + ' ' + item.customer.lastName : ''}}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Date</label>
                          <div class="summary-field">{{ item.orderDate }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Source</label>
                          <div class="summary-field">{{ item.source ? item.source === 'STOREFRONT' ? 'Storefront' : item.source === 'BACKEND' ? 'Backend' : '' : '' }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Currency</label>
                          <div class="summary-field">{{ item.currencyName}}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Status</label>
                          <div class="summary-field">{{ item.status === 'NEW' ? 'New' : item.status === 'PROCESSING' ? 'Processing' : item.status === 'PENDING_PAYMENT' ? 'Pending Payment' : item.status === 'PAYMENT_REVIEW' ? 'Payment Review' : item.status === 'ON_HOLD' ? 'On Hold' : item.status === 'OPEN' ? 'Open' : item.status === 'COMPLETED' ? 'Completed' : item.status === 'SUSPECTED' ? 'Suspected' : item.status === 'CANCELLED' ? 'Canceled' : '' }}</div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="far fa-address-book mr-2"></i>Items Ordered
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="12">
                        <table class="table table-bordered table-sm">
                          <thead class="thead-light">
                          <tr>
                            <th width="5%" scope="col" class="text-center">#</th>
                            <th width="15%" scope="col">Product</th>
                            <th width="15%" scope="col">Description</th>
                            <th width="10%" scope="col">Quantity</th>
                            <th width="10%" scope="col">Price</th>
                            <th width="10%" scope="col">Discount</th>
                            <th width="10%" scope="col">Net Amount</th>
                            <th width="10%" scope="col">Tax Rate</th>
                            <th width="10%" scope="col">Row Total</th>
                          </tr>
                          </thead>
                          <tbody v-for="(orderItem, index) in orderItems" :key="index">
                          <tr>
                            <td class="index text-center" style="vertical-align: middle;"><span>{{ +index + 1 }}</span></td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ orderItem.productName }}</span>
                            </td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ orderItem.description }}</span>
                            </td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ orderItem.qty }}</span>
                            </td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ orderItem.price }}</span>
                            </td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ orderItem.discount }}</span>
                            </td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ orderItem.netAmount }}</span>
                            </td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ orderItem.vatRate }}</span>
                            </td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ orderItem.total }}</span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px">
        <b-col lg="6">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="far fa-address-book mr-2"></i>Billing Address
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="12">
                        <AddressPanel ref="billAddress" :isSummary="true"/>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
        <b-col lg="6">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="far fa-address-book mr-2"></i>Shipping Address
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="12">
                        <AddressPanel ref="shipAddress" :isSummary="true"/>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fas fa-link mr-2"></i>Attachments
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="12">
                        <AttachmentComponent ref="uploadItem" :isSummary="true"/>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
              <div class="add-edit-fields-action-bar">
                <div class="form-actions">
                  <router-link :to="'/sales-order/item/' + item.id">
                    <b-button type="button" variant="primary" class="mr-2">Edit</b-button>
                  </router-link>
                  <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/sales-order'">
                    Cancel
                  </router-link>
                </div>
              </div>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import AttachmentComponent from '../../components/ui/AttachmentComponent'
import AddressPanel from '../../components/AddressPanel'
export default {
  name: 'CustomerAddEditPage',
  data () {
    return {
      item: {
        name: ''
      },
      orderItems: [
        { id: null, product: null, productId: null, productName: null, description: null, qty: null, price: null, discount: null, netAmount: 0, vatRate: null, total: 0 }
      ]
    }
  },
  created () {
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('orders/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.$refs.uploadItem.setData(this.item.files)
        if (this.item.billAddress) {
          this.$refs.billAddress.setItem(this.item.billAddress)
        }
        if (this.item.shipAddress) {
          this.$refs.shipAddress.setItem(this.item.shipAddress)
        }
        this.orderItems = this.item.items
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return 'Customer Summary'
    }
  },
  components: {
    AttachmentComponent,
    AddressPanel
  }
}
</script>

<style scoped>

</style>
