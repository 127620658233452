<template>
  <div class="d-flex flex-column flex-grow-1">
    <ListingComponent class="bg-white"
                      ref="productList"
                      :addNewButton="addNewButton"
                      :requestProvider="requestProvider"
                      :columnConfig="columnConfig">
    </ListingComponent>
    <ConfirmationModal ref="confirmationModal"
                       content="Do you really want to delete"
                       @cancel="closeDeleteModal"
                       @yes="removeItem">
    </ConfirmationModal>
  </div>
</template>

<script>
import ListingComponent from '../../components/listing/ListingComponent'
export default {
  name: 'ProductListPage',
  data () {
    return {
      columnConfig: [
        {
          columnCode: 'action',
          columnName: 'Action',
          value: item => {
            return (
              <b-dropdown variant="button" toggle-class="btn btn-sm btn-light " class="dropdown-options">
                <b-dropdown-item href={'#/user/vendor/summary/' + item.id}>Summary</b-dropdown-item>
                <b-dropdown-item href={'#/user/vendor/item/' + item.id}>Edit</b-dropdown-item>
                <b-dropdown-item onClick={() => this.showDeleteModal(item.id)}>Delete</b-dropdown-item>
              </b-dropdown>
            )
          }
        },
        {
          columnCode: 'image',
          columnName: 'Image',
          customizeColumn: true,
          value: item => {
            if (!item.imageUrl) {
              return <img alt='category-product' src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAnBAMAAAB+jUwGAAAAElBMVEXd3d0AAADBwcGlpaWKiopubm7m/BpWAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQ0lEQVQokWNgGAX0AUpAiEyDAJOxs7EBEg0VdWFwYGYwANNwQRNjpQAGFygNBw5KDiBBMA0308hEAagdTNPEJyMcAAA93wilouyRBgAAAABJRU5ErkJggg=='}/>
            }
            return <img src={item.imageUrl}/>
          }
        },
        {
          columnCode: 'type',
          columnName: 'Type',
          value: item => {
            if (item.type === 'COMPANY') {
              return 'Company'
            } else if (item.type === 'INDIVIDUAL') {
              return 'Individual'
            }
            return item.type
          },
          link: item => {
            return '/user/vendor/summary/' + item.id
          }
        },
        {
          columnCode: 'companyName',
          columnName: 'Company Name',
          value: item => {
            return item.companyName
          },
          link: item => {
            return '/user/vendor/summary/' + item.id
          }
        },
        {
          columnCode: 'firstName',
          columnName: 'First Name',
          value: item => {
            return item.firstName
          },
          link: item => {
            return '/user/vendor/summary/' + item.id
          }
        },
        {
          columnCode: 'lastName',
          columnName: 'Last Name',
          value: item => {
            return item.lastName
          },
          link: item => {
            return '/user/vendor/summary/' + item.id
          }
        },
        {
          columnCode: 'phone',
          columnName: 'Phone',
          value: item => {
            return item.phone
          }
        },
        {
          columnCode: 'email',
          columnName: 'Email',
          value: item => {
            return item.email
          }
        },
        {
          columnCode: 'status',
          columnName: 'Status',
          value: item => {
            return item.status === 'ACTIVE' ? 'Active' : item.status === 'INACTIVE' ? 'Inactive' : 'Blocked'
          }
        }
      ]
    }
  },
  methods: {
    requestProvider (payload) {
      return this.axios.get('vendors', payload)
    },
    addNewButton () {
      return (
        <router-link class="btn btn-success mr-3" to="/user/vendor/item">
          <div class="fa fa-plus"></div>
        </router-link>
      )
    },
    showDeleteModal (id) {
      if (!id) return
      this.id = id
      this.$refs.confirmationModal.show()
    },
    closeDeleteModal () {
      this.id = null
      this.$nextTick(() => {
        this.$refs.confirmationModal.hide()
      })
    },
    removeItem () {
      if (!this.id) {
        return
      }
      this.axios.delete('vendors/' + this.id)
        .then((response) => {
          this.$refs.productList.doRequest()
          if (response.data.success) {
            this.$toastr.success('Successfully deleted')
          }
          this.$refs.confirmationModal.hide()
        })
    }
  },
  components: {
    ListingComponent
  }
}
</script>

<style scoped>

</style>
