<template>
  <label>
    <span><slot/></span>
    <div style="margin-top: 0.5rem">
      <span v-if="required" class="required-bootstrap-field"></span>
      <b-form-input v-bind="$attrs"
                    :value="value"
                    :class="addClass"
                    :type="inputtype"
                    @input="$emit('input', $event)">
      </b-form-input>
    </div>
  </label>
</template>

<script>
export default {
  name: 'TextBox',
  props: {
    value: {
      type: [String, Number],
      default: () => ''
    },
    inputtype: {
      type: String,
      default: () => 'text'
    },
    addClass: {
      type: Object
    },
    required: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style scoped>

</style>
