<template>
  <div>
    <div class="d-flex flex-column flex-grow-1">
      <div class="animated fadeIn">
        <b-row>
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>General Details
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="4">
                          <SelectBox :items="types" v-model="item.type">Type</SelectBox>
                          <TextBox v-if="item.type === 'COMPANY'" v-model="item.companyName" required :addClass="{'is-invalid': $v.item.companyName.$error}">
                            Company Name
                          </TextBox>
                          <b-row v-if="item.type === 'INDIVIDUAL'">
                            <b-col sm="6">
                              <TextBox v-model="item.firstName" required :addClass="{'is-invalid': $v.item.firstName.$error}">
                                First Name
                              </TextBox>
                            </b-col>
                            <b-col sm="6">
                              <TextBox v-model="item.lastName" required :addClass="{'is-invalid': $v.item.lastName.$error}">
                                Last Name
                              </TextBox>
                            </b-col>
                          </b-row>
                          <TextBox v-model="item.phone">
                            Phone
                          </TextBox>
                          <TextBox required v-model="item.email" type="email" :addClass="{'is-invalid': $v.item.email.$error}">
                            Email
                          </TextBox>
                        </b-col>
                        <b-col sm="4">
                          <b-row>
                            <b-col sm="6">
                              <TextBox v-model="item.username" required :addClass="{'is-invalid': $v.item.username.$error}">
                                Username
                              </TextBox>
                            </b-col>
                            <b-col sm="6">
                              <TextBox v-model="item.password" :inputtype="passwordType" required :addClass="{'is-invalid': $v.item.password.$error}">
                                Password
                              </TextBox>
                              <span class="icon is-small is-left"
                                    style="position: absolute;top: 38px;right: 30px;">
                            <i :class="passwordButton" @click="passwordClick" style="cursor: pointer"></i>
                          </span>
                            </b-col>
                          </b-row>
                          <DatePicker v-model="item.registeredDate">Registered date</DatePicker>
                          <SelectBox :items="groups" v-model="selectedGroup">Group</SelectBox>
                          <SelectBox :items="statuses" v-model="selectedStatus">Status</SelectBox>
                        </b-col>
                        <b-col sm="4">
                          <label>Image</label>
                          <div style="margin-top: 0.5rem">
                            <ImageBanner ref="banner"/>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fas fa-link mr-2"></i>Attachments
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="12">
                          <AttachmentComponent ref="uploadItem"/>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
                <div class="add-edit-fields-action-bar">
                  <div class="form-actions">
                    <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
                  </div>
                </div>
              </b-card>
            </transition>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import AttachmentComponent from '../../components/ui/AttachmentComponent'
import ImageBanner from '../../components/ImageBanner'

export default {
  name: 'VendorAddModal',
  data () {
    return {
      item: {
        companyName: '',
        firstName: '',
        lastName: '',
        type: 'COMPANY',
        username: '',
        password: '',
        dateOfBirth: null,
        createdDate: new Date(),
        phone: '',
        email: '',
        registeredDate: null,
        lastLogged: null
      },
      passwordType: 'password',
      passwordButton: 'far fa-eye',
      types: [
        { text: 'Company', value: 'COMPANY' },
        { text: 'Individual', value: 'INDIVIDUAL' }
      ],
      selectedGender: 'MALE',
      statuses: [
        { text: 'Active', value: 'ACTIVE' },
        { text: 'Inactive', value: 'INACTIVE' },
        { text: 'Suspended', value: 'SUSPENDED' }
      ],
      selectedStatus: 'ACTIVE',
      groups: [],
      selectedGroup: null
    }
  },
  created () {
    this.loadRelatedData()
  },
  computed: {
    pageName () {
      return 'Add Vendor'
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if ((this.item.type === 'COMPANY' && this.$v.item.companyName.$invalid) || (this.item.type === 'INDIVIDUAL' && this.$v.item.firstName.$invalid && this.$v.item.lastName.$invalid)) {
        this.$v.$touch()
        this.$toastr.error('Please, fill required fields')
        return
      }
      this.$loading.show()
      const _item = { ...this.item }
      _item.status = this.selectedStatus
      _item.gender = this.selectedGender
      _item.groupId = this.selectedGroup
      _item.files = this.$refs.uploadItem.fileList
      const bannerItems = this.$refs.banner.getImages()
      if (bannerItems.length > 0) {
        _item.images = bannerItems
      }

      const axios = this.axios.post('vendors/modal', _item)
      axios.then(response => {
        if (response.status === 200) {
          this.$emit('vendorModalClose', response.data)
        } else {
          this.$toastr.error(response.data.message)
        }
        this.$loading.hide()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$toastr.error(error.response.data.errors.message)
        }
        this.$loading.hide()
      })
    },
    async loadRelatedData () {
      this.$loading.show()
      const [perReq] = await Promise.all([
        this.axios.get('group/vendor')
      ])
      if (perReq && perReq.data) {
        this.setSelectedItems(perReq.data, 'groups')
      }
      this.$loading.hide()
    },
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    },
    openModalManufacturer () {
      return this.$refs['manufacturer-modal'].show()
    },
    passwordClick () {
      if (this.passwordType === 'password') {
        this.passwordType = 'text'
        this.passwordButton = 'far fa-eye-slash'
      } else if (this.passwordType === 'text') {
        this.passwordType = 'password'
        this.passwordButton = 'far fa-eye'
      }
    }
  },
  validations: {
    item: {
      companyName: {
        required
      },
      firstName: {
        required
      },
      lastName: {
        required
      },
      username: {
        required
      },
      password: {
        required
      },
      email: {
        required,
        email
      }
    }
  },
  components: {
    AttachmentComponent,
    ImageBanner
  }
}
</script>

<style scoped>

</style>
