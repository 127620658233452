<template>
  <div>
    <div class="d-flex flex-column flex-grow-1">
      <div class="animated fadeIn">
        <b-row>
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>General Details
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="6">
                          <TextBox v-model="item.name" required :addClass="{'is-invalid': $v.item.name.$error}">Name</TextBox>
                        </b-col>
                        <b-col sm="6">
                          <label>Rate</label>
                          <b-input-group append="%">
                            <span class="required-bootstrap-field"></span>
                            <b-form-input v-model="item.rate" type="number" :class="{'is-invalid': $v.item.rate.$error}"></b-form-input>
                          </b-input-group>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>Specific Rates
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="12">
                          <table class="table table-bordered table-sm">
                            <thead class="thead-light">
                            <tr>
                              <th width="20%" scope="col">Country</th>
                              <th width="15%" scope="col">State</th>
                              <th width="15%" scope="col">City</th>
                              <th width="10%" scope="col">Rate %</th>
                              <th width="20%" scope="col">Tax Name</th>
                              <th width="10%" scope="col">Priority</th>
                              <th width="5%" scope="col">Compound</th>
                              <th width="5%" scope="col">Shipping</th>
                              <th width="5%" style="min-width: 90px" scope="col" class="text-center">Action</th>
                            </tr>
                            </thead>
                            <tbody v-for="(taxItem, index) in taxItems" :key="index">
                            <tr>
                              <td>
                                <LookUp url="address/country-list" v-model="taxItem.country"></LookUp>
                              </td>
                              <td>
                                <LookUp v-model="taxItem.region"
                                        ref="region" url="address/region-list"
                                        :address="taxItem.country != null ? taxItem.country.value + '' : ''"
                                        placeholder="Select">
                                </LookUp>
                              </td>
                              <td>
                                <LookUp v-model="taxItem.city"
                                        ref="city" url="address/city-list"
                                        :address="taxItem.region != null ? taxItem.region.value + '': ''"
                                        placeholder="Select"></LookUp>
                              </td>
                              <td>
                              <TextBox v-model="taxItem.rate" inputtype="number" min="0"></TextBox>
                              </td>
                              <td>
                              <TextBox v-model="taxItem.taxName"></TextBox>
                              </td>
                              <td>
                                <TextBox v-model="taxItem.priority" inputtype="number" min="0"></TextBox>
                              </td>
                              <td style="vertical-align: middle; text-align: center;">
                                <input type="checkbox" id="checkbox" v-model="taxItem.compound">
                              </td>
                              <td style="vertical-align: middle; text-align: center;">
                                <input type="checkbox" id="checkbox2" v-model="taxItem.shipping">
                              </td>
                              <td class="text-center" style="vertical-align: middle;">
                                <b-button variant="success" size="sm" class="m-2" @click="addRow(index)">
                                  <i class="fa fa-plus"></i>
                                </b-button>
                                <b-button variant="danger" size="sm" @click="removeRow(index)">
                                  <i class="fa fa-window-close"></i>
                                </b-button>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                  <div class="add-edit-fields-action-bar">
                    <div class="form-actions">
                      <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
                      <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/settings/tax'">
                        Cancel
                      </router-link>
                    </div>
                  </div>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'TaxAddEditPage',
  data () {
    return {
      item: {
        name: '',
        rate: null
      },
      taxItems: [
        { id: null, country: null, countryId: null, countryName: null, region: null, regionId: null, regionName: null, city: null, cityId: null, cityName: null, rate: null, taxName: null, priority: null, compound: false, shipping: false }
      ],
      taxItem: { id: null, country: null, countryId: null, countryName: null, region: null, regionId: null, regionName: null, city: null, cityId: null, cityName: null, rate: null, taxName: null, priority: null, compound: false, shipping: false }
    }
  },
  created () {
    this.loadRelatedData()
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('tax/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        if (this.item.items) {
          const list = this.item.items.map((item) => {
            const newItem = {
              country: { value: item.countryId, label: item.countryName },
              region: { value: item.regionId, label: item.regionName },
              city: { value: item.cityId, label: item.cityName },
              rate: item.rate + '',
              taxName: item.taxName,
              priority: item.priority + '',
              compound: item.compound,
              shipping: item.shipping
            }
            return newItem
          })
          if (list.length === 0) {
            list.unshift(this.item.items)
          }
          this.taxItems = list
        }
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return this.$route.params.id ? 'Edit Tax' : 'Add Tax'
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if (this.$v.item.$invalid) {
        this.$v.$touch()
        this.$toastr.error('Please, fill required fields')
        return
      }
      this.$loading.show()
      const _item = { ...this.item }
      if (this.taxItems) {
        const list = this.taxItems.map((item) => {
          if (item.country != null && item.country.value) {
            const newItem = {
              countryId: item.country != null ? item.country.value : null,
              countryName: item.country != null ? item.country.label : null,
              regionId: item.region != null ? item.region.value : null,
              regionName: item.region != null ? item.region.label : null,
              cityId: item.city != null ? item.city.value : null,
              cityName: item.city != null ? item.city.label : null,
              rate: item.rate,
              taxName: item.taxName,
              priority: item.priority,
              compound: item.compound,
              shipping: item.shipping
            }
            return newItem
          }
        })
        if (list.length === 0) {
          list.unshift(this.item.items)
        }
        _item.items = list
      }
      let axios
      if (_item.id) {
        axios = this.axios.put('tax', _item)
      } else {
        axios = this.axios.post('tax', _item)
      }
      axios.then(response => {
        if (response.status === 200) {
          this.$toastr.success(response.data)
          this.$router.push('/settings/tax')
        } else {
          this.$toastr.error(response.data.message)
        }
        this.$loading.hide()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$toastr.error(error.response.data.errors.message)
        }
        this.$loading.hide()
      })
    },
    removeRow (index) {
      if (this.taxItems.length > 1) {
        const itemIndex = this.taxItems[index]
        if (itemIndex) {
          this.taxItems.splice(index, 1)
        }
      } else {
        this.$toastr.error(this.$t('youCannotDeleteYouHave1Message'))
      }
    },
    addRow (index) {
      this.taxItems = [
        ...this.taxItems.slice(0, index + 1),
        Object.assign({}, this.taxItem),
        ...this.taxItems.slice(index + 1)
      ]
    },
    async loadRelatedData () {
      // this.$loading.show()
      // const [categoryReq] = await Promise.all([
      //   this.axios.get('categories/items')
      // ])
      // if (categoryReq && categoryReq.data) {
      //   this.setSelectedItems(categoryReq.data, 'categories')
      // }
      // this.$loading.hide()
    },
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    }
  },
  validations: {
    item: {
      name: {
        required
      },
      rate: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>
