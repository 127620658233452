<template>
  <div class="left-sidebar">
    <div id="sidebar-1" title="Sidebar">
      <div class="">
        <ul class="sidebar-menu">
          <!--<li v-for="(item, index) in menu" :key="index">-->
            <!--<router-link class="nav-link d-flex align-items-center" :to="item.route">-->
              <!--<i :class="`${item.icon} mr-2 nav-icon`"/>-->
              <!--<span class="nav-text">{{ item.name }}</span>-->
            <!--</router-link>-->
          <!--</li>-->
          <a-menu
            :default-selected-keys="['1']"
            :default-open-keys="['sub1']"
            mode="inline"
            theme="dark"
            style="background-color: #2f353a"
            :inline-collapsed="collapsed"
          >
            <template v-for="item in list">
              <a-menu-item v-if="!item.children" :key="item.key">
                <router-link :to="item.route" class="d-flex align-items-center">
                  <i :class="`${item.icon} mr-2 nav-icon`"/>
                  <span class="nav-text">{{ item.name }}</span>
                </router-link>
              </a-menu-item>
              <a-sub-menu v-else :key="item.key">
                <span slot="title" class="nav-text">
                  <router-link :to="item.route" style="color: rgba(255, 255, 255, 0.65)">
                    <i :class="`${item.icon} mr-2 nav-icon`"/>
                    <span>{{ item.name }}</span>
                  </router-link>
                </span>
                <a-menu-item  v-for="child in item.children" :key="child.key">
                  <router-link :to="child.route">
                    <i :class="`${child.icon} mr-2 nav-icon`"/>
                    <span >{{ child.name }}</span>
                  </router-link>
                </a-menu-item>
              </a-sub-menu>
            </template>
          </a-menu>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SideBar',
  data () {
    return {
      collapsed: false,
      list: [
        { name: 'Categories', route: '/category', icon: 'fa fa-boxes' },
        { name: 'Products', route: '/product', icon: 'fa fa-shopping-cart' },
        { name: 'Labels', route: '/label', icon: 'fas fa-tags' },
        { name: 'Sales Orders', route: '/sales-order', icon: 'fas fa-cart-arrow-down' },
        { name: 'Discounts', route: '/discount/list', icon: 'fas fa-percent' },
        {
          name: 'Users',
          route: '/user',
          icon: 'fas fa-users',
          children: [
            { name: 'Customers', route: '/user/customer', icon: 'fas fa-user-alt' },
            { name: 'Vendors', route: '/user/vendor', icon: 'fas fa-file-alt' },
            { name: 'Delivery Persons', route: '/user/delivery-person', icon: 'fas fa-people-carry' },
            { name: 'Staff', route: '/user/staff', icon: 'fas fa-users' }
          ]
        },
        {
          name: 'Content',
          route: '/content',
          icon: 'far fa-list-alt',
          children: [
            { name: 'Articles', route: '/content/article', icon: 'far fa-newspaper' },
            { name: 'Pages', route: '/content/page', icon: 'fas fa-file-alt' },
            { name: 'FAQ', route: '/content/faq', icon: 'fa fa-question' }
          ]
        },
        {
          name: 'Storefront',
          route: '/storefront',
          icon: 'fas fa-store-alt',
          children: [
            { name: 'Themes', route: '/storefront/theme', icon: 'fas fa-palette' },
            { name: 'Menus', route: '/storefront/menus', icon: 'fas fa-bars' },
            { name: 'Banners', route: '/storefront/banner', icon: 'far fa-file-image' },
            { name: 'Editor', route: '/storefront/template', icon: 'far fa-edit' }
          ]
        },
        { name: 'Stores', route: '/store/list', icon: 'fas fa-store' },
        {
          name: 'Settings',
          route: '/settings',
          icon: 'fa fa-cogs',
          children: [
            {
              name: 'General',
              route: '/settings/general-form',
              icon: 'fa fa-newspaper'
            },
            {
              name: 'Image sizes',
              route: '/settings/image-size',
              icon: 'fas fa-crop'
            },
            {
              name: 'Payment Settings',
              route: '/settings/payment-settings',
              icon: 'fas fa-money-check-alt'
            },
            { name: 'Payment Methods', route: '/settings/payment-method', icon: 'fas fa-money-check-alt' },
            { name: 'Tax Rates', route: '/settings/tax', icon: 'fas fa-hand-holding-usd' },
            { name: 'Commissions', route: '/settings/comission', icon: 'fas fa-coins' },
            { name: 'Currencies', route: '/settings/currency', icon: 'fas fa-dollar-sign' },
            { name: 'Languages', route: '/settings/language', icon: 'fas fa-language' },
            { name: 'Attributes', route: '/settings/custom-field', icon: 'fab fa-elementor' },
            { name: 'Badges', route: '/settings/badge', icon: 'fas fa-ribbon' },
            { name: 'Groups', route: '/settings/group', icon: 'fas fa-object-group' }
          ]
        },
        {
          name: 'Add-ons',
          route: '/add-on/form',
          icon: 'fa fa-cogs'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
