<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> General Details
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="3">
                        <TextBox v-model="item.name" placeholder="Name" required :addClass="{'is-invalid': $v.item.name.$error}">Name</TextBox>
                        <TextBox v-model="item.code" placeholder="Identifier" required :addClass="{'is-invalid': $v.item.code.$error}">Identifier </TextBox>
                        <SelectBox :items="types" v-model="widgetType">Field type</SelectBox>
                      </b-col>
                      <b-col sm="3">
                        <div style="padding-top: 10px">
                          <label>Mandatory field</label>
                          <b-row>
                            <b-col sm="6">
                              <input type="radio" v-model="mandatoryYes" :checked="mandatoryYes" @input="onChangeMandatoryYes"
                                     value="true"> Yes
                            </b-col>
                            <b-col sm="6">
                              <input type="radio" v-model="mandatoryNo" :checked="mandatoryNo" @input="onChangeMandatoryNo"
                                     value="true"> No
                            </b-col>
                          </b-row>
                        </div>
                        <div style="padding-top: 25px">
                          <label>Show in listing</label>
                          <b-row>
                            <b-col sm="6">
                              <input type="radio" v-model="showInListingYes" :checked="showInListingYes" @input="onChangeShowInListingYes"
                                     value="true"> Yes
                            </b-col>
                            <b-col sm="6">
                              <input type="radio" v-model="showInListingNo" :checked="showInListingNo" @input="onChangeShowInListingNo"
                                     value="true"> No
                            </b-col>
                          </b-row>
                        </div>
                        <div style="padding-top: 25px">
                          <label>Show in filter</label>
                          <b-row>
                            <b-col sm="6">
                              <input type="radio" v-model="showInFilterYes" :checked="showInFilterYes" @input="onChangeShowInFilterYes"
                                     value="true"> Yes
                            </b-col>
                            <b-col sm="6">
                              <input type="radio" v-model="showInFilterNo" :checked="showInFilterNo" @input="onChangeShowInFilterNo"
                                     value="true"> No
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                      <b-col sm="3">
                        <SelectBox :items="applyToes" v-model="selectedApply">Apply To</SelectBox>
                        <div v-if="selectedApply === 'SPECIFIC_PRODUCTS'">
                          <label>Products</label>
                          <VSelect multiple v-model="selectedProducts" :options="products" />
                        </div>
                        <div style="padding-top: 25px" v-if="widgetType === 'DROPDOWN' || widgetType === 'RADIO_BUTTON' || widgetType === 'LOOKUP'">
                          <label>Use as Variation</label>
                          <b-row>
                            <b-col sm="6">
                              <input type="radio" v-model="useVariationYes" :checked="useVariationYes" @input="onChangeuseVariationYes"
                                     value="true"> Yes
                            </b-col>
                            <b-col sm="6">
                              <input type="radio" v-model="useVariationNo" :checked="useVariationNo" @input="onChangeuseVariationNo"
                                     value="true"> No
                            </b-col>
                          </b-row>
                        </div>
                      </b-col>
                      <b-col sm="3">
                        <label>Preview </label>
                        <div style="border: 1px solid #ced4da;min-height: 185px;">
                          <b-row>
                            <b-col sm="1"> </b-col>
                            <b-col sm="10">
                              <TextBox v-if="widgetType === 'TEXT_BOX'" :v-model="preview">{{ item.name }}</TextBox>
                              <TextBox placeholder="0" inputtype="number" v-if="widgetType === 'NUMBER_FIELD'" :v-model="preview">{{ item.name }}</TextBox>
                              <div v-if="widgetType === 'TEXT_AREA'" >
                                <label>{{ item.name }}</label>
                                <textarea v-model="preview" class="form-control"></textarea>
                              </div>
                              <DatePicker v-if="widgetType === 'DATEPICKER'" v-model="preview">{{item.name}}</DatePicker>
                              <SelectBox v-if="widgetType === 'DROPDOWN'" :items="this.predefinedDropdownValues" v-model="preview">{{item.name}}</SelectBox>
                              <div v-if="widgetType === 'CHECKBOX'" >
                                <label>{{item.name}}</label>
                                <div v-for="(predefinedValue, index) in predefinedValues" :key="index">
                                  <span>{{predefinedValue.name + ' '}}</span><input type="checkbox" value="true"/>
                                </div>
                              </div>
                              <div v-if="widgetType === 'RADIO_BUTTON'" >
                                <label>{{item.name}}</label>
                                <div v-for="(predefinedValue, index) in predefinedValues" :key="index">
                                  <span>{{predefinedValue.name + ' '}}</span><input type="radio" value="true"/>
                                </div>
                              </div>
                            </b-col>
                            <b-col sm="1"> </b-col>
                          </b-row>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px" v-if="widgetType === 'DROPDOWN' || widgetType === 'CHECKBOX' || widgetType === 'RADIO_BUTTON'">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i>Predefined Values
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="6">
                        <b-row>
                          <b-col sm="12">
                            <b-input-group class="mt-3">
                              <b-form-input v-model="preDefValue"></b-form-input>
                              <b-input-group-append>
                                <b-button @click="addPredefinedvalue()">Add Value</b-button>
                              </b-input-group-append>
                            </b-input-group>
                          </b-col>
                          <b-col sm="12" style="padding-top: 10px">
                            <table class="table table-bordered table-sm">
                              <thead class="thead-light">
                              <tr>
                                <th width="10%" scope="col">ID</th>
                                <th width="50%" scope="col">Value</th>
                                <th width="30%" scope="col">Sort order</th>
                                <th width="10%" scope="col">Action</th>
                              </tr>
                              </thead>
                              <tbody v-for="(predefinedValue, index) in predefinedValues" :key="index">
                              <tr>
                                <td class="text-center" style="vertical-align: middle;">
                                  <span >{{ predefinedValue.id }}</span>
                                </td>
                                <td class="text-center" style="vertical-align: middle;">
                                  <span >{{ predefinedValue.name }}</span>
                                </td>
                                <td>
                                  <TextBox inputtype="number" v-model="predefinedValue.sorder"></TextBox>
                                </td>
                                <td class="text-center" style="vertical-align: middle;">
                                  <b-button variant="danger" size="sm" @click="removeRow(index)">
                                    <i class="fa fa-window-close"></i>
                                  </b-button>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> Frontend properties
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="4">
                        <label>Show on Product listing</label>
                        <b-row>
                          <b-col sm="6">
                            <input type="radio" v-model="showOnProductYes" :checked="showOnProductYes" @input="onChangeShowOnProductYes"
                                   value="true"> Yes
                          </b-col>
                          <b-col sm="6">
                            <input type="radio" v-model="showOnProductNo" :checked="showOnProductNo" @input="onChangeShowOnProductNo"
                                   value="true"> No
                          </b-col>
                        </b-row>
                        <label style="padding-top: 15px">Sort by on Product listing</label>
                        <b-row>
                          <b-col sm="6">
                            <input type="radio" v-model="sortByProductYes" :checked="sortByProductYes" @input="onChangeSortByProductYes"
                                   value="true"> Yes
                          </b-col>
                          <b-col sm="6">
                            <input type="radio" v-model="sortByProductNo" :checked="sortByProductNo" @input="onChangeSortByProductNo"
                                   value="true"> No
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col sm="4">
                        <label>Visible on Product view </label>
                        <b-row>
                          <b-col sm="6">
                            <input type="radio" v-model="visibleOnProductYes" :checked="visibleOnProductYes" @input="onChangevisibleOnProductYes"
                                   value="true"> Yes
                          </b-col>
                          <b-col sm="6">
                            <input type="radio" v-model="visibleOnProductNo" :checked="visibleOnProductNo" @input="onChangevisibleOnProductNo"
                                   value="true"> No
                          </b-col>
                        </b-row>
                        <label style="padding-top: 15px">Use in Quick Search</label>
                        <b-row>
                          <b-col sm="6">
                            <input type="radio" v-model="useQuickSearchYes" :checked="useQuickSearchYes" @input="onChangeuseQuickSearchYes"
                                   value="true"> Yes
                          </b-col>
                          <b-col sm="6">
                            <input type="radio" v-model="useQuickSearchNo" :checked="useQuickSearchNo" @input="onChangeuseQuickSearchNo"
                                   value="true"> No
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col sm="4">
                        <label>Use in Advanced Search</label>
                        <b-row>
                          <b-col sm="6">
                            <input type="radio" v-model="useAdvancedSearchYes" :checked="useAdvancedSearchYes" @input="onChangeuseAdvancedSearchYes"
                                   value="true"> Yes
                          </b-col>
                          <b-col sm="6">
                            <input type="radio" v-model="useAdvancedSearchNo" :checked="useAdvancedSearchNo" @input="onChangeuseAdvancedSearchNo"
                                   value="true"> No
                          </b-col>
                        </b-row>
                        <label style="padding-top: 15px">Comparable</label>
                        <b-row>
                          <b-col sm="6">
                            <input type="radio" v-model="comparableYes" :checked="comparableYes" @input="onChangecomparableYes"
                                   value="true"> Yes
                          </b-col>
                          <b-col sm="6">
                            <input type="radio" v-model="comparableNo" :checked="comparableNo" @input="onChangecomparableNo"
                                   value="true"> No
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
              <div class="add-edit-fields-action-bar">
                <div class="form-actions">
                  <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
                  <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/settings/custom-field'">
                    Cancel
                  </router-link>
                </div>
              </div>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import VSelect from 'vue-select'

export default {
  name: 'AttributeAddEditPage',
  data () {
    return {
      item: {
        name: '',
        code: ''
      },
      preview: null,
      mandatoryYes: false,
      mandatoryNo: true,
      showInListingYes: false,
      showInListingNo: true,
      showInFilterYes: false,
      showInFilterNo: true,
      showOnProductYes: false,
      showOnProductNo: true,
      visibleOnProductYes: false,
      visibleOnProductNo: true,
      useQuickSearchYes: false,
      useAdvancedSearchYes: false,
      useAdvancedSearchNo: true,
      comparableYes: false,
      comparableNo: true,
      useQuickSearchNo: true,
      sortByProductYes: false,
      sortByProductNo: true,
      useVariationYes: false,
      useVariationNo: true,
      applyToes: [
        { text: 'Select', value: '' },
        { text: 'Specific Products', value: 'SPECIFIC_PRODUCTS' },
        { text: 'Selected Types', value: 'SELECTED_TYPES' }
      ],
      types: [
        { text: 'Text Box', value: 'TEXT_BOX' },
        { text: 'Number Field', value: 'NUMBER_FIELD' },
        { text: 'Text Area', value: 'TEXT_AREA' },
        { text: 'Datepicker', value: 'DATEPICKER' },
        { text: 'Dropdown', value: 'DROPDOWN' },
        { text: 'Radio Button', value: 'RADIO_BUTTON' },
        { text: 'CheckBox', value: 'CHECKBOX' },
        { text: 'LookUp', value: 'LOOKUP' },
        { text: 'MultiLookup', value: 'MULTI_LOOKUP' },
        { text: 'Image', value: 'IMAGE' },
        { text: 'Video ', value: 'VIDEO' }
      ],
      widgetType: 'TEXT_BOX',
      selectedApply: '',
      products: [],
      selectedProducts: [],
      predefinedValues: [],
      predefinedDropdownValues: [],
      preDefValueId: 0,
      preDefValueSorder: 1,
      preDefValue: ''
    }
  },
  created () {
    this.loadRelatedData()
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('custom-fields/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.widgetType = this.item.widgetType
        this.selectedApply = this.item.applyTo

        if (this.item.mandatory) {
          this.mandatoryYes = true
          this.mandatoryNo = false
        } else {
          this.mandatoryYes = false
          this.mandatoryNo = true
        }
        if (this.item.mandatory) {
          this.mandatoryYes = true
          this.mandatoryNo = false
        } else {
          this.mandatoryYes = false
          this.mandatoryNo = true
        }
        this.selectedApply = this.item.applyTo
        if (this.item.showInListing) {
          this.showInListingYes = true
          this.showInListingNo = false
        } else {
          this.showInListingYes = false
          this.showInListingNo = true
        }
        if (this.item.showInFilter) {
          this.showInFilterYes = true
          this.showInFilterNo = false
        } else {
          this.showInFilterYes = false
          this.showInFilterNo = true
        }
        if (this.item.showOnProductList) {
          this.showOnProductYes = true
          this.showOnProductNo = false
        } else {
          this.showOnProductYes = false
          this.showOnProductNo = true
        }
        if (this.item.sortByProductList) {
          this.sortByProductYes = true
          this.sortByProductNo = false
        } else {
          this.sortByProductYes = false
          this.sortByProductNo = true
        }
        if (this.item.visibleProductView) {
          this.visibleOnProductYes = true
          this.visibleOnProductNo = false
        } else {
          this.visibleOnProductYes = false
          this.visibleOnProductNo = true
        }
        if (this.item.useQuickSearch) {
          this.useQuickSearchYes = true
          this.useQuickSearchNo = false
        } else {
          this.useQuickSearchYes = false
          this.useQuickSearchNo = true
        }
        if (this.item.useAdvancedSearch) {
          this.useAdvancedSearchYes = true
          this.useAdvancedSearchNo = false
        } else {
          this.useAdvancedSearchYes = false
          this.useAdvancedSearchNo = true
        }
        if (this.item.comparable) {
          this.comparableYes = true
          this.comparableNo = false
        } else {
          this.comparableYes = false
          this.comparableNo = true
        }
        if (this.item.useVariation) {
          this.useVariationYes = true
          this.useVariationNo = false
        } else {
          this.useVariationYes = false
          this.useVariationNo = true
        }
        if (this.item.products) {
          const list = []
          this.item.products.forEach(function (item) {
            list.push({
              label: item.name,
              value: item.id
            })
          })
          this.selectedProducts = list
        }
        if (this.item.predefinedValues) {
          this.predefinedValues = this.item.predefinedValues
          if (this.widgetType === 'DROPDOWN') {
            this.selectBoxitems(this.predefinedValues, 'predefinedDropdownValues')
          }
        }
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return this.$route.params.id ? 'Edit Attribute' : 'Add Attribute'
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if (this.$v.item.$invalid) {
        this.$v.$touch()
        this.$toastr.error('Please, fill required fields')
        return
      }
      this.$loading.show()
      const _item = { ...this.item }
      _item.widgetType = this.widgetType
      if (this.widgetType === 'NUMBER_FIELD') {
        _item.dataType = 'NUMBER'
      } else if (this.widgetType === 'DATEPICKER') {
        _item.dataType = 'DATE'
      } else {
        _item.dataType = 'STRING'
      }

      _item.mandatory = this.mandatoryYes
      _item.applyTo = this.selectedApply
      _item.showInListing = this.showInListingYes
      _item.showInFilter = this.showInFilterYes
      _item.showOnProductList = this.showOnProductYes
      _item.sortByProductList = this.sortByProductYes
      _item.visibleProductView = this.visibleOnProductYes
      _item.useQuickSearch = this.useQuickSearchYes
      _item.useAdvancedSearch = this.useAdvancedSearchYes
      _item.comparable = this.comparableYes
      _item.useVariation = this.useVariationYes
      if (this.selectedProducts) {
        const list = []
        this.selectedProducts.forEach(function (item) {
          list.push({
            id: item.value,
            name: item.label
          })
        })
        _item.products = list
      }
      if (this.widgetType === 'DROPDOWN' || this.widgetType === 'CHECKBOX' || this.widgetType === 'RADIO_BUTTON') {
        _item.predefinedValues = this.predefinedValues
      }
      let axios
      if (_item.id) {
        axios = this.axios.put('custom-fields', _item)
      } else {
        axios = this.axios.post('custom-fields', _item)
      }
      axios.then(response => {
        if (response.status === 200) {
          this.$toastr.success(response.data)
          this.$router.push('/settings/custom-field')
        } else {
          this.$toastr.error(response.data.message)
        }
        this.$loading.hide()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$toastr.error(error.response.data.errors.message)
        }
        this.$loading.hide()
      })
    },
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    },
    onChangeMandatoryYes () {
      this.mandatoryYes = true
      this.mandatoryNo = false
    },
    onChangeMandatoryNo () {
      this.mandatoryNo = true
      this.mandatoryYes = false
    },
    onChangeShowInListingYes () {
      this.showInListingYes = true
      this.showInListingNo = false
    },
    onChangeShowInListingNo () {
      this.showInListingNo = true
      this.showInListingYes = false
    },
    onChangeShowInFilterYes () {
      this.showInFilterYes = true
      this.showInFilterNo = false
    },
    onChangeShowInFilterNo () {
      this.showInFilterNo = true
      this.showInFilterYes = false
    },
    onChangevisibleOnProductYes () {
      this.visibleOnProductYes = true
      this.visibleOnProductNo = false
    },
    onChangevisibleOnProductNo () {
      this.visibleOnProductNo = true
      this.visibleOnProductYes = false
    },
    onChangeuseQuickSearchYes () {
      this.useQuickSearchYes = true
      this.useQuickSearchNo = false
    },
    onChangeuseQuickSearchNo () {
      this.useQuickSearchNo = true
      this.useQuickSearchYes = false
    },
    onChangeShowOnProductYes () {
      this.showOnProductYes = true
      this.showOnProductNo = false
    },
    onChangeShowOnProductNo () {
      this.showOnProductNo = true
      this.showOnProductYes = false
    },
    onChangeSortByProductYes () {
      this.sortByProductYes = true
      this.sortByProductNo = false
    },
    onChangeSortByProductNo () {
      this.sortByProductNo = true
      this.sortByProductYes = false
    },
    onChangeuseAdvancedSearchYes () {
      this.useAdvancedSearchYes = true
      this.useAdvancedSearchNo = false
    },
    onChangeuseAdvancedSearchNo () {
      this.useAdvancedSearchNo = true
      this.useAdvancedSearchYes = false
    },
    onChangecomparableYes () {
      this.comparableYes = true
      this.comparableNo = false
    },
    onChangecomparableNo () {
      this.comparableNo = true
      this.comparableYes = false
    },
    onChangeuseVariationYes () {
      this.useVariationYes = true
      this.useVariationNo = false
    },
    onChangeuseVariationNo () {
      this.useVariationNo = true
      this.useVariationYes = false
    },
    setSelectedLookUpItems (items, code) {
      const _items = items.map(item => {
        return { label: item.name, value: item.id }
      })
      this[code] = _items
    },
    addPredefinedvalue () {
      if (this.preDefValue) {
        const item = {
          id: this.preDefValueId,
          name: this.preDefValue,
          sorder: this.preDefValueSorder
        }
        this.predefinedValues.push(item)
        this.preDefValue = ''
        this.preDefValueId = this.preDefValueId + 1
        this.preDefValueSorder = this.preDefValueSorder + 1
        this.selectBoxitems(this.predefinedValues, 'predefinedDropdownValues')
      }
    },
    removeRow (index) {
      const itemIndex = this.predefinedValues[index]
      if (itemIndex) {
        this.predefinedValues.splice(index, 1)
        this.selectBoxitems(this.predefinedValues, 'predefinedDropdownValues')
      }
    },
    selectBoxitems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      this[code] = _items
    },
    async loadRelatedData () {
      this.$loading.show()
      const [productReq] = await Promise.all([
        this.axios.get('products/items')
      ])
      if (productReq && productReq.data) {
        this.setSelectedLookUpItems(productReq.data, 'products')
      }
      this.$loading.hide()
    }
  },
  validations: {
    item: {
      name: {
        required
      },
      code: {
        required
      }
    }
  },
  components: {
    VSelect
  }
}
</script>

<style scoped>

</style>
