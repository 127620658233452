<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> General Details
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="4">
                        <TextBox v-model="item.name" @input="onChangeName" placeholder="Name" :addClass="{'is-invalid': $v.item.name.$error}" required>
                          Name:
                        </TextBox>
                        <LookUp  url="categories/items" v-model="selectedParent">Parent Category:</LookUp>
                      </b-col>
                      <b-col sm="4">
                        <SelectBox :items="statuses" v-model="selectedStatus">Status:</SelectBox>
                        <div class="form-group">
                          <label>Description:</label>
                          <textarea v-model="item.description" @input="onChangeDescription" class="form-control"></textarea>
                        </div>
                      </b-col>
                      <b-col sm="4" class="single-image">
                        <label>Image</label>
                        <div style="margin-top: 0.5rem">
                          <ImageBanner ref="banner"/>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row v-show="enableMultiStore" style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> Stores
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="6">
                        <table class="table table-bordered table-sm">
                          <thead class="thead-light">
                          <tr>
                            <th width="83%" scope="col">Store</th>
                            <th width="17%" scope="col"></th>
                          </tr>
                          </thead>
                          <tbody v-for="(store, index) in storeList" :key="index">
                          <tr>
                            <td>
                              <SelectBox v-model="store.id" :items="stores" ></SelectBox>
                            </td>
                            <td class="text-center" style="vertical-align: middle;">
                              <b-button size="sm" class="m-2" @click="addStoreRow(index)">
                                <i class="fa fa-plus"></i>
                              </b-button>
                              <b-button size="sm" @click="removeStoreRow(index)">
                                <i class="fas fa-minus"></i>
                              </b-button>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> Visibility
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="3">
                        <div class="form-group">
                          <label>Permanent:</label>
                          <input type="radio" name="numbering-type" v-model="permanent" v-on:click="onChangePermanent" value="true" :checked="permanent"/>
                        </div>
                      </b-col>
                      <b-col sm="3">
                        <div class="form-group">
                          <label>Scheduled:</label>
                          <input type="radio" name="numbering-type" v-model="scheduled" v-on:click="onChangeScheduled" value="true" :checked="scheduled"/>
                        </div>
                      </b-col>
                      <b-col sm="6" v-if="scheduled">
                        <b-row>
                          <b-col sm="6">
                            <a-form-item label="From">
                              <a-date-picker v-decorator="[item.visibilityFrom, visibilityFrom]" />
                            </a-form-item>
                          </b-col>
                          <b-col sm="6">
                            <a-form-item label="To">
                              <a-date-picker v-decorator="[item.visibilityTo, visibilityTo]" />
                            </a-form-item>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> Search Engine Optimization
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="12">
                        <SearchEngineOptimization ref="SEO" />
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
              <div class="add-edit-fields-action-bar">
                <div class="form-actions">
                  <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
                  <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/category'">
                    Cancel
                  </router-link>
                </div>
              </div>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import ImageBanner from '../../components/ImageBanner'
import SearchEngineOptimization from '../../components/SearchEngineOptimization'

export default {
  name: 'CategoryAddEditPage',
  data () {
    return {
      item: {
        name: '',
        description: '',
        parentId: null
      },
      parents: [],
      storeList: [
        { id: null }
      ],
      storeItem: { id: null },
      stores: [],
      permanent: true,
      enableMultiStore: false,
      scheduled: false,
      visibilityFrom: null,
      visibilityTo: null,
      selectedParent: null,
      statuses: [
        { text: 'Active', value: 'ACTIVE' },
        { text: 'Inactive', value: 'INACTIVE' }
      ],
      selectedStatus: 'ACTIVE'
    }
  },
  created () {
    this.loadRelatedData()
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      this.$loading.show()
      this.axios.get('store/main-store')
        .then(response => {
          if (response && response.data) {
            this.enableMultiStore = response.data.enableMultiStore
            if (response.data.lookUpItemDto) {
              this.storeList[0].id = response.data.lookUpItemDto.value
            }
          }
          this.$loading.hide()
        })
        .catch(error => {
          if (error.response && error.response.data.status === 400) {
            this.$toastr.error(error.response.data.errors)
          }
          this.$loading.hide()
        })
      return
    }
    this.$loading.show()
    this.axios.get('categories/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.enableMultiStore = this.item.enableMultiStore
        this.selectedParent = this.item.parent || null
        this.selectedStatus = this.item.status
        if (this.item.stores) {
          this.storeList = this.item.stores
        }
        if (this.item.visibility === 'PERMANENT') {
          this.permanent = true
        } else {
          this.scheduled = true
          if (this.item.visibilityFrom) {
            this.visibilityFrom = new Date(this.item.visibilityFrom)
          }
          if (this.item.visibilityTo) {
            this.visibilityTo = new Date(this.item.visibilityTo)
          }
        }
        this.$refs.banner.setImages(this.item.images)
        this.$refs.SEO.setItem(this.item)
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return this.$route.params.id ? 'Edit Category' : 'Add Category'
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if (this.$v.item.$invalid) {
        this.$v.$touch()
        this.$toastr.error('Please, fill required fields')
        return
      }
      this.$loading.show()
      const _item = { ...this.item }
      if (this.selectedParent) {
        _item.parentId = this.selectedParent.value
      }
      _item.status = this.selectedStatus
      if (this.permanent) {
        _item.visibility = 'PERMANENT'
      } else {
        _item.visibility = 'SCHEDULED'
        _item.visibilityFrom = this.visibilityFrom
        _item.visibilityTo = this.visibilityTo
      }
      if (this.storeList && this.enableMultiStore) {
        _item.stores = this.storeList
      }
      this.$refs.SEO.getItem(_item)
      const bannerItems = this.$refs.banner.getImages()
      if (bannerItems.length > 0) {
        _item.images = bannerItems
      }
      let axios
      if (_item.id) {
        axios = this.axios.put('categories', _item)
      } else {
        axios = this.axios.post('categories', _item)
      }
      axios.then(response => {
        if (response.status === 200) {
          this.$toastr.success(response.data)
          this.$router.push('/category')
        } else {
          this.$toastr.error(response.data.message)
        }
        this.$loading.hide()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$toastr.error(error.response.data.errors.message)
        }
        this.$loading.hide()
      })
    },
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    },
    onChangePermanent () {
      this.permanent = true
      this.scheduled = false
    },
    onChangeScheduled () {
      this.permanent = false
      this.scheduled = true
    },
    onChangeName () {
      this.$refs.SEO.setObjectName(this.item.name)
    },
    onChangeDescription () {
      this.$refs.SEO.setObjectDescription(this.item.description)
    },
    addStoreRow (index) {
      this.storeList = [
        ...this.storeList.slice(0, index + 1),
        Object.assign({}, this.storeItem),
        ...this.storeList.slice(index + 1)
      ]
    },
    removeStoreRow (index) {
      if (this.storeList.length > 1) {
        const itemIndex = this.storeList[index]
        if (itemIndex) {
          this.storeList.splice(index, 1)
        }
      } else {
        this.$toastr.error(this.$t('youCannotDeleteYouHave1Message'))
      }
    },
    async loadRelatedData () {
      this.$loading.show()
      const [storeReq] = await Promise.all([
        this.axios.get('store/items')
      ])
      if (storeReq && storeReq.data) {
        this.setSelectedItems(storeReq.data, 'stores')
      }
      this.$loading.hide()
    }
  },
  validations: {
    item: {
      name: {
        required
      }
    }
  },
  components: {
    ImageBanner,
    SearchEngineOptimization
  }
}
</script>

<style scoped>

</style>
