<template>
  <a-collapse v-model="activeKey">
    <a-collapse-panel key="1" header="Categories">
      <div class="menuitems" >
        <div class="table" style="margin-bottom: 0;" v-for="(category, index) in categories" :key="index">
          <tr>
            <td width="5%" style="text-align: right;">
              <input type="checkbox" v-model="category.checked" id="checkboxCategory" @input="onSelectCheckBox(category, 'CATEGORY')">
            </td>
            <td>
              <span>{{ category.name }}</span>
            </td>
          </tr>
        </div>
      </div>
    </a-collapse-panel>
    <a-collapse-panel key="2" header="Products">
      <div class="menuitems" >
        <div class="table" style="margin-bottom: 0;" v-for="(product, index) in products" :key="index">
          <tr>
            <td width="5%" style="text-align: right;">
              <input type="checkbox" id="checkboxProduct" v-model="product.checked" @input="onSelectCheckBox(product, 'PRODUCT')">
            </td>
            <td>
              <span>{{ product.name }}</span>
            </td>
          </tr>
        </div>
      </div>
    </a-collapse-panel>
    <a-collapse-panel key="4" header="Articles">
      <div class="menuitems" >
        <div class="table" style="margin-bottom: 0;" v-for="(article, index) in articles" :key="index">
          <tr>
            <td width="5%" style="text-align: right;">
              <input type="checkbox" id="checkboxArticle" v-model="article.checked" @input="onSelectCheckBox(article, 'ARTICLE')">
            </td>
            <td>
              <span>{{ article.name }}</span>
            </td>
          </tr>
        </div>
      </div>
    </a-collapse-panel>
    <a-collapse-panel key="5" header="Pages">
      <div class="menuitems" >
        <div class="table" style="margin-bottom: 0;" v-for="(page, index) in pages" :key="index">
          <tr>
            <td width="5%" style="text-align: right;">
              <input type="checkbox" id="checkboxPage" v-model="page.checked" @input="onSelectCheckBox(page, 'PAGE')">
            </td>
            <td>
              <span>{{ page.name }}</span>
            </td>
          </tr>
        </div>
      </div>
    </a-collapse-panel>
    <a-collapse-panel key="3" header="Custom">
      <label class="w-100">
        Menu name
        <a-input class="mt-2" v-model="item.menuName"/>
      </label>
      <label class="w-100">
        URL
        <a-input class="mt-2" v-model="item.url" placeholder="https://"/>
      </label>
      <a-button @click.prevent="saveMenu" type="primary" class="mt-3" block :disabled="item.menuName === ''">Save</a-button>
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
export default {
  name: 'LeftSide',
  data () {
    return {
      activeKey: ['1'],
      item: {
        id: '',
        entityId: '',
        code: '',
        menuName: '',
        url: ''
      },
      menuItems: [],
      categories: [{
        id: null, entityId: null, name: null, checked: false
      }],
      products: [{
        id: null, entityId: null, name: null, checked: false
      }],
      articles: [{
        id: null, entityId: null, name: null, checked: false
      }],
      pages: [{
        id: null, entityId: null, name: null, checked: false
      }]
    }
  },
  created () {
    this.$loading.show()
    this.axios.get('menu-item/items')
      .then(response => {
        if (response.data) {
          this.categories = response.data.categories
          this.products = response.data.products
          this.articles = response.data.articles
          this.pages = response.data.pages
        }
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  methods: {
    saveMenu () {
      this.$emit('saveMenuItem', this.item)
      this.item = {
        menuName: '',
        url: '',
        id: '',
        entityId: '',
        code: 'CUSTOM'
      }
    },
    onSelectCheckBox (item, type) {
      if (item && !item.checked) {
        this.item.menuName = item.name
        this.item.url = ''
        this.item.entityId = item.id
        this.item.code = type
        this.$emit('saveMenuItem', this.item)
        this.item = {
          menuName: '',
          url: '',
          id: '',
          entityId: '',
          code: ''
        }
      } else if (item && item.checked) {
        for (let i = 0; i < this.menuItems.length; i++) {
          if (this.menuItems[i].entityId === item.entityId && this.menuItems[i].code === type) {
            this.$emit('removeMenuItem', this.menuItems[i])
          }
        }
      }
    },
    setMenuItems (item) {
      if (item) {
        this.menuItems.push(item)
      }
    }
  }
}
</script>

<style scoped>

</style>
