<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> General Details
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="4">
                        <TextBox v-model="item.name" placeholder="Name" required :addClass="{'is-invalid': $v.item.name.$error}">
                          Name:
                        </TextBox>
                        <TextBox v-model="item.shortName" placeholder="Short Name" required :addClass="{'is-invalid': $v.item.shortName.$error}">
                          Short Name:
                        </TextBox>
                      </b-col>
                      <b-col sm="4">
                        <SelectBox :items="statuses" v-model="selectedStatus">Status:</SelectBox>
                        <TextBox v-model="item.sortOrder" inputtype="number">Sort Order:</TextBox>
                      </b-col>
                      <b-col sm="4">
                        <label>Image</label>
                        <div style="margin-top: 0.5rem">
                          <ImageBanner ref="banner"/>
                        </div>
                        <div class="form-group">
                          <label>Main Language:</label>
                          <input type="checkbox" v-model="item.main" value="true"/>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
              <div class="add-edit-fields-action-bar">
                <div class="form-actions">
                  <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
                  <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/settings/language'">
                    Cancel
                  </router-link>
                </div>
              </div>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import ImageBanner from '../../components/ImageBanner'

export default {
  name: 'LanguageAddEditPage',
  data () {
    return {
      item: {
        name: '',
        shortName: ''
      },
      statuses: [
        { text: 'Active', value: 'ACTIVE' },
        { text: 'Inactive', value: 'INACTIVE' }
      ],
      selectedStatus: 'ACTIVE'
    }
  },
  created () {
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('languages/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.selectedStatus = this.item.status
        this.$refs.banner.setImages(this.item.images)
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return this.$route.params.id ? 'Edit Language' : 'Add Language'
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if (this.$v.item.$invalid) {
        this.$v.$touch()
        this.$toastr.error('Please, fill required fields')
        return
      }
      this.$loading.show()
      const _item = { ...this.item }
      _item.status = this.selectedStatus
      let axios
      if (_item.id) {
        axios = this.axios.put('languages', _item)
      } else {
        axios = this.axios.post('languages', _item)
      }
      axios.then(response => {
        if (response.status === 200) {
          this.$toastr.success(response.data)
          this.$router.push('/settings/language')
        } else {
          this.$toastr.error(response.data.message)
        }
        this.$loading.hide()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$toastr.error(error.response.data.errors.message)
        }
        this.$loading.hide()
      })
    },
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    }
  },
  validations: {
    item: {
      name: {
        required
      },
      shortName: {
        required
      }
    }
  },
  components: {
    ImageBanner
  }
}
</script>

<style scoped>

</style>
