<template>
  <div>
    <div class="d-flex flex-column flex-grow-1">
      <div class="animated fadeIn">
        <b-row>
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>General Details
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="6">
                          <TextBox v-model="item.siteName" required :addClass="{'is-invalid': $v.item.siteName.$error}">Company Name</TextBox>
                          <TextBox v-model="item.storeName" required :addClass="{'is-invalid': $v.item.storeName.$error}">Store Name</TextBox>
                        </b-col>
                        <b-col sm="6  ">
                          <div class="form-group">
                            <label>Business Information</label>
                            <textarea v-model="item.siteDesc" class="form-control" style="min-height: 115px;"></textarea>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>Contact details
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="3">
                          <TextBox v-model="item.phone">Phone</TextBox>
                          <TextBox v-model="item.email" placeholder="type..." type="email" style="padding-top: 9px;">
                            Email:
                          </TextBox>
                        </b-col>
                        <b-col sm="3">
                          <label>Youtube</label>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="fab fa-youtube"></i></span>
                            </div>
                            <input v-model="item.youtube" type="text" class="form-control" placeholder="Youtube">
                          </div>
                          <label>Facebook</label>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="fab fa-facebook"></i></span>
                            </div>
                            <input v-model="item.facebook" type="text" class="form-control" placeholder="Facebook">
                          </div>
                        </b-col>
                        <b-col sm="3">
                          <label>Instagram</label>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="fab fa-instagram"></i></span>
                            </div>
                            <input v-model="item.instagram" type="text" class="form-control" placeholder="Instagram">
                          </div>
                          <label>Twitter</label>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="fab fa-twitter"></i></span>
                            </div>
                            <input v-model="item.twitter" type="text" class="form-control" placeholder="Twitter">
                          </div>
                        </b-col>
                        <b-col sm="3">
                          <label>Telegram</label>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="fab fa-telegram"></i></span>
                            </div>
                            <input v-model="item.telegram" type="text" class="form-control" placeholder="Telegram">
                          </div>
                          <label>Tiktok</label>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="fab fa-tiktok"></i></span>
                            </div>
                            <input v-model="item.tiktok" type="text" class="form-control" placeholder="Tiktok">
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row style="padding-top: 20px">
          <b-col lg="6">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="far fa-address-book mr-2"></i>Billing Address
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="12">
                          <AddressPanel ref="billAddress"/>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
          <b-col lg="6">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="far fa-address-book mr-2"></i>Shipping Address
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="12">
                          <AddressPanel ref="shipAddress"/>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>System Settings
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="3">
                          <LookUp  url="currency/items" v-model="selectedCurrency">Base Currency</LookUp>
                          <SelectBox :items="thousandSeperatorList" v-model="selectedThousandSeperator">Thousands Separator
                          </SelectBox>
                        </b-col>
                        <b-col sm="3">
                          <SelectBox :items="decimalSeperatorList" v-model="selectedDecimalSeperator">Decimal Separator</SelectBox>
                          <TextBox inputtype="number" v-model="selected_digits_decimal">Digits after decimal</TextBox>
                        </b-col>
                        <b-col sm="3">
                          <SelectBox :items="weightUnitList" v-model="selectedWeightUnit">Weight Unit</SelectBox>
                          <SelectBox :items="dimensionUnitList" v-model="selectedDimensionUnit">Dimensions Unit</SelectBox>
                        </b-col>
                        <b-col sm="3">
                          <SelectBox :items="distanceUnitList" v-model="selectedDistanceUnit">Distance Unit</SelectBox>
                          <SelectBox :items="temperatureUnitList" v-model="selectedTemperatureUnit">Temperature Unit</SelectBox>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row >
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div class="add-edit-fields-action-bar">
                  <div class="form-actions">
                    <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
                    <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/company'">
                      Cancel
                    </router-link>
                  </div>
                </div>
              </b-card>
            </transition>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import AddressPanel from '../../components/AddressPanel'

export default {
  name: 'GeneralPage',
  data () {
    return {
      item: {
        siteName: null,
        phone: '',
        email: '',
        youtube: '',
        facebook: '',
        instagram: '',
        twitter: '',
        telegram: '',
        tiktok: '',
        siteDesc: '',
        storeName: ''
      },
      selected_digits_decimal: '2',
      selectedCurrency: null,
      thousandSeperatorList: [
        { text: 'Comma', value: 'COMMA' },
        { text: 'Space', value: 'SPACE' },
        { text: 'Point', value: 'POINT' }
      ],
      selectedThousandSeperator: 'SPACE',
      decimalSeperatorList: [
        { text: 'Comma', value: 'COMMA' },
        { text: 'Space', value: 'SPACE' },
        { text: 'Point', value: 'POINT' }
      ],
      selectedDecimalSeperator: 'POINT',
      weightUnitList: [
        { text: 'Pounds', value: 'POUNDS' },
        { text: 'Kilogramms', value: 'KILOGRAMMS' }
      ],
      selectedWeightUnit: 'KILOGRAMMS',
      dimensionUnitList: [
        { text: 'Centimeter (cm)', value: 'CANTIMETR' },
        { text: 'Meter (m)', value: 'METER' }
      ],
      selectedDimensionUnit: 'CANTIMETR',
      distanceUnitList: [
        { text: 'Miles', value: 'MILES' },
        { text: 'Kilometers', value: 'KILOMETRS' }
      ],
      selectedDistanceUnit: 'KILOMETRS',
      temperatureUnitList: [
        { text: 'Fahrenheit', value: 'FAHRENHEIT' },
        { text: 'Celsius', value: 'CELSIUS' }
      ],
      selectedTemperatureUnit: 'FAHRENHEIT'
    }
  },
  created () {
    // this.loadRelatedData()
    // if (!this.$route.params.id || this.$route.params.id <= 0) {
    //   return
    // }
    this.$loading.show()
    this.axios.get('company/' + 0)
      .then(response => {
        if (response.data) {
          this.item = response.data
          if (this.item.billAddress) {
            this.$refs.billAddress.setItem(this.item.billAddress)
          }
          if (this.item.shipAddress) {
            this.$refs.shipAddress.setItem(this.item.shipAddress)
          }
          if (this.item.currencyId) {
            this.selectedCurrency = { value: this.item.currencyId, label: this.item.currencyName }
          }
          if (this.item.thousand_seperator) {
            this.selectedThousandSeperator = this.item.thousand_seperator
          }

          if (this.item.decimal_seperator) {
            this.selectedDecimalSeperator = this.item.decimal_seperator
          }

          if (this.item.weight_unit) {
            this.selectedWeightUnit = this.item.weight_unit
          }

          if (this.item.distance_unit) {
            this.selectedDistanceUnit = this.item.distance_unit
          }

          if (this.item.dimension_unit) {
            this.selectedDimensionUnit = this.item.dimension_unit
          }

          if (this.item.temperature_unit) {
            this.selectedTemperatureUnit = this.item.temperature_unit
          }
          if (this.item.digits_decimal) {
            this.selected_digits_decimal = this.item.digits_decimal
          }
        }
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return 'Edit Company'
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if (this.$v.item.siteName.$invalid || this.$v.item.storeName.$invalid || this.$v.item.email.$invalid) {
        this.$v.$touch()
        this.$toastr.error('Please, fill required fields')
        return
      }
      this.$loading.show()
      const _item = { ...this.item }
      const billAddress = this.$refs.billAddress.getItem()
      if (billAddress) {
        _item.billAddress = billAddress
      }
      const shipAddress = this.$refs.shipAddress.getItem()
      if (shipAddress) {
        _item.shipAddress = shipAddress
      }
      if (this.selectedCurrency) {
        _item.currencyId = this.selectedCurrency.value
      }
      _item.digits_decimal = this.selected_digits_decimal
      _item.thousand_seperator = this.selectedThousandSeperator
      _item.decimal_seperator = this.selectedDecimalSeperator
      _item.weight_unit = this.selectedWeightUnit
      _item.distance_unit = this.selectedDistanceUnit
      _item.temperature_unit = this.selectedTemperatureUnit
      _item.dimension_unit = this.selectedDimensionUnit
      let axios
      if (_item.id) {
        axios = this.axios.put('company', _item)
      } else {
        axios = this.axios.post('company', _item)
      }
      axios.then(response => {
        if (response.status === 200) {
          this.$toastr.success('Saved successfully')
        } else {
          this.$toastr.error(response.data.message)
        }
        this.$loading.hide()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$toastr.error(error.response.data.errors.message)
        }
        this.$loading.hide()
      })
    },
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    }
  },
  validations: {
    item: {
      siteName: {
        required
      },
      storeName: {
        required
      },
      email: {
        email
      }
    }
  },
  components: {
    AddressPanel
  }
}
</script>

<style scoped>

</style>
