<template>
  <div>
    <div class="d-flex flex-column flex-grow-1">
      <div class="animated fadeIn">
        <b-row>
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>Account Information
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="4">
                          <TextBox v-model="item.number" placeholder="Number">
                            Number
                          </TextBox>
                          <TextBox v-model="item.firstName" required :addClass="{'is-invalid': $v.item.firstName.$error}">
                            First Name
                          </TextBox>
                          <TextBox v-model="item.lastName" required :addClass="{'is-invalid': $v.item.lastName.$error}">
                            Last Name
                          </TextBox>
                          <TextBox v-model="item.middleName">
                            Middle Name
                          </TextBox>
                          <DatePicker v-model="item.dateOfBirth">Date of Birth </DatePicker>
                        </b-col>
                        <b-col sm="4">
                          <TextBox v-model="item.username" required :addClass="{'is-invalid': $v.item.username.$error}">
                            Username
                          </TextBox>
                          <TextBox v-model="item.password" :inputtype="passwordType" required :addClass="{'is-invalid': $v.item.password.$error}">
                            Password
                          </TextBox>
                          <span class="icon is-small is-left"
                                style="position: absolute;top: 111px;right: 30px;">
                            <i :class="passwordButton" @click="passwordClick" style="cursor: pointer"></i>
                          </span>
                          <SelectBox :items="genders" v-model="selectedGender">Gender</SelectBox>
                          <TextBox v-model="item.phone">
                            Phone
                          </TextBox>
                          <TextBox required v-model="item.email" type="email" :addClass="{'is-invalid': $v.item.email.$error}">
                            Email:
                          </TextBox>
                        </b-col>
                        <b-col sm="4">
                          <SelectBox :items="groups" v-model="selectedGroup">Group</SelectBox>
                          <DatePicker v-model="item.registeredDate">Registered date</DatePicker>
                          <DatePicker v-model="item.lastLogged">Last Logged In</DatePicker>
                          <SelectBox :items="statuses" v-model="selectedStatus">Status</SelectBox>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row style="padding-top: 20px">
          <b-col lg="6">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="far fa-address-book mr-2"></i>Billing Address
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="12">
                          <AddressPanel ref="billAddress"/>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
          <b-col lg="6">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="far fa-address-book mr-2"></i>Shipping Address
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="12">
                          <AddressPanel ref="shipAddress"/>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fas fa-link mr-2"></i>Attachments
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="12">
                          <AttachmentComponent ref="uploadItem"/>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
                <div class="add-edit-fields-action-bar">
                  <div class="form-actions">
                    <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
                    <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/user/customer'">
                      Cancel
                    </router-link>
                  </div>
                </div>
              </b-card>
            </transition>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import AttachmentComponent from '../../components/ui/AttachmentComponent'
import AddressPanel from '../../components/AddressPanel'

export default {
  name: 'CustomerAddEditPage',
  data () {
    return {
      item: {
        number: '',
        firstName: '',
        lastName: '',
        middleName: '',
        username: '',
        password: '',
        dateOfBirth: null,
        createdDate: new Date(),
        phone: '',
        email: '',
        registeredDate: null,
        lastLogged: null
      },
      passwordType: 'password',
      passwordButton: 'far fa-eye',
      genders: [
        { text: 'Male', value: 'MALE' },
        { text: 'Female', value: 'FEMALE' }
      ],
      selectedGender: 'MALE',
      statuses: [
        { text: 'Active', value: 'ACTIVE' },
        { text: 'Inactive', value: 'INACTIVE' },
        { text: 'Blocked', value: 'BLOCKED' }
      ],
      selectedStatus: 'ACTIVE',
      groups: [],
      selectedGroup: null
    }
  },
  created () {
    this.loadRelatedData()
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('customers/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.selectedGender = this.item.gender
        this.selectedStatus = this.item.status
        this.selectedGroup = this.item.groupId
        this.$refs.uploadItem.setData(this.item.files)
        if (this.item.billAddress) {
          this.$refs.billAddress.setItem(this.item.billAddress)
        }
        if (this.item.shipAddress) {
          this.$refs.shipAddress.setItem(this.item.shipAddress)
        }
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return this.$route.params.id ? 'Edit Customer' : 'Add Customer'
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if (this.$v.item.$invalid) {
        this.$v.$touch()
        this.$toastr.error('Please, fill required fields')
        return
      }
      this.$loading.show()
      const _item = { ...this.item }
      _item.status = this.selectedStatus
      _item.gender = this.selectedGender
      _item.groupId = this.selectedGroup
      _item.files = this.$refs.uploadItem.fileList
      const billAddress = this.$refs.billAddress.getItem()
      if (billAddress) {
        _item.billAddress = billAddress
      }
      const shipAddress = this.$refs.shipAddress.getItem()
      if (shipAddress) {
        _item.shipAddress = shipAddress
      }
      let axios
      if (_item.id) {
        axios = this.axios.put('customers', _item)
      } else {
        axios = this.axios.post('customers', _item)
      }
      axios.then(response => {
        if (response.status === 200) {
          this.$toastr.success(response.data)
          this.$router.push('/user/customer')
        } else {
          this.$toastr.error(response.data.message)
        }
        this.$loading.hide()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$toastr.error(error.response.data.errors.message)
        }
        this.$loading.hide()
      })
    },
    async loadRelatedData () {
      this.$loading.show()
      const [perReq] = await Promise.all([
        this.axios.get('group/customer')
      ])
      if (perReq && perReq.data) {
        this.setSelectedItems(perReq.data, 'groups')
      }
      this.$loading.hide()
    },
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    },
    openModalManufacturer () {
      return this.$refs['manufacturer-modal'].show()
    },
    passwordClick () {
      if (this.passwordType === 'password') {
        this.passwordType = 'text'
        this.passwordButton = 'far fa-eye-slash'
      } else if (this.passwordType === 'text') {
        this.passwordType = 'password'
        this.passwordButton = 'far fa-eye'
      }
    }
  },
  validations: {
    item: {
      firstName: {
        required
      },
      lastName: {
        required
      },
      username: {
        required
      },
      password: {
        required
      },
      email: {
        required,
        email
      }
    }
  },
  components: {
    AttachmentComponent,
    AddressPanel
  }
}
</script>

<style scoped>

</style>
