<template>
  <div class="d-flex flex-column flex-grow-1">
    <ListingComponent class="bg-white"
                      ref="pageList"
                      :addNewButton="addNewButton"
                      :requestProvider="requestProvider"
                      :columnConfig="columnConfig">
    </ListingComponent>
    <ConfirmationModal ref="confirmationModal"
                       content="Do you really want to delete"
                       @cancel="closeDeleteModal"
                       @yes="removeItem">
    </ConfirmationModal>
  </div>
</template>

<script>
import ListingComponent from '../../components/listing/ListingComponent'
export default {
  name: 'FAQListPage',
  data () {
    return {
      columnConfig: [
        {
          columnCode: 'action',
          columnName: 'Action',
          value: item => {
            return (
              <b-dropdown variant="button" toggle-class="btn btn-sm btn-light " class="dropdown-options">
                <b-dropdown-item href={'#/content/faq/summary/' + item.id}>Summary</b-dropdown-item>
                <b-dropdown-item href={'#/content/faq/item/' + item.id}>Edit</b-dropdown-item>
                <b-dropdown-item onClick={() => this.showDeleteModal(item.id)}>Delete</b-dropdown-item>
              </b-dropdown>
            )
          }
        },
        {
          columnCode: 'group',
          columnName: 'Group',
          link: item => {
            return '/content/faq/summary/' + item.id
          },
          value: item => {
            return item.groupName
          }
        },
        {
          columnCode: 'question',
          columnName: 'Question',
          value: item => {
            return item.question
          }
        },
        {
          columnCode: 'answer',
          columnName: 'Answer',
          value: item => {
            return item.answer
          }
        }
      ]
    }
  },
  methods: {
    requestProvider (payload) {
      return this.axios.get('faq', payload)
    },
    addNewButton () {
      return (
        <router-link class="btn btn-success mr-3" to="/content/faq/item">
          <div class="fa fa-plus"></div>
        </router-link>
      )
    },
    showDeleteModal (id) {
      if (!id) return
      this.id = id
      this.$refs.confirmationModal.show()
    },
    closeDeleteModal () {
      this.id = null
      this.$nextTick(() => {
        this.$refs.confirmationModal.hide()
      })
    },
    removeItem () {
      if (!this.id) {
        return
      }
      this.axios.delete('faq/' + this.id)
        .then((response) => {
          this.$refs.pageList.doRequest()
          if (response.data.success) {
            this.$toastr.success('Successfully deleted')
          }
          this.$refs.confirmationModal.hide()
        })
    }
  },
  components: {
    ListingComponent
  }
}
</script>

<style scoped>

</style>
