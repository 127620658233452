<template>
  <tbody>
    <tr v-for="(row, index) in list" :key="index">
      <td v-for="(column, idx) in columnConfig" :key="idx">
        <table-cell :column="column" :item="row" :renderable="column.renderable"></table-cell>
      </td>
    </tr>
  </tbody>
</template>

<script>
import TableCell from './TableCell.vue'
export default {
  name: 'TableBody',
  components: { TableCell },
  props: {
    list: {
      type: Array,
      required: true
    },
    columnConfig: {
      type: Array,
      required: true
    },
    start: {
      type: Number,
      required: true
    },
    selected: {
      type: Array,
      required: true
    }
  }
}

</script>
