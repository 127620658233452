<template>
  <div class="d-flex flex-column flex-grow-1">
    <ListingComponent class="bg-white"
                      ref="paymentMethod"
                      :addNewButton="addNewButton"
                      :requestProvider="requestProvider"
                      :columnConfig="columnConfig">
    </ListingComponent>
  </div>
</template>

<script>
import ListingComponent from '../../components/listing/ListingComponent'
export default {
  name: 'PaymentMethodListPage',
  data () {
    return {
      columnConfig: [
        {
          columnCode: 'action',
          columnName: 'Action',
          value: item => {
            if (item.code !== 'PAYPAL') {
              return ''
            }
            return (
              <b-dropdown variant="button" toggle-class="btn btn-sm btn-light " class="dropdown-options">
                <b-dropdown-item href={'#/settings/payment-method/paypal/' + item.id}>Edit</b-dropdown-item>
              </b-dropdown>
            )
          }
        },
        {
          columnCode: 'paymentLogo',
          columnName: 'Logo',
          customizeColumn: true,
          value: item => {
            if (!item.logoUrl) {
              return <img alt='category-product' class='td-centered' src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAnBAMAAAB+jUwGAAAAElBMVEXd3d0AAADBwcGlpaWKiopubm7m/BpWAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQ0lEQVQokWNgGAX0AUpAiEyDAJOxs7EBEg0VdWFwYGYwANNwQRNjpQAGFygNBw5KDiBBMA0308hEAagdTNPEJyMcAAA93wilouyRBgAAAABJRU5ErkJggg=='}/>
            }
            return <img class='td-centered' src={item.logoUrl}/>
          }
        },
        {
          columnCode: 'name',
          columnName: 'Name',
          value: item => {
            return item.name
          },
          link: item => {
            if (item.code === 'PAYPAL') {
              return '/settings/payment-method/paypal/' + item.id
            } else {
              return ''
            }
          }
        },
        {
          columnCode: 'code',
          columnName: 'Code',
          value: item => {
            return item.code
          }
        },
        {
          columnCode: 'sortOrder',
          columnName: 'Sort Order',
          value: item => {
            return item.sortOrder
          }
        },
        {
          columnCode: 'status',
          columnName: 'Status',
          value: item => {
            if (item.status === 'ACTIVE') {
              return 'Active'
            } else if (item.status === 'INACTIVE') {
              return 'Inactive'
            }
            return item.status
          }
        }
      ]
    }
  },
  methods: {
    requestProvider (payload) {
      return this.axios.get('payment-method', payload)
    },
    addNewButton () {
      return null
    }
  },
  components: {
    ListingComponent
  }
}
</script>

<style scoped>

</style>
