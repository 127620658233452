<template>
  <a-row :gutter="24">
    <b-collapse id="collapse1" visible>
    </b-collapse>
    <a-col span="8">
      <LeftSide ref="leftMenu" @saveMenuItem="saveMenuItem" @removeMenuItem="removeMenuItem"/>
    </a-col>
    <a-col span="16">
      <RightSide ref="rightside" :treeData="item.treeData" @onRemove="onRemove" @onEdit="onEdit"/>
    </a-col>
    <div class="add-edit-fields-action-bar">
      <div class="form-actions">
        <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
        <router-link type="cancel" class="btn btn-secondary" variant="secondary"
                     :to="'/storefront/menus/menu-listing-page'">
          Cancel
        </router-link>
      </div>
    </div>
  </a-row>
</template>

<script>
import RightSide from './RightSide'
import LeftSide from './LeftSide'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'MenuCreator',
  components: {
    LeftSide,
    RightSide
  },
  validations: {
    item: {
      title: {
        required
      }
    }
  },
  data () {
    return {
      item: {
        treeData: [],
        title: '',
        identifier: ''
      }
    }
  },
  created () {
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('menu/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.$refs.rightside.setData(this.item)
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      // this.$loading.show()
      const _item = { ...this.item }
      _item.title = this.$refs.rightside.item.title
      _item.urlSlug = this.$refs.rightside.item.identifier
      let axios
      if (_item.id) {
        axios = this.axios.put('menu', _item)
      } else {
        axios = this.axios.post('menu', _item)
      }
      axios.then(response => {
        if (response.status === 200) {
          this.$toastr.success(response.data)
          this.$router.push('/storefront/menus/menu-listing-page')
        } else {
          this.$toastr.error(response.data.message)
        }
        this.$loading.hide()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$toastr.error(error.response.data.errors.message)
        }
        this.$loading.hide()
      })
    },
    saveMenuItem (data) {
      const menu = {
        title: data.menuName,
        url: data.url,
        code: data.code,
        id: data.id,
        entityId: data.entityId,
        key: String(Date.now() + Math.floor(Math.random() * 10000)),
        scopedSlots: { title: 'custom' }
      }
      this.item.treeData.push(menu)
      this.$refs.leftMenu.setMenuItems(menu)
    },
    removeMenuItem (data) {
      this.searchOption(data, this.item.treeData)
    },
    searchObj (obj, array, edit = false) {
      let result = {}
      for (let i = 0; i < array.length; i++) {
        if (array[i].key === obj.key) {
          result = array[i]
          if (edit) {
            array[i] = obj
          }
          return result
        }
        if (array[i].key === obj.key && array[i].children.length) {
          this.searchObj(obj, array[i].children)
        }
      }
    },
    searchOption (option, arr, type = 'delect') {
      console.log(option, arr)
      for (let s = 0; s < arr.length; s++) {
        console.log(arr[s].key, option.key)
        if (arr[s].key === option.key) {
          if (type === 'delect') {
            arr.splice(s, 1)
          } else {
            // This is simulated data edit data
            debugger
            this.$set(arr, s, {
              title: '12121212',
              key: '12121212',
              scopedSlots: { title: 'custom' }
            })
          }
          break
        } else if (arr[s].children && arr[s].children.length > 0) { // recursive condition
          this.searchOption(option, arr[s].children)
        } else {
          continue
        }
      }
    },
    onRemove (data) {
      this.searchOption(data, this.item.treeData)
    },
    onEdit (data) {
      console.log(this.item.treeData)
      this.searchOption(data, this.item.treeData)
    }
  }
}
</script>

<style scoped>

</style>
