<template>
  <div>
    <div class="d-flex flex-column flex-grow-1">
      <div class="animated fadeIn">
        <b-row>
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>General Details
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="4">
                          <b-form-group style="margin-top: 0.5rem">
                            <label>Name</label>
                            <div class="summary-field">{{ item.name }}</div>
                          </b-form-group>
                          <Textarea v-model="item.description" customStyle="height: 112px">Description</Textarea>
                          <TextBox v-model="item.email" type="email" required :addClass="{'is-invalid': $v.item.email.$error}">Email</TextBox>
                        </b-col>
                        <b-col sm="4">
                          <SelectBox :items="ipnNotifications" v-model="selectedNotification" >IPN Email Notifications</SelectBox>
                          <TextBox v-model="item.receiverEmail" type="email">Receiver Email</TextBox>
                          <SelectBox :items="paymentActions" v-model="selectedPaymentAction" >Payment Action</SelectBox>
                          <SelectBox :items="statuses" v-model="selectedStatus">Status</SelectBox>
                        </b-col>
                        <b-col sm="4">
                          <label>Logo</label>
                          <div style="margin-top: 0.5rem">
                            <ImageBanner ref="banner"/>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i> API Credentials
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="4">
                          <TextBox v-model="item.apiUserName" required :addClass="{'is-invalid': $v.item.apiUserName.$error}">Live API Username </TextBox>
                        </b-col>
                        <b-col sm="4">
                          <TextBox v-model="item.apiPassword" :inputtype="passwordType" required :addClass="{'is-invalid': $v.item.apiPassword.$error}">
                            Password
                          </TextBox>
                          <span class="icon is-small is-left"
                                style="position: absolute;top: 38px;right: 30px;">
                            <i :class="passwordButton" @click="passwordClick" style="cursor: pointer"></i>
                          </span>
                        </b-col>
                        <b-col sm="4">
                          <b-form-group>
                            <label>Signature</label>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row >
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div class="add-edit-fields-action-bar">
                  <div class="form-actions">
                    <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
                    <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/settings/payment-method'">
                      Cancel
                    </router-link>
                  </div>
                </div>
              </b-card>
            </transition>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import ImageBanner from '../../components/ImageBanner'
export default {
  name: 'PayPalEditPage',
  data () {
    return {
      item: {
        name: '',
        description: '',
        email: '',
        receiverEmail: '',
        apiUserName: '',
        apiPassword: ''
      },
      ipnNotifications: [
        { text: 'Enable', value: 'ENABLE' },
        { text: 'Disable', value: 'DISABLE' }
      ],
      selectedNotification: 'ENABLE',
      paymentActions: [
        { text: 'Select', value: '' }
      ],
      selectedPaymentAction: '',
      statuses: [
        { text: 'Active', value: 'ACTIVE' },
        { text: 'Inactive', value: 'INACTIVE' }
      ],
      selectedStatus: 'ACTIVE',
      passwordType: 'password',
      passwordButton: 'far fa-eye'
    }
  },
  created () {
    this.loadRelatedData()
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('payment-method/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.$refs.banner.setImages(this.item.logo)
        this.selectedNotification = this.item.emailNotification != null && this.item.emailNotification === false ? 'DISABLE' : 'ENABLE'
        if (this.item.status) {
          this.selectedStatus = this.item.status
        }

        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return this.$route.params.id ? 'Edit PayPal' : 'Add PayPal'
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if (this.$v.item.$invalid) {
        this.$v.$touch()
        this.$toastr.error('Please, fill required fields')
        return
      }
      this.$loading.show()
      const _item = { ...this.item }
      _item.status = this.selectedStatus
      _item.emailNotification = this.selectedNotification === 'ENABLE'
      const bannerItems = this.$refs.banner.getImages()
      if (bannerItems.length > 0) {
        _item.logo = bannerItems
      }
      let axios
      if (_item.id) {
        axios = this.axios.put('payment-method', _item)
        axios.then(response => {
          if (response.status === 200) {
            this.$toastr.success(response.data)
            this.$router.push('/settings/payment-method')
          } else {
            this.$toastr.error(response.data.message)
          }
          this.$loading.hide()
        }).catch(error => {
          if (error.response && error.response.status === 400) {
            this.$toastr.error(error.response.data.errors.message)
          }
          this.$loading.hide()
        })
      }
    },
    async loadRelatedData () {
      // this.$loading.show()
      // const [categoryReq] = await Promise.all([
      //   this.axios.get('categories/items')
      // ])
      // if (categoryReq && categoryReq.data) {
      //   this.setSelectedItems(categoryReq.data, 'categories')
      // }
      // this.$loading.hide()
    },
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    },
    passwordClick () {
      if (this.passwordType === 'password') {
        this.passwordType = 'text'
        this.passwordButton = 'far fa-eye-slash'
      } else if (this.passwordType === 'text') {
        this.passwordType = 'password'
        this.passwordButton = 'far fa-eye'
      }
    }
  },
  validations: {
    item: {
      email: {
        required,
        email
      },
      receiverEmail: {
        email
      },
      apiPassword: {
        required
      },
      apiUserName: {
        required
      }
    }
  },
  components: {
    ImageBanner
  }
}
</script>

<style scoped>

</style>
