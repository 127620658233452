<template>
  <div class="wrapper" v-if="$store.getters.isAuthenticated">
    <HeaderBar/>
    <div class="d-flex">
      <SideBar/>
      <MainContent/>
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/layout/SideBar'
import HeaderBar from '@/components/layout/HeaderBar'
import MainContent from '@/components/layout/MainContent'
export default {
  name: 'MainLayout',
  components: {
    SideBar,
    HeaderBar,
    MainContent
  }
}
</script>

<style scoped>

</style>
