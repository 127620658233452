import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import menuCreator from './menuCreator'
import menuListingPage from './menuListingPage'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    menuCreator,
    menuListingPage
  }
})
