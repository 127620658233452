<template>
  <div class="d-flex flex-column flex-grow-1">
    <ListingComponent class="bg-white"
                      ref="productList"
                      :addNewButton="addNewButton"
                      :requestProvider="requestProvider"
                      :columnConfig="columnConfig"
                      :showFilterButton="true"
                      :filterConfig="filterConfig">
    </ListingComponent>
    <ConfirmationModal ref="confirmationModal"
                       content="Do you really want to delete"
                       @cancel="closeDeleteModal"
                       @yes="removeItem">
    </ConfirmationModal>
  </div>
</template>

<script>
import ListingComponent from '../../components/listing/ListingComponent'
export default {
  name: 'ProductListPage',
  data () {
    return {
      columnConfig: [
        {
          columnCode: 'action',
          columnName: 'Action',
          value: item => {
            return (
              <b-dropdown variant="button" toggle-class="btn btn-sm btn-light " class="dropdown-options">
                <b-dropdown-item href={'#/product/summary/' + item.id}>Summary</b-dropdown-item>
                <b-dropdown-item href={'#/product/item/' + item.id}>Edit</b-dropdown-item>
                <b-dropdown-item onClick={() => this.showDeleteModal(item.id)}>Delete</b-dropdown-item>
              </b-dropdown>
            )
          }
        },
        {
          columnCode: 'image',
          columnName: 'Image',
          customizeColumn: true,
          value: item => {
            if (!item.imageUrl) {
              return <img alt='category-product' src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAnBAMAAAB+jUwGAAAAElBMVEXd3d0AAADBwcGlpaWKiopubm7m/BpWAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQ0lEQVQokWNgGAX0AUpAiEyDAJOxs7EBEg0VdWFwYGYwANNwQRNjpQAGFygNBw5KDiBBMA0308hEAagdTNPEJyMcAAA93wilouyRBgAAAABJRU5ErkJggg=='}/>
            }
            return <img src={item.imageUrl}/>
          }
        },
        {
          columnCode: 'name',
          columnName: 'Name',
          value: item => {
            return item.name
          },
          link: item => {
            return '/product/summary/' + item.id
          }
        },
        {
          columnCode: 'number',
          columnName: 'Number',
          value: item => {
            return item.number
          },
          link: item => {
            return '/product/summary/' + item.id
          }
        },
        {
          columnCode: 'type',
          columnName: 'Type',
          value: item => {
            if (item.type === 'INVENTORY') {
              return 'Inventory'
            } else if (item.type === 'NON_INVENTORY') {
              return 'Non-Inventory'
            } else if (item.type === 'VARIABLE') {
              return 'Variable'
            } else if (item.type === 'GROUPED') {
              return 'Grouped'
            } else if (item.type === 'SERVICE') {
              return 'Service'
            } else if (item.type === 'DIGITAL') {
              return 'Digital'
            }
            return item.type
          }
        },
        {
          columnCode: 'salesPrice',
          columnName: 'Sales Price',
          value: item => {
            return item.salesPrice
          }
        },
        {
          columnCode: 'purchasePrice',
          columnName: 'Purchase Price',
          value: item => {
            return item.purchasePrice
          }
        },
        {
          columnCode: 'status',
          columnName: 'Status',
          value: item => {
            return item.status && item.status === 'ACTIVE' ? 'Active' : 'Inactive'
          }
        }
      ],
      filterConfig: {
        title: 'Product Filter',
        type: this.$options.name,
        fields: [
          {
            code: 'categoryId',
            label: 'Category',
            type: 'LOOK_UP',
            lookupUri: 'categories/items'
          }
        ]
      }
    }
  },
  methods: {
    requestProvider (payload) {
      return this.axios.get('products', payload)
    },
    addNewButton () {
      return (
        <router-link class="btn btn-success mr-3" to="/product/item">
          <div class="fa fa-plus"></div>
        </router-link>
      )
    },
    showDeleteModal (id) {
      if (!id) return
      this.id = id
      this.$refs.confirmationModal.show()
    },
    closeDeleteModal () {
      this.id = null
      this.$nextTick(() => {
        this.$refs.confirmationModal.hide()
      })
    },
    removeItem () {
      if (!this.id) {
        return
      }
      this.axios.delete('products/' + this.id)
        .then((response) => {
          this.$refs.productList.doRequest()
          if (response.data.success) {
            this.$toastr.success('Successfully deleted')
          }
          this.$refs.confirmationModal.hide()
        })
    }
  },
  components: {
    ListingComponent
  }
}
</script>

<style scoped>

</style>
