import { render, staticRenderFns } from "./AttachmentComponent.vue?vue&type=template&id=974c1d44&scoped=true&"
import script from "./AttachmentComponent.vue?vue&type=script&lang=js&"
export * from "./AttachmentComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "974c1d44",
  null
  
)

export default component.exports