<template>
  <div>
    <div class="d-flex flex-column flex-grow-1">
      <div class="animated fadeIn">
        <b-row>
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>Click Information
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="6">
                          <TextBox v-model="item.clickSecretKey">Secret Key</TextBox>
                          <TextBox v-model="item.clickServiceId">Service ID</TextBox>
                        </b-col>
                        <b-col sm="6">
                          <TextBox v-model="item.clickMerchantId">Merchant ID</TextBox>
                          <TextBox v-model="item.clickMerchantUserId">Merchant User ID</TextBox>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                  <div class="add-edit-fields-action-bar">
                    <div class="form-actions">
                      <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
                    </div>
                  </div>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PaymentSettingsPage',
  data () {
    return {
      item: {
        clickSecretKey: null,
        clickServiceId: null,
        clickMerchantId: null,
        clickMerchantUserId: null
      }
    }
  },
  created () {
    this.$loading.show()
    this.axios.get('payment-settings')
      .then(response => {
        if (response.data) {
          this.item = response.data
        }
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return 'Payment Settings'
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      this.$loading.show()
      this.axios.post('payment-settings', this.item)
        .then(response => {
          if (response.status === 200) {
            this.$toastr.success(response.data)
          } else {
            this.$toastr.error(response.data.message)
          }
          this.$loading.hide()
        }).catch(error => {
          if (error.response && error.response.status === 400) {
            this.$toastr.error(error.response.data.errors.message)
          }
          this.$loading.hide()
        })
    }
  }
}
</script>

<style scoped>

</style>
