<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { AUTHORIZATION } from './constants'
export default {
  name: 'App',
  async created () {
    this.$loading.show(true)
    await this.$store.dispatch('fetch', localStorage.getItem(AUTHORIZATION), { root: true })
      .then(response => {
        if (response && response.status !== 200) {
          this.$toastr.error(response.data.errors)
          this.$router.push('/login').catch(e => {})
        }
        this.$loading.hide()
      })
  }
}
</script>

<style lang="scss">
  @import '../node_modules/bootstrap/dist/css/bootstrap.css';
  @import '../node_modules/bootstrap-vue/dist/bootstrap-vue.css';
  @import '../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css';
  @import '../node_modules/vue-select/dist/vue-select.css';
  @import 'assets/fontawesome-free/css/all.css';
  @import '../node_modules/vue-popperjs/dist/vue-popper.css';
  @import 'assets/slick/slick.css';
  @import 'assets/css/custom.css';
  @import 'assets/css/custom_color.scss';
</style>
