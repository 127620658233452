<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> General Details
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Name</label>
                          <div class="summary-field">{{ item.name }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Code</label>
                          <div class="summary-field">{{ item.code }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Field type</label>
                          <div class="summary-field">{{ item.widgetType === 'TEXT_BOX' ? 'Text Box' : item.widgetType === 'NUMBER_FIELD' ? 'Number Field' : item.widgetType === 'TEXT_AREA' ? 'Text Area' : item.widgetType === 'DATEPICKER' ? 'Datepicker' : item.widgetType === 'DROPDOWN' ? 'Dropdown' : item.widgetType === 'RADIO_BUTTON' ? 'Radio Button' : item.widgetType === 'CHECKBOX' ? 'Checkbox' : item.widgetType === 'IMAGE' ? 'Image' : item.widgetType === 'VIDEO' ? 'Video' : item.widgetType === 'LOOKUP' ? 'LookUp' : item.widgetType === 'MULTI_LOOKUP' ? 'MultiLookup' : '' }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Mandatory</label>
                          <div class="summary-field">{{ item.mandatory === true ? 'Yes' : 'No' }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Show in filter</label>
                          <div class="summary-field">{{ item.showInFilter === true ? 'Yes' : 'No' }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Show in listing</label>
                          <div class="summary-field">{{ item.showInListing === true ? 'Yes' : 'No' }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Apply To</label>
                          <div class="summary-field">{{ item.applyTo === 'SPECIFIC_PRODUCTS' ? 'Specific Products' : item.applyTo === 'SELECTED_TYPES' ? 'Selected Types' : '' }}</div>
                        </b-form-group>
                        <b-form-group v-if="item.applyTo === 'SPECIFIC_PRODUCTS'">
                          <label>Products</label>
                          <div class="summary-field">{{ item.productNames }}</div>
                        </b-form-group>
                        <b-form-group v-if="item.widgetType === 'DROPDOWN' || item.widgetType === 'RADIO_BUTTON' || item.widgetType === 'LOOKUP'">
                          <label>Use as Variation</label>
                          <div class="summary-field">{{ item.useVariation === true ? 'Yes' : 'No' }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="3">
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px" v-if="item.widgetType === 'DROPDOWN' || item.widgetType === 'CHECKBOX' || item.widgetType === 'RADIO_BUTTON'">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i>Predefined Values
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="6">
                        <b-row>
                          <b-col sm="12" style="padding-top: 10px">
                            <table class="table table-bordered table-sm">
                              <thead class="thead-light">
                              <tr>
                                <th width="10%" scope="col">ID</th>
                                <th width="50%" scope="col">Value</th>
                                <th width="30%" scope="col">Sort order</th>
                              </tr>
                              </thead>
                              <tbody v-for="(predefinedValue, index) in predefinedValues" :key="index">
                              <tr>
                                <td class="text-center" style="vertical-align: middle;">
                                  <span >{{ predefinedValue.id }}</span>
                                </td>
                                <td class="text-center" style="vertical-align: middle;">
                                  <span >{{ predefinedValue.name }}</span>
                                </td>
                                <td class="text-center" style="vertical-align: middle;">
                                  <span >{{ predefinedValue.sorder }}</span>
                                </td>
                              </tr>
                              </tbody>
                            </table>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> Frontend properties
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Show on Product listing</label>
                          <div class="summary-field">{{ item.showOnProductList === true ? 'Yes' : 'No' }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Sort by on Product listing</label>
                          <div class="summary-field">{{ item.sortByProductList === true ? 'Yes' : 'No' }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Visible on Product view</label>
                          <div class="summary-field">{{ item.visibleProductView === true ? 'Yes' : 'No' }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Use in Quick Search</label>
                          <div class="summary-field">{{ item.useQuickSearch === true ? 'Yes' : 'No' }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Use in Advanced Search</label>
                          <div class="summary-field">{{ item.useAdvancedSearch === true ? 'Yes' : 'No' }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Comparable</label>
                          <div class="summary-field">{{ item.comparable === true ? 'Yes' : 'No' }}</div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
              <div class="add-edit-fields-action-bar">
                <div class="form-actions">
                  <router-link :to="'/settings/custom-field/item/' + item.id">
                    <b-button type="button" variant="primary" class="mr-2">Edit</b-button>
                  </router-link>
                  <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/settings/custom-field'">
                    Cancel
                  </router-link>
                </div>
              </div>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AttributeViewPage',
  data () {
    return {
      item: {
        name: '',
        description: '',
        parentId: null
      }
    }
  },
  created () {
    // this.loadRelatedData()
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('custom-fields/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        if (this.item.predefinedValues) {
          this.predefinedValues = this.item.predefinedValues
          if (this.item.type === 'DROPDOWN') {
            this.selectBoxitems(this.predefinedValues, 'predefinedDropdownValues')
          }
        }
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return 'Attribute Summary'
    }
  },
  methods: {
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    }
  }
}
</script>

<style scoped>

</style>
