<template>
  <render v-if="column.columnCode === 'action' || renderable" :data="item" :render="column.value"/>
  <render v-else-if="column.customizeColumn" :data="item" :render="column.value"></render>
  <router-link v-else-if="column.link"
               :to="typeof column.link === 'string' ? (column.link + '/' + item.id) : column.link(item)">
    {{ column.value(item) }}
  </router-link>
  <span v-else>{{ column.value(item) }}</span>
</template>

<script>

export default {
  name: 'TableCell',
  props: {
    renderable: {
      type: Boolean,
      required: false,
      default: () => false
    },
    column: {
      type: Object,
      required: true
    },
    item: {
      type: null,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .is-clickable {
    cursor: pointer;
  }
</style>
