<template>
  <div>
    <div class="stylish-org-images">
      <div
        v-for="(item, index) in images"
        :key="index"
        class="stylish-org-images-child"
        :id="'stylish-org-images-child-' + index"
      >
        <div class="org-img-wrap">
          <div class="h-100 d-flex align-items-center" @click="openModal(index)">
            <div class="stylish-org-image-bg-layer">
            </div>
            <i class="fas fa-search-plus stylish-org-images-child-btn"></i>
            <img class="org-img" :src="item.url" alt="img">
          </div>
          <a class="image-banner-delete-item" href="#" @click="imageBannerDeleteItem(index)"><i class="fas fa-times"></i></a>
        </div>
      </div>
      <div v-if="!isSummary" class="stylish-org-images-add stylish-org-images-child">
        <div class="org-img-wrap">
          <div class="stylish-org-image-bg-layer">
          </div>
          <i class="fas fa-plus stylish-org-images-add-btn"></i>
          <img src="../../static/images/placeholder-image.jpg" alt="img">
          <input  type="file" class="custom-file-input"
                 accept="image/*" @change="onChoose">
          <!--<label class="custom-file-label border border-light" for="addViewChooseBannerStylish">-->
          <!--</label>-->
        </div>
      </div>
    </div>
    <div class="wg_slider">
      <slick v-show="hasImages" style="max-width: 100%;" class="mx-auto" ref="slick" :options="slickOptions">
        <div v-for="(item, index) in images" :key="index" class="mx-auto">
          <img style="display:block; width: 100%; height: auto;" :src="item.url" alt="img">
        </div>
      </slick>
      <div v-show="!hasImages" style="max-width: 500px;margin-bottom: 5px" class="mx-auto">
        <img src="../../static/images/placeholder-image.jpg" alt="img">
      </div>
      <div class="wg_slider-buttons">
        <div  class="custom-file custom-file-w-sm mr-2">
          <input v-if="!isSummary" id="addViewChooseBanner" type="file" class="custom-file-input"
                 accept="image/*" @change="onChoose">
          <label  v-if="!isSummary"  class="custom-file-label border border-light" for="addViewChooseBanner">
            <span  >Upload</span>
          </label>
        </div>
        <a class="btn btn-outline-light" @click="openModal">Large View</a>
      </div>
      <b-modal ref="slickModal" v-model="isModal" centered hide-footer
               :no-close-on-backdrop="true" @close="closeModal" size="xl">
        <div class="col-12">
          <button title="Delete" @click="imageBannerDeleteSlide" class="image-banner-delete-slide"><i class="fas fa-trash fa-2x"></i></button>
          <slick  @afterChange="handleAfterChange" @reInit="handleReInit" v-show="hasImages" style="max-width: 100%;" class="mx-auto"
                 ref="modalslick" :options="slickOptions">
          </slick>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import Slick from 'vue-slick'
export default {
  name: 'ImageBanner',
  props: {
    isSummary: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      images: [],
      slickOptions: {
        dots: true,
        speed: 400,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        arrow: true
      },
      currentSlide: null,
      isModal: false
    }
  },
  computed: {
    hasImages () {
      return this.images.length > 0
    }
  },

  methods: {
    getOptions () {
      return {
        ...this.slickOptions
      }
    },
    getImages () {
      return this.images
    },
    setImages (images) {
      if (!images || images.length <= 0) return
      this.images = images
      setTimeout(() => {
        this.$refs.slick.reSlick()
        if (this.$refs.modalslick) {
          if (this.$refs.modalslick.$children.length > 0) {
            this.$refs.modalslick.reSlick()
          }
        }
      }, 1500)
    },
    onChoose (event) {
      this.selectedFile = event.target.files[0]
      const formData = new FormData()
      formData.append('file', this.selectedFile)
      this.axios.post('image-banner/upload', formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      ).then((response) => {
        if (response.data) {
          const images = this.images.slice()
          const item = {
            id: response.data.id,
            url: response.data.url,
            key: response.data.key,
            name: response.data.name,
            contentType: response.data.contentType,
            description: response.data.description
          }
          images.push(item)
          this.images = images
          setTimeout(() => {
            this.$refs.slick.reSlick()
          }, 2000)
        }
      })
    },
    openModal (index) {
      if (this.images.length <= 0) return
      this.isModal = true
      this.$nextTick(() => {
        this.images.forEach(item => {
          this.$refs.modalslick.add('<div class="mx-auto"> ' +
            '<img style="display:block; width: 100%; height: 600px;" src=\'' + item.url + '\' alt="img">\n' +
            ' </div>')
        })
        this.$refs.modalslick.goTo(index, true)
      })
    },
    handleReInit (event, slick) {
      this.currentSlide = slick.currentSlide
    },
    handleAfterChange (event, slick, currentSlide) {
      this.currentSlide = currentSlide
    },
    imageBannerDeleteItem (index) {
      this.images.splice(index, 1)
    },
    imageBannerDeleteSlide () {
      this.$refs.modalslick.remove(this.currentSlide)
      this.imageBannerDeleteItem(this.currentSlide)
      this.$refs.slick.reSlick()
      if (this.images.length === 0) {
        this.$refs.slickModal.hide()
      }
    },

    closeModal () {
      if (this.$refs.modalslick) {
        this.$refs.modalslick.destroy()
      }
    }
  },

  beforeUpdate () {
    if (this.$refs.slick) {
      this.$refs.slick.destroy()
    }
  },
  updated () {
    this.$nextTick(() => {
      if (this.$refs.slick) {
        this.$refs.slick.create(this.getOptions())
      }
    })
  },
  beforeDestroy () {
    this.$refs.slick.destroy()
    if (this.$refs.modalslick) {
      this.$refs.modalslick.destroy()
    }
  },
  components: {
    Slick
  }
}
</script>
<style lang="scss">
  .slick-active{
    position: relative;
  }
  .image-banner-delete-slide{
    border: none;
    background: transparent;
    color: #e55959;
    position: absolute;
    right: 17px;
    top: 0;
    cursor: crosshair;
    z-index: 999;
  }
  #product_images .stylish-org-images > .stylish-org-images-child, #product_images .stylish-org-images > .stylish-org-images-add{
    flex: none;
  }
  .stylish-org-images .org-img-wrap img{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  .stylish-org-images-child{
    margin-right: 5px;
    margin-bottom: 10px;
  }
  .stylish-org-images{
    margin-left: 0px ;
    margin-right: -15px ;
    flex-wrap: wrap;

  }
  .slick-slide.slick-current.slick-active{
    display: flex;
    justify-content: center;
  }
  .slick-initialized .slick-slide, .slick-track{
    height: 100%;
  }
  .slick-list{
    height: 70vh !important;
  }
  .slick-slide img{
    max-width: 100%;
    width: auto !important;
    height: 100% !important;
  }
  .slick-dots{
    bottom: -50px;
      .slick-active{
        background: #474747;
        color: red;
        border-radius: 50%;
        border: 2px solid #a8a6a6;
        width: 15px;
        height: 15px;
        text-align: center;
        margin: 2px;
      }
    }
  .slick-prev, .slick-next{
        width: 40px;
        height: 40px;
        background: #474747;
        border-radius: 60%;
         &:focus{
           background: #474747;
         }
        &:before{
          color: #d0d0d0;
          font-size: 30px;
        }
        &:hover{
         width: 40px;
         height: 40px;
         background: #8e8d8d;
        }
      }
.stylish-org-images .stylish-org-image-bg-layer{
  width: auto !important;
  height: auto !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.stylish-org-images .stylish-org-images-child .stylish-org-images-child-btn{
  left: calc(50% - 16px);
  top: calc(50% - 16px);
  padding: 0;

}
  .slick-dots li{
    background: white;
    border-radius: 50%;
    border: 2px solid #a8a6a6;
    width: 15px;
    height: 15px;
    text-align: center;
    margin: 2px;
  }

  .slick-dots li button:before{
    display: none;
  }
  .stylish-org-images-child{
    .image-banner-delete-item{
      display: none;
    }
    &:hover{
      .image-banner-delete-item{
        display: block;
      }
    }
  }
  .image-banner-delete-item{
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 18px;
  z-index: 1;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 20px;
  background: red;
  color: white;
}
  .org-img-wrap{
    position: relative;
    }
</style>
