<template>
  <label>
    <span><slot/></span>
    <div style="margin-top: 0.5rem">
      <span v-if="required" class="required-bootstrap-field"></span>
      <b-form-select v-bind="$attrs"
                     v-on="$listeners"
                     :options="items"
                     :value="value"
                     :plain="true"
                     :class="addClass"
                     @input="$emit('input', $event)">
      </b-form-select>
    </div>
  </label>
</template>

<script>
export default {
  name: 'SelectBox',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    value: [String, Number],
    addClass: {
      type: Object
    },
    label: {
      type: String,
      default: () => ''
    },
    required: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style scoped>

</style>
