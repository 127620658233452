<template>
  <a-form-item :label="label">
    <span v-if="required" class="required-field"></span>
    <a-input
      :type="type"
      :addon-after="addonAfter"
      :addon-before="addonBefore"
      v-decorator="[name, { rules: [{ required, message: errorMessage }] }]"
      v-model="model"
    >
      <slot></slot>
    </a-input>
  </a-form-item>
</template>

<script>
export default {
  name: 'TextBoxFormItem',
  props: {
    model: {
      type: String,
      default: () => ''
    },
    label: {
      type: String,
      default: () => ''
    },
    name: {
      type: String,
      default: () => '',
      required: true
    },
    type: {
      type: String,
      default: () => 'text'
    },
    addonAfter: {
      type: String,
      default: () => ''
    },
    addonBefore: {
      type: String,
      default: () => ''
    },
    required: {
      type: Boolean,
      default: () => false
    },
    errorMessage: {
      type: String,
      default: () => ''
    }
  }
}
</script>

<style scoped>

</style>
