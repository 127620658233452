<template>
  <div class="d-flex flex-column flex-grow-1">
    <ListingComponent class="bg-white"
                      ref="comission"
                      :addNewButton="addNewButton"
                      :requestProvider="requestProvider"
                      :columnConfig="columnConfig">
    </ListingComponent>
  </div>
</template>

<script>
import ListingComponent from '../../components/listing/ListingComponent'
export default {
  name: 'ComissionListPage',
  data () {
    return {
      columnConfig: [
        {
          columnCode: 'action',
          columnName: 'Action',
          value: item => {
            return (
              <b-dropdown variant="button" toggle-class="btn btn-sm btn-light " class="dropdown-options">
                <b-dropdown-item href={'#/settings/comission/item/' + item.id}>Edit</b-dropdown-item>
              </b-dropdown>
            )
          }
        },
        {
          columnCode: 'owner',
          columnName: 'Owner',
          value: item => {
            return item.owner
          },
          link: item => {
            return '/settings/comission/item/' + item.id
          }
        },
        {
          columnCode: 'method',
          columnName: 'Method',
          value: item => {
            if (item.method === 'PERCENTAGE') {
              return 'Percentage'
            } else if (item.method === 'FIXED') {
              return 'Fixed'
            } else if (item.method === 'PERCENTAGE_FIXED') {
              return 'Percentage+Fixed'
            } else {
              return item.method
            }
          }
        },
        {
          columnCode: 'value',
          columnName: 'Value',
          value: item => {
            if (item.method === 'PERCENTAGE') {
              return item.percentage
            } else if (item.method === 'FIXED') {
              return item.fixed
            } else if (item.method === 'PERCENTAGE_FIXED') {
              return item.percentage + '/' + item.fixed
            } else {
              return ''
            }
          }
        },
        {
          columnCode: 'updatedBy',
          columnName: 'Updated by',
          value: item => {
            return item.lastModifiedByName
          }
        },
        {
          columnCode: 'updatedDate',
          columnName: 'Updated date',
          value: item => {
            return item.lastModifiedDate != null ? item.lastModifiedDate[0] + '/' + item.lastModifiedDate[1] + '/' + item.lastModifiedDate[2] : ''
          }
        }
      ]
    }
  },
  methods: {
    requestProvider (payload) {
      return this.axios.get('comission', payload)
    },
    addNewButton () {
      return null
    }
  },
  components: {
    ListingComponent
  }
}
</script>

<style scoped>

</style>
