<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> General Details
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="4">
                        <TextBox v-model="item.name" placeholder="Name" required :addClass="{'is-invalid': $v.item.name.$error}">Name</TextBox>
                        <SelectBox :items="types" v-model="item.type" required :addClass="{'is-invalid': $v.item.type.$error}">Type</SelectBox>
                      </b-col>
                      <b-col sm="4">
                        <SelectBox :items="themes" v-model="selectedTheme">Theme</SelectBox>
                        <label>Location</label>
                      </b-col>
                      <b-col sm="4">
                        <b-row>
                          <b-col sm="6">
                            <DatePicker v-model="item.fromDate">From Date</DatePicker>
                          </b-col>
                          <b-col sm="6">
                            <DatePicker v-model="item.toDate">To Date</DatePicker>
                          </b-col>
                        </b-row>
                        <SelectBox :items="statuses" v-model="selectedStatus">Status</SelectBox>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> Display Settings
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="4">
                        <TextBox v-model="item.title">Title</TextBox>
                        <TextBox v-model="item.bannerMessage">Banner Message</TextBox>
                        <label>Background Color </label>
                        <ColourPicker
                            v-model="item.backgroundColour"/>
                      </b-col>
                      <b-col sm="4">
                        <TextBox v-model="item.buttonText">Button text</TextBox>
                        <div>
                          <label>Button Destination URL</label>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <SelectBox :items="destinationUrls" v-model="item.destinationUrl"></SelectBox>
                            </div>
                            <div class="custom-file" style="align-items: end;">
                              <LookUp v-if="item.destinationUrl === 'CATEGORY'"  url="categories/items" v-model="selectedCategory"></LookUp>
                              <LookUp v-else-if="item.destinationUrl === 'PRODUCT'"   url="products/items" v-model="selectedProduct"></LookUp>
                              <TextBox v-else v-model="item.selectedDesctinationText"></TextBox>
                            </div>
                          </div>
                        </div>
                        <SelectBox :items="urlTargets" v-model="item.urlTarget">URL Target</SelectBox>
                      </b-col>
                      <b-col sm="4" id="banner_image" class="single-image">
                        <label>Image</label>
                        <div>
                          <ImageBanner ref="banner"/>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
              <div class="add-edit-fields-action-bar">
                <div class="form-actions">
                  <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
                  <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/storefront/banner'">
                    Cancel
                  </router-link>
                </div>
              </div>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import ImageBanner from '../../components/ImageBanner'
import ColourPicker from 'vue-native-color-picker'

export default {
  name: 'BannerAddEditPage',
  data () {
    return {
      item: {
        name: '',
        type: '',
        fromDate: null,
        toDate: null,
        title: '',
        bannerMessage: '',
        backgroundColour: '',
        buttonText: '',
        selectedDesctinationText: '',
        urlTarget: '',
        destinationUrl: ''
      },
      themes: [
        { text: 'Select', value: '' }
      ],
      urlTargets: [
        { text: 'Select', value: '' },
        { text: 'Open in current Window', value: 'OPEN_CURRENT_WINDOW' },
        { text: 'Open in new Tab', value: 'OPEN_NEW_TAB' }
      ],
      destinationUrls: [
        { text: 'Select', value: '' },
        { text: 'Category', value: 'CATEGORY' },
        { text: 'Product', value: 'PRODUCT' },
        { text: 'Page', value: 'PAGE' },
        { text: 'Custom', value: 'CUSTOM' }
      ],
      selectedTheme: '',
      types: [
        { text: 'Select', value: '' },
        { text: 'Announcement', value: 'ANNOUNCEMENT' },
        { text: 'Free Shipping', value: 'FREE_SHIPPING' },
        { text: 'Discount', value: 'DISCOUNT' }
      ],
      statuses: [
        { text: 'Active', value: 'ACTIVE' },
        { text: 'Scheduled', value: 'SCHEDULED' },
        { text: 'Inactive', value: 'INACTIVE' },
        { text: 'Expired', value: 'EXPIRED' }
      ],
      selectedStatus: 'ACTIVE',
      selectedCategory: '',
      selectedProduct: ''
    }
  },
  created () {
    // this.loadRelatedData()
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('banner/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.selectedStatus = this.item.status

        if (this.item.destinationUrl === 'CATEGORY' && this.item.selectedDesctinationId) {
          this.selectedCategory = {
            label: this.item.selectedDesctinationText,
            value: this.item.selectedDesctinationId
          }
        } else if (this.item.destinationUrl === 'PRODUCT' && this.item.selectedDesctinationId) {
          this.selectedProduct = {
            label: this.item.selectedDesctinationText,
            value: this.item.selectedDesctinationId
          }
        }
        this.$refs.banner.setImages(this.item.images)
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return this.$route.params.id ? 'Edit Banner' : 'Add Banner'
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if (this.$v.item.$invalid) {
        this.$v.$touch()
        this.$toastr.error('Please, fill required fields')
        return
      }
      this.$loading.show()
      const _item = { ...this.item }
      _item.status = this.selectedStatus
      if (this.item.destinationUrl === 'CATEGORY' && this.selectedCategory) {
        _item.selectedDesctinationId = this.selectedCategory.value
        _item.selectedDesctinationText = this.selectedCategory.label
      } else if (this.item.destinationUrl === 'PRODUCT' && this.selectedProduct) {
        _item.selectedDesctinationId = this.selectedProduct.value
        _item.selectedDesctinationText = this.selectedProduct.label
      } else {
        _item.selectedDesctinationId = null
      }
      const bannerItems = this.$refs.banner.getImages()
      if (bannerItems.length > 0) {
        _item.images = bannerItems
      }
      let axios
      if (_item.id) {
        axios = this.axios.put('banner', _item)
      } else {
        axios = this.axios.post('banner', _item)
      }
      axios.then(response => {
        if (response.status === 200) {
          this.$toastr.success(response.data)
          this.$router.push('/storefront/banner')
        } else {
          this.$toastr.error(response.data.message)
        }
        this.$loading.hide()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$toastr.error(error.response.data.errors.message)
        }
        this.$loading.hide()
      })
    },
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    }
  },
  validations: {
    item: {
      name: {
        required
      },
      type: {
        required
      }
    }
  },
  components: {
    ImageBanner,
    ColourPicker
  }
}
</script>

<style scoped>

</style>
