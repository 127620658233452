<template>
    <b-modal v-model="showModal" size="sm" centered title="Confirmation">
        <p class="text-center">{{content}}</p>
      <template v-slot:modal-footer>
        <div class="d-flex justify-content-center w-100">
          <b-button variant="primary" @click="$emit('yes', data)" class="mx-3">Yes</b-button>
          <b-button variant="secondary" class="mx-3" @click="$emit('cancel', data)">No</b-button>
        </div>
      </template>
    </b-modal>
</template>

<script>
export default {
  name: 'ConfirmationModal',
  props: {
    content: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showModal: false,
      data: null
    }
  },
  methods: {
    show (data) {
      this.data = data
      this.showModal = true
    },
    hide () {
      this.data = null
      this.showModal = false
    }
  }
}
</script>

<style scoped>

</style>
