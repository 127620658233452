const state = {
  menuItem: {
    menuName: '',
    url: ''
  }
}

const actions = {
  setMenuItemName ({ commit }, data) {
    commit('setMenuItem', data)
  }
}

// const muta

export default {
  state,
  // mutations,
  actions
  // getters
}
