<template>
  <div class="d-flex flex-column flex-grow-1">
    <ListingComponent class="bg-white"
                      ref="categoryList"
                      :addNewButton="addNewButton"
                      :requestProvider="requestProvider"
                      :columnConfig="columnConfig">
    </ListingComponent>
    <ConfirmationModal ref="confirmationModal"
                       content="Do you really want to delete"
                       @cancel="closeDeleteModal"
                       @yes="removeItem">
    </ConfirmationModal>
  </div>
</template>

<script>
import ListingComponent from '../../components/listing/ListingComponent'
export default {
  name: 'AttributeListPage',
  data () {
    return {
      columnConfig: [
        {
          columnCode: 'action',
          columnName: 'Action',
          value: item => {
            return (
              <b-dropdown variant="button" toggle-class="btn btn-sm btn-light " class="dropdown-options">
                <b-dropdown-item href={'#/settings/custom-field/summary/' + item.id}>Summary</b-dropdown-item>
                <b-dropdown-item href={'#/settings/custom-field/item/' + item.id}>Edit</b-dropdown-item>
                <b-dropdown-item onClick={() => this.showDeleteModal(item.id)}>Delete</b-dropdown-item>
              </b-dropdown>
            )
          }
        },
        {
          columnCode: 'name',
          columnName: 'Name',
          link: item => {
            return '/settings/custom-field/summary/' + item.id
          },
          value: item => {
            return item.name
          }
        },
        {
          columnCode: 'fieldType',
          columnName: 'Field type',
          value: item => {
            return item.widgetType === 'TEXT_BOX' ? 'Text Box' : item.widgetType === 'NUMBER_FIELD' ? 'Number Field' : item.widgetType === 'TEXT_AREA' ? 'Text Area' : item.widgetType === 'DATEPICKER' ? 'Datepicker' : item.widgetType === 'DROPDOWN' ? 'Dropdown' : item.widgetType === 'RADIO_BUTTON' ? 'Radio Button' : item.widgetType === 'MULTIPLE_SELECT' ? 'Multiple Select' : item.widgetType === 'IMAGE' ? 'Image' : item.widgetType === 'VIDEO' ? 'Video' : ''
          }
        },
        {
          columnCode: 'mandatory',
          columnName: 'Mandatory field',
          value: item => {
            return item.mandatory === true ? 'Yes' : 'No'
          }
        },
        {
          columnCode: 'applyTo',
          columnName: 'Apply To',
          value: item => {
            return item.applyTo === 'ALL_PRODUCTS' ? 'All Products' : item.applyTo === 'SELECTED_TYPES' ? 'Selected Types' : ''
          }
        },
        {
          columnCode: 'showInListing',
          columnName: 'Show in listing',
          value: item => {
            return item.showinListing === true ? 'Yes' : 'No'
          }
        },
        {
          columnCode: 'showInFilter',
          columnName: 'Show in filter',
          value: item => {
            return item.showInFilter === true ? 'Yes' : 'No'
          }
        }
      ]
    }
  },
  methods: {
    requestProvider (payload) {
      return this.axios.get('custom-fields', payload)
    },
    addNewButton () {
      return (
        <router-link class="btn btn-success mr-3" to="/settings/custom-field/item">
          <div class="fa fa-plus"></div>
        </router-link>
      )
    },
    showDeleteModal (id) {
      if (!id) return
      this.id = id
      this.$refs.confirmationModal.show()
    },
    closeDeleteModal () {
      this.id = null
      this.$nextTick(() => {
        this.$refs.confirmationModal.hide()
      })
    },
    removeItem () {
      if (!this.id) {
        return
      }
      this.axios.delete('custom-fields/' + this.id)
        .then((response) => {
          this.$refs.categoryList.doRequest()
          if (response.data.success) {
            this.$toastr.success('Successfully deleted')
          }
          this.$refs.confirmationModal.hide()
        })
    }
  },
  components: {
    ListingComponent
  }
}
</script>

<style scoped>

</style>
