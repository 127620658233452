<template>
  <b-collapse id="collapse-filter" v-model="showFilterPanel">
    <form ref="filterForm" @submit.stop.prevent="$emit('filterSubmit')">
      <b-card-body>
        <div class="add-edit-fields-wrap">
      <b-row>
        <!--<div class="col-3" v-if="filterList.length > 0">-->
          <!--<b-form-group class="position-relative">-->
            <!--<template>-->
              <!--<label class="text-black-50">Saved Filter</label>-->
              <!--<div class="styled-select after-arrow-down">-->
                <!--<b-form-select-->
                  <!--v-model="filterId"-->
                  <!--:options="filterList"-->
                  <!--@change="onChange"-->
                  <!--:plain="true"-->
                <!--&gt;-->
                <!--</b-form-select>-->
              <!--</div>-->
            <!--</template>-->
          <!--</b-form-group>-->
        <!--</div>-->
        <div style="width: 100%;" v-for="(item, index) in filterItems" :key="index">
        <b-col sm="3">
          <template v-if="haslookUp(item.type)">
            <LookUp v-model="filterData[item.code]" :url="item.lookupUri">{{item.label}}</LookUp>
          </template>
          <template v-if="hasFormInput(item.type)">
            <b-form-input v-model="filterData[item.code]">
            </b-form-input>
          </template>
          <template v-else-if="hasDatePicker(item.type)">
            <date-picker name="datepicker-sample" v-model="filterData[item.code]"
                         format="yyyy-MM-dd"
                         placeholder="yyyy-MM-dd"
                         :typeable="true"
                         :clearButton="true"
                         :bootstrapStyling="true"
                         :calendarButton="true"
                         calendarButtonIcon="icon-calendar">
            </date-picker>
          </template>
          <template v-else-if="hasDatePickerFromTo(item.type)">
            <div class="row">
              <div class="col-6">
                <DatePicker v-model="filterData[item.code]"
                            format="yyyy-MM-dd"
                            placeholder="yyyy-MM-dd"
                            :typeable="true"
                            :calendarButton="true"
                            :labelPicker="false"
                            calendarButtonIcon="icon-calendar"
                            id="fromDate"
                            @input="selectDate(item)">
                </DatePicker>
              </div>
              <div class="col-6">
                <DatePicker v-model="filterData[item.toCode]"
                            format="yyyy-MM-dd"
                            placeholder="yyyy-MM-dd"
                            :typeable="true"
                            :clearButton="true"
                            :bootstrapStyling="true"
                            :calendarButton="true"
                            :labelPicker="false"
                            calendarButtonIcon="icon-calendar"
                            id="toDate">
                </DatePicker>
              </div>
            </div>
          </template>
          <template v-else-if="hasDropDown(item.type)">
            <div class="position-relative">
              <div class="styled-select after-arrow-down">
                <b-form-select v-model="filterData[item.code]" :options="options[item.code]"></b-form-select>
              </div>
            </div>
          </template>
          <template v-else-if="hasCustomDropDown(item.type)">
            <SelectBox v-model="filterData[item.code]" :items="item.itemList"></SelectBox>
          </template>
        </b-col>
        </div>
      </b-row>
        </div>
      </b-card-body>
      <b-button type="submit" variant="success" class="mr-2">Apply</b-button>
      <!--<b-button type="button" variant="success" class="mr-2" v-b-modal.modal-prevent-closing @click="saveFilter">Save-->
      <!--</b-button>-->
      <b-button variant="secondary" class="mr-2" @click="resetFilter">Reset</b-button>
      <b-button variant="outline-primary" class="mr-2" @click="$emit('cancelButton')">Cancel</b-button>

    </form>
    <!--<SaveFilter ref="saveFilterModal"-->
                <!--:filterId="filterId"-->
                <!--:params="params"-->
                <!--@onSaveFilter="onSaveFilter">-->
    <!--</SaveFilter>-->
  </b-collapse>
</template>

<script>
// import SaveFilter from './SaveFilter'

export default {
  name: 'FilterComponent',
  props: {
    showFilterPanel: {
      type: Boolean,
      default: () => false
    },
    filterConfig: {
      type: Object,
      default: () => {
      }
    }
  },
  data () {
    return {
      options: {},
      filterData: {},
      params: null,
      filterId: null,
      filterList: []
    }
  },
  created () {},
  computed: {
    filterItems () {
      if (!this.filterConfig || !this.filterConfig.fields) return
      return this.filterConfig.fields.map(field => {
        return {
          code: field.code,
          toCode: field.toCode,
          type: field.type,
          label: field.label,
          value: field.value,
          itemList: field.itemList,
          lookupUri: field.lookupUri
        }
      })
    }
  },
  methods: {
    hasFormInput (type) {
      return type === 'FORM_INPUT'
    },
    hasDatePicker (type) {
      return type === 'DATE_PICKER'
    },
    hasDatePickerFromTo (type) {
      return type === 'DATE_PICKER_FROM_TO'
    },
    hasDropDown (type) {
      return type === 'DROP_DOWN'
    },
    hasCustomDropDown (type) {
      return type === 'CUSTOM_DROP_DOWN'
    },
    haslookUp (type) {
      return type === 'LOOK_UP'
    },
    selectDate (item) {
      const value = this.filterData[item.code]
      this.filterData[item.toCode] = value
    },
    onChange () {
      if (this.filterId < 1) {
        this.filterData = {}
        return
      }
      this.$loading.show()
      this.axios.get('filter/' + this.filterId)
        .then((response) => {
          if (response.data && response.data.data && response.data.data.selectItems) {
            const arr = response.data.data.selectItems
            const selected = {}
            for (const a in arr) {
              this.filterData[arr[a].description] = {
                label: arr[a].name,
                value: arr[a].id + ''
              }
              selected[arr[a].description] = this.filterData[arr[a].description]
              this.filterData = selected
            }
            this.submitForm()
          }
        })
      this.$loading.hide()
    },
    saveFilter () {
      this.params = {
        type: this.filterConfig.type
      }
      if (this.filterData) {
        for (const field of this.filterConfig.fields) {
          if (this.filterData[field.code]) {
            this.params[field.code] = this.filterData[field.code].value
          }
          if (this.filterData[field.toCode]) {
            this.params[field.toCode] = this.filterData[field.toCode].value
          }
        }
      }
      this.$refs.saveFilterModal.show()
    },
    submitForm () {
      this.$parent.filterSubmit()
    },
    onSaveFilter (option) {
      const list = this.filterList.filter(e => e.value !== option.value)
      list.push(option)
      this.filterList = list
      console.log(this.filterList)
    },
    resetFilter () {
      this.filterId = null
      this.filterData = {}
    }
  },
  mounted () {
    if (this.filterConfig) {
      this.axios.get('filter/list', {
        params: {
          type: this.filterConfig.type
        }
      }).then(response => {
        this.item = response.data.data
        if (this.item) {
          const list = [{ value: -1, text: 'Select' }]
          this.item.forEach(function (item) {
            if (item.id) {
              list.push({
                value: item.id,
                text: item.name
              })
            } else {
              list.push({ value: null, text: 'N/A' })
            }
          })
          this.filterList = list
        }
      })
    }
  }
  // components: {
  //   SaveFilter
  // }
}
</script>

<style scoped>
#collapse-filter {
  margin-bottom: 1rem;
}
</style>
