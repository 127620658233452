<template>
  <label>
    <span><slot/></span>
    <b-form-select v-bind="$attrs"
                   :options="items"
                   :value="value"
                   :plain="true"
                   :class="addClass"
                   multiple :select-size="5"
                   @input="$emit('input', $event)">
    </b-form-select>
  </label>
</template>

<script>
export default {
  name: 'MultiSelectBox',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    value: [String, Number, Array],
    addClass: {
      type: Object
    }
  }
}
</script>

<style scoped>

</style>
