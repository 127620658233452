<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> General Details
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="4">
                        <SelectBox :items="groups" v-model="item.group" required :addClass="{'is-invalid': $v.item.group.$error}">Group</SelectBox>
                      </b-col>
                      <b-col sm="4">
                        <TextBox v-model="item.question" required :addClass="{'is-invalid': $v.item.question.$error}">Question</TextBox>
                      </b-col>
                      <b-col sm="4">
                        <Textarea v-model="item.answer" required :addClass="{'is-invalid': $v.item.answer.$error}">Answer</Textarea>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
              <div class="add-edit-fields-action-bar">
                <div class="form-actions">
                  <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
                  <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/content/faq'">
                    Cancel
                  </router-link>
                </div>
              </div>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
export default {
  name: 'FaqAddEditPage',
  data () {
    return {
      item: {
        question: '',
        answer: '',
        group: null
      },
      groups: []
    }
  },
  created () {
    this.loadRelatedData()
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('faq/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        if (this.item.groupId) {
          this.item.group = this.item.groupId
        }

        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return this.$route.params.id ? 'Edit page' : 'Add page'
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if (this.$v.item.$invalid) {
        this.$v.$touch()
        this.$toastr.error('Please, fill required fields')
        return
      }
      this.$loading.show()
      const _item = { ...this.item }
      if (this.item.group) {
        _item.groupId = this.item.group
      }
      let axios
      if (_item.id) {
        axios = this.axios.put('faq', _item)
      } else {
        axios = this.axios.post('faq', _item)
      }
      axios.then(response => {
        if (response.status === 200) {
          this.$toastr.success(response.data)
          this.$router.push('/content/faq')
        } else {
          this.$toastr.error(response.data.message)
        }
        this.$loading.hide()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$toastr.error(error.response.data.errors.message)
        }
        this.$loading.hide()
      })
    },
    async loadRelatedData () {
      this.$loading.show()
      const [perReq] = await Promise.all([
        this.axios.get('group/faq')
      ])
      if (perReq && perReq.data) {
        this.setSelectedItems(perReq.data, 'groups')
      }
      this.$loading.hide()
    },
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    }
  },
  validations: {
    item: {
      question: {
        required
      },
      answer: {
        required
      },
      group: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>
