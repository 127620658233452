<template>
  <div>
    <table class="table table-bordered table-sm">
      <div>
        <b-modal v-model="openImageModal"
                 hide-footer
                 :no-close-on-backdrop="true"
                 @hidden="resetModal"
                 size="lg">
          <div class="col-12 h-300px overflow-auto">
            <a :href="imageUrl">
              <img class="preview" :src="imageUrl">
            </a>
          </div>
        </b-modal>
        <b-modal v-model="openUploadModal"
                 hide-footer
                 :no-close-on-backdrop="true"
                 centered title="File upload"
                 @hidden="resetModal"
                 size="lg">
          <div class="row">
            <div class="col-8 h-200px overflow-auto">
              <div v-for="file in files" :key="file.id" class="files__item d-flex">
                <div class="flex-grow-1 mr-2">
                  <div class="files__item-name">{{ file.name }}</div>
                  <BProgress striped :max="100">
                    <BProgressBar :value="calculatePercentage(file)" :label="`${((uploadPercentage / 100) * 100).toFixed(2)}%`"></BProgressBar>
                  </BProgress>
                </div>
                <b-button variant="danger" size="sm" @click="removeFromList(file)"><i class="fa fa-window-close"></i></b-button>
              </div>
            </div>
            <div class="col-4">
              <div class="form-group files">
                <input type="file" id="files" class="form-control" ref="files" multiple v-on:change="onChoose"/>
                <div class="files-text">
                  <h6><strong>Drag & drop</strong></h6>
                  <div>Or <a href="#">browse</a> your files</div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right">
            <b-button variant="primary" size="sm" @click="submitFiles">Save</b-button>
          </div>
        </b-modal>
      </div>
      <thead class="thead-light">
      <tr>
        <th width="40%" scope="col">Name</th>
        <th width="55%" scope="col">Description</th>
        <th v-if="!isSummary" width="10%" scope="col" class="text-center">Action</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="file in fileList" :key="file.id">
        <td>
          <template v-if="checkFileType(file)">
            <div>
              <label>{{ ''}}</label>
            </div>
            <div>
              <span class="text-primary" style="cursor: pointer;" @click="openImage(file)">{{ file.name }}</span>
            </div>
          </template>
          <template v-else>
            <div>
              <label>{{ ''}}</label>
            </div>
            <div>
              <a :href="file.url" target="_blank">{{ file.name }}</a>
            </div>
          </template>
        </td>
        <td>
          <textarea v-model="file.description" class="form-control" style="margin-top: 7px;" v-if="!isSummary"></textarea>
          <label v-else class="form-control" style="margin-top: 18px;">{{file.description}} </label>
        </td>
        <td v-if="!isSummary">
          <div class="text-center" style="margin-top: 20px;">
            <b-button variant="danger" size="sm" @click="removeFile(file)"><i class="fa fa-window-close"></i>
            </b-button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <div v-if="!isSummary" class="text-right">
      <b-button variant="primary" size="sm" @click="openModal"><span><i class="fas fa-cloud-upload-alt mr-2" aria-hidden="true"></i>Upload</span></b-button>
    </div>
  </div>
</template>

<script>
import { FILE_TYPE_IMAGE } from '../../constants/index'
export default {
  name: 'AttachmentComponent',
  props: {
    isSummary: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      files: [],
      filess: [],
      uploadPercentage: 0,
      openImageModal: false,
      imageUrl: '',
      openUploadModal: false,
      fileList: [],
      types: [],
      creator: null,
      createdDate: new Date(),
      first: true
    }
  },
  methods: {
    onChoose () {
      if (this.first) {
        this.files = this.$refs.files.files
        this.first = false
      } else {
        this.filess = [...this.$refs.files.files, ...this.files]
        this.files = this.filess
      }
    },
    calculatePercentage (file) {
      var interval = file.size / 1000
      setInterval(() => {
        this.uploadPercentage = Math.min(this.uploadPercentage + 5, 100)
      }, interval)
      return this.uploadPercentage
    },
    resetModal () {
      this.files = []
    },
    submitFiles () {
      // this.$loading.show()
      const formData = new FormData()
      for (var i = 0; i < this.files.length; i++) {
        const file = this.files[i]
        formData.append('uploadFile', file)
      }

      this.axios.post('file',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
          }.bind(this)
        }
      ).then((response) => {
        if (response.data) {
          const list = this.fileList
          const item = {
            id: response.data.id,
            url: response.data.url,
            key: response.data.key,
            name: response.data.name,
            contentType: response.data.contentType,
            description: response.data.description
          }
          list.push(item)
          this.fileList = list
          this.openUploadModal = false
          this.files = []
          this.filess = []
          // this.$loading.hide()
        }
      })
        .catch(e => {
          this.files = []
          // this.$loading.hide()
          this.$toastr.error('500 - Internal Server Error')
        })
    },
    getItems () {
      return this.fileList
    },
    setData (items) {
      if (!items) return
      const list = []
      items.forEach(function (item) {
        list.push({
          id: item.id,
          name: item.name,
          description: item.description,
          type: item.type,
          createdDate: new Date(item.createdDate),
          creator: item.creator,
          url: item.url,
          contentType: item.contentType
        })
      })
      this.fileList = list
    },
    checkFileType (file) {
      return FILE_TYPE_IMAGE === file.contentType
    },
    openImage (file) {
      this.imageUrl = file.url
      this.openImageModal = true
    },
    openModal () {
      this.openUploadModal = true
    },
    removeFile (file) {
      this.axios.delete('/upload/file/', { params: { id: file.id } })
        .then((result) => {
          if (result && result.data && result.data.status === 200) {
            const itemIndex = this.fileList.indexOf(file)
            if (itemIndex !== -1) {
              this.fileList.splice(itemIndex, 1)
            }
          }
        })
    },
    removeFromList (file) {
      const arr = []
      for (const f of this.files) {
        if (f !== file) {
          arr.push(f)
        }
      }
      this.files = arr
    },
    validate () {
      return this.$v.fileList.$invalid
    },
    touch () {
      this.$v.$touch()
    }
  }
}
</script>

<style scoped>

</style>
