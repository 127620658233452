<template>
  <div>
    <template v-if="isSummary">
      <div class="row" style="min-height: 224px;">
        <div class="col-12">
          <b-form-group>
            <label>Address</label>
            <div>{{ item.address }}</div>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group>
            <label>City</label>
            <div>{{ item.cityName }}</div>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group>
            <label>State/Region </label>
            <div>{{ item.regionName }}</div>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group>
            <label>Zip Code</label>
            <div>{{ item.zipCode }}</div>
          </b-form-group>
        </div>
        <div class="col-6">
          <b-form-group>
            <label>Country</label>
            <div>{{ item.countryName }}</div>
          </b-form-group>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-12">
          <TextBox v-model="item.address">Address</TextBox>
        </div>
        <div class="col-6">
          <LookUp v-model="selectedRegion"
                  ref="region" url="address/region-list"
                  :address="selectedCountry != null ? selectedCountry.value + '' : ''"
                  placeholder="Select">State/Region
          </LookUp>
        </div>
        <div class="col-6">
          <LookUp v-model="selectedCity"
                  ref="city" url="address/city-list"
                  :address="selectedRegion != null ? selectedRegion.value + '': ''"
                  placeholder="Select">City
          </LookUp>
        </div>
        <div class="col-6">
          <TextBox v-model="item.zipCode">Zip Code</TextBox>
        </div>
        <div class="col-6">
          <LookUp url="address/country-list" v-model="selectedCountry">Country</LookUp>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'AddressPanel',
  props: {
    isSummary: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      item: {
        address: '',
        zipCode: ''
      },
      selectedRegion: null,
      selectedCity: null,
      selectedCountry: null,
      regionList: [],
      cityList: []
    }
  },
  created () {
    // this.axios.get('address/default-country')
    //   .then((response) => {
    //     if (response.data && response.data.data) {
    //       const c = response.data.data
    //       this.selectedCountry = {
    //         value: c.id,
    //         label: c.name
    //       }
    //     }
    //   })
  },
  methods: {
    clear () {
      this.item = {
        address: '',
        zipCode: ''
      }
      this.selectedRegion = null
      this.selectedCity = null
    },
    setItem (item) {
      this.item = item
      if (item.countryId) {
        this.selectedCountry = {
          label: item.countryName,
          value: item.countryId
        }
      }
      if (item.regionId) {
        this.selectedRegion = {
          label: item.regionName,
          value: item.regionId
        }
      }
      if (item.cityId) {
        this.selectedCity = {
          label: item.cityName,
          value: item.cityId
        }
      }
    },
    getItem () {
      const item = {
        address: this.item.address,
        zipCode: this.item.zipCode
      }
      if (this.item.id) {
        item.id = this.item.id
      }
      if (this.selectedCountry) {
        item.countryId = this.selectedCountry.value
        item.countryName = this.selectedCountry.label
      }
      if (this.selectedRegion) {
        item.regionId = this.selectedRegion.value
        item.regionName = this.selectedRegion.label
      }
      if (this.selectedCity) {
        item.cityId = this.selectedCity.value
        item.cityName = this.selectedCity.label
      }
      return item
    },
    searchRegion (search) {
      if (this.selectedCountry) {
        this.axios.get('address/region-list', { params: { searchKey: search, id: this.selectedCountry.value } })
          .then((response) => {
            if (response.data && response.data.data) {
              this.setLookUpOptions(response.data.data)
            }
          })
      }
    },
    setLookUpOptions (data) {
      const list = []
      data.forEach(function (item) {
        list.push({
          label: item.name,
          value: item.id
        })
      })
      this.regionList = list
    },
    searchCity (search) {
      if (this.selectedRegion) {
        this.axios.get('address/city-list', { params: { searchKey: search, id: this.selectedRegion.value } })
          .then((response) => {
            if (response.data && response.data.data) {
              this.setCityLookUpOptions(response.data.data)
            }
          })
      }
    },
    setCityLookUpOptions (data) {
      const list = []
      data.forEach(function (item) {
        list.push({
          label: item.name,
          value: item.id
        })
      })
      this.cityList = list
    }
  }
}
</script>

<style scoped>

</style>
