<template>
  <div class="modal-content border-0">
    <div class="modal-header border-0">
      <div class="d-flex align-items-center">
        <h5 class="modal-title" id="exampleModalLabel"><b>New Manufacturer</b></h5>
      </div>
      <button type="button" class="close" @click="closePopup" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <b-row>
        <b-col sm="4">
          <TextBox v-model="item.name" placeholder="Name" required :addClass="{'is-invalid': $v.item.name.$error}">
            Name
          </TextBox>
        </b-col>
      </b-row>
    </div>
    <div class="modal-footer">
      <b-button type6="button" class="btn btn-green" @click.prevent="onSubmit">
        Save
      </b-button>
    </div>
  </div>
</template>

<script>import { required } from 'vuelidate/lib/validators'
export default {
  name: 'ManufacturerAddModal',
  data () {
    return {
      item: {
        name: ''
      }
    }
  },
  created () {
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if (this.$v.item.name.$invalid) {
        this.$v.$touch()
        return
      }
      const axios = this.axios.post('manufacturers/modal', this.item)
      this.$loading.show()
      axios.then((res) => {
        if (res.data && res.status === 200) {
          this.$emit('manufacturerModalClose', res.data)
        } else {
          this.$toastr.error(res.data)
        }
        this.$loading.hide()
      }).catch((error) => {
        this.$loading.hide()
        if (error.message) {
          this.$toastr.error(error.message)
        }
      })
    },
    closePopup () {
      this.$emit('manufacturerModalClose', null)
    }
  },
  validations: {
    item: {
      name: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>
