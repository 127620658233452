export default {
  name: 'Render',
  props: {
    data: Object,
    render: {
      type: Function,
      required: true
    }
  },
  render () {
    if (this.data) {
      return (this.$props.render(this.$props.data))
    } else {
      return (this.$props.render())
    }
  }
}
