<template>
  <div class="d-flex justify-content-between">
    <div class="px-3 py-3 d-flex align-items-center">
      <div class="d-flex align-items-center">
        <Render v-if="addNewButton" :render="addNewButton"/>
        <b-form-select :options="limitOptions"
                       value="20"
                       @change="$emit('update-limit', $event)"
                       class="mr-3 custom-select--short-num"
                       style="border-color: #6c757d"
        />
        <b-button :variant="'outline-secondary'"
                  class="mr-3"
                  @click="$emit('reset')">
          <i class="fa fa-sync-alt"></i>
        </b-button>
      </div>
      <div class="input-group w-auto mt-0 mr-3">
        <input class="form-control "
               type="text"
               v-model="$parent.searchKey"
               @keyup.enter="$emit('search')"
               placeholder="Search"
               style="border-color: #6c757d"
        >
        <span class="input-group-append">
          <b-button :variant="'outline-secondary'"
                    @click="$emit('search')">
            <i class="fa fa-search"></i>
          </b-button>
        </span>
      </div>
      <div class="d-flex align-items-center">
        <Render v-if="filterButton" :render="filterButton"/>
      </div>
    </div>
    <div class="px-3 py-3 d-flex align-items-center">
      <button class="btn btn-secondary"
              v-b-tooltip.hover.v-secondary title="Customize">
        <i class="fas fa-sliders-h"></i>
      </button>
    </div>
  </div>

</template>

<script>
export default {
  name: 'TableHeaderPanel',
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    addNewButton: {
      type: Function
    },
    filterButton: {
      type: Function
    }
  },
  data () {
    return {
      modal: false,
      button: null,
      limitOptions: [
        { value: 20, text: '20' },
        { value: 30, text: '30' },
        { value: 40, text: '40' },
        { value: 50, text: '50' }
      ]
    }
  }
}
</script>
