<template>
  <div>
    <div class="d-flex flex-column flex-grow-1">
      <div class="animated fadeIn">
        <b-row>
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>General Details
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="4">
                          <TextBox v-model="item.code" required :addClass="{'is-invalid': $v.item.code.$error}">Discount code</TextBox>
                          <TextBox v-model="item.name" required :addClass="{'is-invalid': $v.item.name.$error}">Name</TextBox>
                          <div class="form-group">
                            <label>Description</label>
                            <textarea v-model="item.description" style="min-height: 100px" class="form-control"></textarea>
                          </div>
                        </b-col>
                        <b-col sm="4">
                          <SelectBox :items="types" v-model="item.type" @input="onchangeType">Type</SelectBox>
                          <div v-if="item.type === 'PERCENTAGE'">
                            <label>Value</label>
                            <b-input-group append="%">
                              <b-form-input v-model="item.percentage" type="number"></b-form-input>
                            </b-input-group>
                          </div>
                          <TextBox v-else-if="item.type === 'FIXED_AMOUNT'" v-model="item.fixedAmount" inputtype="number">Value</TextBox>
                          <SelectBox :items="provides" v-model="item.provide">Provide</SelectBox>
                          <b-row>
                            <b-col sm="6">
                              <DatePicker v-model="item.fromDate">From Date</DatePicker>
                            </b-col>
                            <b-col sm="6">
                              <DatePicker v-model="item.toDate">To Date</DatePicker>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col sm="4">
                          <SelectBox v-if="item.type === 'FREE_SHIPPING'" :items="appliesByFreeShipping" v-model="selectedApplyTo">Applies To</SelectBox>
                          <SelectBox v-else :items="applies" v-model="selectedApplyTo">Applies To</SelectBox>
                          <MultiSelectBox v-if="selectedApplyTo === 'SPECIFIC_CATEGORIES'" :items="categories" v-model="selectedCategories">Categories</MultiSelectBox>
                          <MultiSelectBox v-else-if="selectedApplyTo === 'SPECIFIC_PRODUCTS'" :items="products" v-model="selectedProducts">Products</MultiSelectBox>
                          <MultiSelectBox v-else-if="selectedApplyTo === 'SPECIFIC_COUNTRIES'" :items="countries" v-model="selectedCountry">Countries</MultiSelectBox>
                          <SelectBox :items="statuses" v-model="selectedStatus">Status</SelectBox>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>Requirements, Eligibility and Limits
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="4">
                            <label>Minimum Requirements</label>
                          <div>
                          <input type="radio" v-model="none" :checked="none" @input="onChangeNone"
                                 value="true"> None
                          </div>
                          <div>
                          <input type="radio" v-model="minPurchaseAmount" :checked="minPurchaseAmount" @input="onChangeMinPurchaseAmount"
                                   value="true"> Minimum Purchase Amount
                          </div>
                          <div>
                          <input type="radio" v-model="minQtyItems" :checked="minQtyItems" @input="onChangeminQtyItems"
                                   value="true"> Minimum Quantity of Items
                          </div>
                        </b-col>
                        <b-col sm="4">
                            <label>Customer Eligibility</label>
                          <div>
                          <input type="radio" v-model="allCustomer" :checked="allCustomer" @input="onChangeAllCustomer"
                                 value="true"> All Customers
                          </div>
                          <div>
                          <input type="radio" v-model="specificGroups" :checked="specificGroups" @input="onChangeSpecificGroup"
                                   value="true"> Specific Groups
                          </div>
                          <div>
                          <input type="radio" v-model="specificCustomers" :checked="specificCustomers" @input="onChangeSpecificCustomers"
                                   value="true"> Specific Customers
                          </div>
                        </b-col>
                        <b-col sm="4">
                            <label>Usage limits</label>
                          <div>
                            <input type="checkbox" id="checkbox1" v-model="numberOfTimes"> Number of times can be used
                            <b-form-input v-if="numberOfTimes" v-model="numberOfTimesUsed" type="number"></b-form-input>
                          </div>
                          <div>
                            <input type="checkbox" id="checkbox2" v-model="oneUsePerCustomer"> One use per Customer
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>Labels and Messages
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="6">
                          <TextBox v-model="item.title">Title</TextBox>
                          <TextBox v-model="item.subtitle">Subtitle</TextBox>
                          <TextBox v-model="item.tag">Tag</TextBox>
                          <label>Color </label>
                        </b-col>
                        <b-col sm="6">
                          <div class="form-group">
                            <label>Success Message</label>
                            <textarea v-model="item.successMessage" style="min-height: 100px" class="form-control"></textarea>
                          </div>
                          <div class="form-group">
                            <label>Warning Message</label>
                            <textarea v-model="item.warningMessage" style="min-height: 100px" class="form-control"></textarea>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
                <div class="add-edit-fields-action-bar">
                  <div class="form-actions">
                    <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
                    <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/discount'">
                      Cancel
                    </router-link>
                  </div>
                </div>
              </b-card>
            </transition>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'DiscountAddEditPage',
  data () {
    return {
      item: {
        name: '',
        description: '',
        code: '',
        type: 'PERCENTAGE',
        percentage: null,
        fixedAmount: null,
        provide: '',
        fromDate: null,
        toDate: null,
        title: '',
        tag: '',
        subtitle: '',
        successMessage: 'Congratulations, Discount has been applied.',
        warningMessage: 'ERROR: Discount code is not available. Please enter a valid promotion code.'
      },
      types: [
        { text: 'Percentage', value: 'PERCENTAGE' },
        { text: 'Fixed Amount', value: 'FIXED_AMOUNT' },
        { text: 'Free Shipping', value: 'FREE_SHIPPING' },
        { text: 'Buy X get Y', value: 'BUY_X_GET_Y' }
      ],
      provides: [
        { text: 'Select', value: '' },
        { text: 'Automatically', value: 'AUTOMATICALLY' },
        { text: 'By Discount Code', value: 'BY_DISCOUNT_CODE' }
      ],
      appliesByFreeShipping: [
        { text: 'Select', value: '' },
        { text: 'All countries', value: 'ALL_COUNTRIES' },
        { text: 'Specific Countries', value: 'SPECIFIC_COUNTRIES' }
      ],
      applies: [
        { text: 'Select', value: '' },
        { text: 'Entire Order', value: 'ENTIRE_ORDER' },
        { text: 'Specific Categories', value: 'SPECIFIC_CATEGORIES' },
        { text: 'Specific Products', value: 'SPECIFIC_PRODUCTS' },
        { text: 'Shipping Amount', value: 'SHIPPING_AMOUNT' }
      ],
      statuses: [
        { text: 'Active', value: 'ACTIVE' },
        { text: 'Scheduled', value: 'SCHEDULED' },
        { text: 'Inactive', value: 'INACTIVE' },
        { text: 'Expired', value: 'EXPIRED' }
      ],
      selectedStatus: 'ACTIVE',
      selectedApplyTo: '',
      selectedCategories: [],
      categories: [],
      products: [],
      countries: [],
      selectedProducts: [],
      selectedCountry: [],
      none: true,
      minPurchaseAmount: false,
      minQtyItems: false,
      allCustomer: true,
      specificGroups: false,
      specificCustomers: false,
      numberOfTimes: false,
      numberOfTimesUsed: null,
      oneUsePerCustomer: false
    }
  },
  created () {
    this.loadRelatedData()
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('discount/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        if (response.data.appliesTo) {
          this.selectedApplyTo = response.data.appliesTo
          if (response.data.appliesTo && response.data.appliesTo === 'SPECIFIC_CATEGORIES' && response.data.categories) {
            this.selectedCategories = this.item.categories
          } else if (response.data.appliesTo && response.data.appliesTo === 'SPECIFIC_PRODUCTS' && response.data.products) {
            this.selectedProducts = this.item.products
          } else if (response.data.appliesTo && response.data.appliesTo === 'SPECIFIC_COUNTRIES' && response.data.products) {
            this.selectedProducts = this.item.products
          }
        }

        if (this.minRequiredType === 'NONE') {
          this.none = true
        } else if (this.minRequiredType === 'MIN_PURCHASE_AMOUNT') {
          this.minPurchaseAmount = true
        } else if (this.minRequiredType === 'MIN_QTY_ITEMS') {
          this.minQtyItems = true
        }

        if (this.item.customerEligibility === 'ALL_CUSTOMER') {
          this.allCustomer = true
        } else if (this.item.customerEligibility === 'SPECIFIC_GROUPS') {
          this.specificGroups = true
        } else if (this.item.customerEligibility === 'SPECIFIC_CUSTOMERS') {
          this.specificCustomers = true
        }
        if (this.item.numberOfTimesUsed) {
          this.numberOfTimes = true
          this.numberOfTimesUsed = this.item.numberOfTimesUsed
        }
        this.oneUsePerCustomer = this.item.oneUsePerCustomer
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return this.$route.params.id ? 'Edit Tax' : 'Add Tax'
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if (this.$v.item.name.$invalid || this.$v.item.code.$invalid) {
        this.$v.$touch()
        this.$toastr.error('Please, fill required fields')
        return
      }
      this.$loading.show()
      const _item = { ...this.item }
      _item.status = this.selectedStatus
      _item.appliesTo = this.selectedApplyTo

      if (this.selectedApplyTo && this.selectedApplyTo === 'SPECIFIC_CATEGORIES' && this.selectedCategories) {
        _item.categories = this.selectedCategories
      }
      if (this.selectedApplyTo && this.selectedApplyTo === 'SPECIFIC_PRODUCTS') {
        _item.products = this.selectedProducts
      }
      if (this.selectedApplyTo && this.selectedApplyTo === 'SPECIFIC_COUNTRIES') {
        _item.countries = this.selectedCountry
      }
      if (this.none) {
        _item.minRequiredType = 'NONE'
      } else if (this.minPurchaseAmount) {
        _item.minRequiredType = 'MIN_PURCHASE_AMOUNT'
      } else if (this.minQtyItems) {
        _item.minRequiredType = 'MIN_QTY_ITEMS'
      }

      if (this.allCustomer) {
        _item.customerEligibility = 'ALL_CUSTOMER'
      } else if (this.specificGroups) {
        _item.customerEligibility = 'SPECIFIC_GROUPS'
      } else if (this.specificCustomers) {
        _item.customerEligibility = 'SPECIFIC_CUSTOMERS'
      }
      if (this.numberOfTimes) {
        _item.numberOfTimesUsed = this.numberOfTimesUsed
      } else {
        _item.numberOfTimesUsed = null
      }
      _item.oneUsePerCustomer = this.oneUsePerCustomer

      if (this.item.title) {
        _item.title = this.item.title
      } else {
        _item.title = this.item.name
      }
      if (this.item.subtitle) {
        _item.subtitle = this.item.subtitle
      } else {
        _item.subtitle = this.item.description
      }
      if (this.item.type === 'PERCENTAGE') {
        _item.percentage = this.item.percentage
        _item.fixedAmount = null
      } else if (this.item.type === 'FIXED_AMOUNT') {
        _item.fixedAmount = this.item.fixedAmount
        _item.percentage = null
      } else {
        _item.percentage = null
        _item.fixedAmount = null
      }

      let axios
      if (_item.id) {
        axios = this.axios.put('discount', _item)
      } else {
        axios = this.axios.post('discount', _item)
      }
      axios.then(response => {
        if (response.status === 200) {
          this.$toastr.success(response.data)
          this.$router.push('/discount')
        } else {
          this.$toastr.error(response.data.message)
        }
        this.$loading.hide()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$toastr.error(error.response.error)
        }
        this.$loading.hide()
      })
    },
    async loadRelatedData () {
      this.$loading.show()
      const [categoryReq, productReq, countryReq] = await Promise.all([
        this.axios.get('categories/items'),
        this.axios.get('products/items'),
        this.axios.get('address/country-list')
      ])
      if (categoryReq && categoryReq.data) {
        this.setSelectedItems(categoryReq.data, 'categories')
      }
      if (productReq && productReq.data) {
        this.setSelectedItems(productReq.data, 'products')
      }
      if (countryReq && countryReq.data) {
        this.setSelectedItems(countryReq.data, 'countries')
      }
      this.$loading.hide()
    },
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    },
    onchangeType () {
      if (this.item.type) {
        if (this.item.type === 'PERCENTAGE') {
          this.selectedApplyTo = ''
          this.selectedCategories = []
          this.selectedProducts = []
          this.selectedCountry = []
        } else if (this.item.type === 'FIXED_AMOUNT') {
          this.selectedApplyTo = ''
          this.selectedCategories = []
          this.selectedProducts = []
          this.selectedCountry = []
        } else if (this.item.type === 'FREE_SHIPPING') {
          this.selectedApplyTo = ''
          this.selectedCategories = []
          this.selectedProducts = []
          this.selectedCountry = []
        }
      }
    },
    onChangeNone () {
      this.none = true
      this.minPurchaseAmount = false
      this.minQtyItems = false
    },
    onChangeMinPurchaseAmount () {
      this.minPurchaseAmount = true
      this.none = false
      this.minQtyItems = false
    },
    onChangeminQtyItems () {
      this.minQtyItems = true
      this.minPurchaseAmount = false
      this.none = false
    },
    onChangeAllCustomer () {
      this.allCustomer = true
      this.specificGroups = false
      this.specificCustomers = false
    },
    onChangeSpecificGroup () {
      this.specificGroups = true
      this.allCustomer = false
      this.specificCustomers = false
    },
    onChangeSpecificCustomers () {
      this.specificCustomers = true
      this.specificGroups = false
      this.allCustomer = false
    }
  },
  validations: {
    item: {
      code: {
        required
      },
      name: {
        required
      },
      rate: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>
