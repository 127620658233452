<template>
  <div>
    <template v-if="isSummary">
      <div class="row">
        <div class="col-4">
          <b-form-group>
            <label>Meta Title</label>
            <div>{{ item.metaTitle }}</div>
          </b-form-group>
          <b-form-group>
            <label>Meta Description</label>
            <div>{{ item.metaDescription }}</div>
          </b-form-group>
        </div>
        <div class="col-4">
          <b-form-group>
            <label>URL Slug</label>
            <div>{{ item.urlSlug }}</div>
          </b-form-group>
          <b-form-group>
            <label>Meta Keywords</label>
            <div>{{ item.metaKeywords }}</div>
          </b-form-group>
        </div>
        <div class="col-4">
          <label>Preview</label>
          <div class="google-search-preview">
            <div class="domain">{{item.urlSlug}}</div>
            <div class="site-title">{{item.metaTitle}}</div>
            <div class="meta-description">{{ item.metaDescription }}</div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row">
        <div class="col-4">
          <label>Meta Title</label>
          <div>
            <input v-model="item.metaTitle" type="text" class="form-control"  @input="onChangeMetaTitle">
            <div class="col text-right character-limit">
              <span v-if="countMetaTitle > 60"><b style="color: red">{{ countMetaTitle }}</b>  out of  <b>60</b>  max recommended characters</span>
              <span v-else><b>{{ countMetaTitle }}</b>  out of  <b>60</b>  max recommended characters</span>
            </div>
          </div>
          <label>Meta Description</label>
          <div >
            <textarea v-model="item.metaDescription" class="form-control" @input="onChangeMetaDescription"></textarea>
            <div class="col text-right character-limit">
              <span v-if="countMetaDescription > 160"><b style="color: red">{{ countMetaDescription }}</b>  out of  <b>160</b>  max recommended characters</span>
              <span v-else><b>{{ countMetaDescription }}</b>  out of  <b>160</b>  max recommended characters</span>
            </div>
          </div>
        </div>
        <div class="col-4">
            <TextBox v-model="item.urlSlug" @input="onChangeUrlSlug">URL Slug</TextBox>
            <TextBox style="padding-top:8px" v-model="item.metaKeywords">Meta Keywords</TextBox>
        </div>
        <div class="col-4">
          <label>Preview</label>
          <div class="google-search-preview">
            <div class="domain">{{item.urlSlug}}</div>
            <div class="site-title">{{item.metaTitle}}</div>
            <div class="meta-description">{{ item.metaDescription }}</div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SearchEngineOptimization',
  props: {
    isSummary: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      item: {
        urlSlug: null,
        metaTitle: null,
        metaDescription: null,
        metaKeywords: null
      },
      countMetaTitle: 0,
      countMetaDescription: 0
    }
  },
  created () {
  },
  methods: {
    setItem (item) {
      this.item = item
      this.onChangeMetaDescription()
      this.onChangeMetaTitle()
    },
    getItem (_item) {
      if (_item) {
        _item.urlSlug = this.item.urlSlug
        _item.metaTitle = this.item.metaTitle
        _item.metaDescription = this.item.metaDescription
        _item.metaKeywords = this.item.metaKeywords
      }
    },
    setObjectName (item) {
      if (item) {
        let objectName = ''
        objectName = item
          .replace(/\u0401/g, 'YO')
          .replace(/\u0419/g, 'I')
          .replace(/\u0426/g, 'TS')
          .replace(/\u0423/g, 'U')
          .replace(/\u041A/g, 'K')
          .replace(/\u0415/g, 'E')
          .replace(/\u041D/g, 'N')
          .replace(/\u0413/g, 'G')
          .replace(/\u0428/g, 'SH')
          .replace(/\u0429/g, 'SCH')
          .replace(/\u0417/g, 'Z')
          .replace(/\u0425/g, 'H')
          .replace(/\u042A/g, '')
          .replace(/\u0451/g, 'yo')
          .replace(/\u0439/g, 'i')
          .replace(/\u0446/g, 'ts')
          .replace(/\u0443/g, 'u')
          .replace(/\u043A/g, 'k')
          .replace(/\u0435/g, 'e')
          .replace(/\u043D/g, 'n')
          .replace(/\u0433/g, 'g')
          .replace(/\u0448/g, 'sh')
          .replace(/\u0449/g, 'sch')
          .replace(/\u0437/g, 'z')
          .replace(/\u0445/g, 'h')
          .replace(/\u044A/g, "'")
          .replace(/\u0424/g, 'F')
          .replace(/\u042B/g, 'I')
          .replace(/\u0412/g, 'V')
          .replace(/\u0410/g, 'a')
          .replace(/\u041F/g, 'P')
          .replace(/\u0420/g, 'R')
          .replace(/\u041E/g, 'O')
          .replace(/\u041B/g, 'L')
          .replace(/\u0414/g, 'D')
          .replace(/\u0416/g, 'ZH')
          .replace(/\u042D/g, 'E')
          .replace(/\u0444/g, 'f')
          .replace(/\u044B/g, 'i')
          .replace(/\u0432/g, 'v')
          .replace(/\u0430/g, 'a')
          .replace(/\u043F/g, 'p')
          .replace(/\u0440/g, 'r')
          .replace(/\u043E/g, 'o')
          .replace(/\u043B/g, 'l')
          .replace(/\u0434/g, 'd')
          .replace(/\u0436/g, 'zh')
          .replace(/\u044D/g, 'e')
          .replace(/\u042F/g, 'Ya')
          .replace(/\u0427/g, 'CH')
          .replace(/\u0421/g, 'S')
          .replace(/\u041C/g, 'M')
          .replace(/\u0418/g, 'I')
          .replace(/\u0422/g, 'T')
          .replace(/\u042C/g, "'")
          .replace(/\u0411/g, 'B')
          .replace(/\u042E/g, 'YU')
          .replace(/\u044F/g, 'ya')
          .replace(/\u0447/g, 'ch')
          .replace(/\u0441/g, 's')
          .replace(/\u043C/g, 'm')
          .replace(/\u0438/g, 'i')
          .replace(/\u0442/g, 't')
          .replace(/\u044C/g, "'")
          .replace(/\u0431/g, 'b')
          .replace(/\u044E/g, 'yu')

        this.item.urlSlug = objectName != null && objectName.length > 0 ? objectName.trim().toLocaleLowerCase().replaceAll(' ', '-') : ''
        this.item.urlSlug = this.item.urlSlug.replaceAll(' ', '').replaceAll('&', 'and')
        this.item.metaTitle = objectName
      } else {
        this.item.urlSlug = item
        this.item.metaTitle = item
      }
    },
    setObjectDescription (item) {
      if (item) {
        this.item.metaDescription = item
        this.onChangeMetaDescription()
      }
    },
    onChangeMetaTitle () {
      this.countMetaTitle = this.item.metaTitle.length
    },
    onChangeMetaDescription () {
      this.countMetaDescription = this.item.metaDescription.length
    },
    onChangeUrlSlug () {
      this.item.urlSlug = this.item.urlSlug
        .replace(/\u0401/g, 'YO')
        .replace(/\u0419/g, 'I')
        .replace(/\u0426/g, 'TS')
        .replace(/\u0423/g, 'U')
        .replace(/\u041A/g, 'K')
        .replace(/\u0415/g, 'E')
        .replace(/\u041D/g, 'N')
        .replace(/\u0413/g, 'G')
        .replace(/\u0428/g, 'SH')
        .replace(/\u0429/g, 'SCH')
        .replace(/\u0417/g, 'Z')
        .replace(/\u0425/g, 'H')
        .replace(/\u042A/g, '')
        .replace(/\u0451/g, 'yo')
        .replace(/\u0439/g, 'i')
        .replace(/\u0446/g, 'ts')
        .replace(/\u0443/g, 'u')
        .replace(/\u043A/g, 'k')
        .replace(/\u0435/g, 'e')
        .replace(/\u043D/g, 'n')
        .replace(/\u0433/g, 'g')
        .replace(/\u0448/g, 'sh')
        .replace(/\u0449/g, 'sch')
        .replace(/\u0437/g, 'z')
        .replace(/\u0445/g, 'h')
        .replace(/\u044A/g, "'")
        .replace(/\u0424/g, 'F')
        .replace(/\u042B/g, 'I')
        .replace(/\u0412/g, 'V')
        .replace(/\u0410/g, 'a')
        .replace(/\u041F/g, 'P')
        .replace(/\u0420/g, 'R')
        .replace(/\u041E/g, 'O')
        .replace(/\u041B/g, 'L')
        .replace(/\u0414/g, 'D')
        .replace(/\u0416/g, 'ZH')
        .replace(/\u042D/g, 'E')
        .replace(/\u0444/g, 'f')
        .replace(/\u044B/g, 'i')
        .replace(/\u0432/g, 'v')
        .replace(/\u0430/g, 'a')
        .replace(/\u043F/g, 'p')
        .replace(/\u0440/g, 'r')
        .replace(/\u043E/g, 'o')
        .replace(/\u043B/g, 'l')
        .replace(/\u0434/g, 'd')
        .replace(/\u0436/g, 'zh')
        .replace(/\u044D/g, 'e')
        .replace(/\u042F/g, 'Ya')
        .replace(/\u0427/g, 'CH')
        .replace(/\u0421/g, 'S')
        .replace(/\u041C/g, 'M')
        .replace(/\u0418/g, 'I')
        .replace(/\u0422/g, 'T')
        .replace(/\u042C/g, "'")
        .replace(/\u0411/g, 'B')
        .replace(/\u042E/g, 'YU')
        .replace(/\u044F/g, 'ya')
        .replace(/\u0447/g, 'ch')
        .replace(/\u0441/g, 's')
        .replace(/\u043C/g, 'm')
        .replace(/\u0438/g, 'i')
        .replace(/\u0442/g, 't')
        .replace(/\u044C/g, "'")
        .replace(/\u0431/g, 'b')
        .replace(/\u044E/g, 'yu')
    }
  }
}
</script>

<style scoped>

</style>
