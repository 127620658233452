import Vue from 'vue'

const loading = new Vue({
  data: {
    isShow: false,
    isWhite: false
  },
  methods: {
    show (white) {
      this.isShow = true
      if (white) {
        this.isWhite = true
      }
    },
    hide () {
      this.isShow = false
      this.isWhite = false
    }
  }
})

export default {
  install (Vue) {
    Vue.prototype.$loading = loading
  }
}
