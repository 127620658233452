import {
  USER,
  AUTHORIZATION
} from '../constants'

export const setAuth = (token) => {
  localStorage.setItem(AUTHORIZATION, token)
}

export const getAuth = () => {
  return {
    sessionToken: localStorage.getItem(AUTHORIZATION)
  }
}

export const setUser = (user) => {
  localStorage.setItem(USER, JSON.stringify(user))
}

export const resetAuth = () => {
  localStorage.removeItem(AUTHORIZATION)
}
