<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fas fa-link mr-2"></i>General Details
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Currency name</label>
                          <div class="summary-field">{{ item.name}}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Currency code</label>
                          <div class="summary-field">{{ item.code}}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Symbol</label>
                          <div class="summary-field">{{ item.symbol}}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Exchange rate</label>
                          <div class="summary-field">{{ item.exchangeRate}}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Note</label>
                          <div class="summary-field">{{ item.note}}</div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
              <div class="add-edit-fields-action-bar">
                <div class="form-actions">
                  <router-link :to="'/settings/currency/item/' + item.id">
                    <b-button type="button" variant="primary" class="mr-2">Edit</b-button>
                  </router-link>
                  <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/settings/currency'">
                    Cancel
                  </router-link>
                </div>
              </div>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomerViewPage',
  data () {
    return {
      item: {
        name: ''
      }
    }
  },
  created () {
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('currency/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return 'Currency Summary'
    }
  }
}
</script>

<style scoped>

</style>
