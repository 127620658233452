<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fas fa-link mr-2"></i>General Details
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="6">
                        <b-form-group>
                          <label>Name</label>
                          <div class="summary-field">{{ item.name}}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6">
                        <b-form-group>
                          <label>Rate</label>
                          <div class="summary-field">{{ item.rate + '%'}}</div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i>Specific Rates
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="12">
                        <table class="table table-bordered table-sm">
                          <thead class="thead-light">
                          <tr>
                            <th width="20%" scope="col">Country</th>
                            <th width="15%" scope="col">State</th>
                            <th width="15%" scope="col">City</th>
                            <th width="10%" scope="col">Rate %</th>
                            <th width="20%" scope="col">Tax Name</th>
                            <th width="10%" scope="col">Priority</th>
                            <th width="5%" scope="col">Compound</th>
                            <th width="5%" scope="col">Shipping</th>
                          </tr>
                          </thead>
                          <tbody v-for="(taxItem, index) in taxItems" :key="index">
                          <tr>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ taxItem.countryName }}</span>
                            </td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ taxItem.regionName }}</span>
                            </td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ taxItem.cityName }}</span>
                            </td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ taxItem.rate }}</span>
                            </td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ taxItem.taxName }}</span>
                            </td>
                            <td class="text-center" style="vertical-align: middle;">
                              <span>{{ taxItem.priority }}</span>
                            </td>
                            <td style="vertical-align: middle; text-align: center;">
                              <span>{{ taxItem.compound === true ? 'Yes' : 'No' }}</span>
                            </td>
                            <td style="vertical-align: middle; text-align: center;">
                              <span>{{ taxItem.shipping === true ? 'Yes' : 'No' }}</span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
                <div class="add-edit-fields-action-bar">
                  <div class="form-actions">
                    <router-link :to="'/settings/tax/item/' + item.id">
                      <b-button type="button" variant="primary" class="mr-2">Edit</b-button>
                    </router-link>
                    <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/settings/tax'">
                      Cancel
                    </router-link>
                  </div>
                </div>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TaxViewPage',
  data () {
    return {
      item: {
        name: ''
      },
      taxItems: [
        {
          id: null,
          country: null,
          countryId: null,
          countryName: null,
          region: null,
          regionId: null,
          regionName: null,
          city: null,
          cityId: null,
          cityName: null,
          rate: null,
          taxName: null,
          priority: null,
          compound: false,
          shipping: false
        }
      ]
    }
  },
  created () {
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('tax/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.taxItems = this.item.items
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return 'Tax Summary'
    }
  }
}
</script>

<style scoped>

</style>
