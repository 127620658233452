<template>
  <div class="d-flex flex-column flex-grow-1">
    <ListingComponent class="bg-white"
                      ref="productList"
                      :addNewButton="addNewButton"
                      :requestProvider="requestProvider"
                      :columnConfig="columnConfig">
    </ListingComponent>
    <ConfirmationModal ref="confirmationModal"
                       content="Do you really want to delete"
                       @cancel="closeDeleteModal"
                       @yes="removeItem">
    </ConfirmationModal>
  </div>
</template>

<script>
import ListingComponent from '../../components/listing/ListingComponent'
export default {
  name: 'TaxListPage',
  data () {
    return {
      columnConfig: [
        {
          columnCode: 'action',
          columnName: 'Action',
          value: item => {
            return (
              <b-dropdown variant="button" toggle-class="btn btn-sm btn-light " class="dropdown-options">
                <b-dropdown-item href={'#/discount/summary/' + item.id}>Summary</b-dropdown-item>
                <b-dropdown-item href={'#/discount/item/' + item.id}>Edit</b-dropdown-item>
                <b-dropdown-item onClick={() => this.showDeleteModal(item.id)}>Delete</b-dropdown-item>
              </b-dropdown>
            )
          }
        },
        {
          columnCode: 'discountCode',
          columnName: 'Discount code',
          value: item => {
            return item.code
          },
          link: item => {
            return '/discount/summary/' + item.id
          }
        },
        {
          columnCode: 'name',
          columnName: 'Name',
          value: item => {
            return item.name
          },
          link: item => {
            return '/discount/summary/' + item.id
          }
        },
        {
          columnCode: 'type',
          columnName: 'Type',
          value: item => {
            if (item.type === 'PERCENTAGE') {
              return 'Percentage'
            } else if (item.type === 'FIXED_AMOUNT') {
              return 'Fixed Amount'
            } else if (item.type === 'FREE_SHIPPING') {
              return 'Free Shipping'
            } else if (item.type === 'BUY_X_GET_Y') {
              return 'Buy X get Y'
            }
            return ''
          }
        },
        {
          columnCode: 'provide',
          columnName: 'Provide',
          value: item => {
            if (item.provide === 'AUTOMATICALLY') {
              return 'Automatically'
            } else if (item.provide === 'BY_DISCOUNT_CODE') {
              return 'By Discount Code'
            }
            return ''
          }
        },
        {
          columnCode: 'fromDate',
          columnName: 'From date',
          value: item => {
            return item.fromDate
          }
        },
        {
          columnCode: 'toDate',
          columnName: 'To date',
          value: item => {
            return item.toDate
          }
        },
        {
          columnCode: 'status',
          columnName: 'Status',
          value: item => {
            if (item.status === 'ACTIVE') {
              return 'Active'
            } else if (item.status === 'SCHEDULED') {
              return 'Scheduled'
            } else if (item.status === 'INACTIVE') {
              return 'Inactive'
            } else if (item.status === 'EXPIRED') {
              return 'Expired'
            }
            return ''
          }
        }
      ]
    }
  },
  methods: {
    requestProvider (payload) {
      return this.axios.get('discount', payload)
    },
    addNewButton () {
      return (
        <router-link class="btn btn-success mr-3" to="/discount/item">
          <div class="fa fa-plus"></div>
        </router-link>
      )
    },
    showDeleteModal (id) {
      if (!id) return
      this.id = id
      this.$refs.confirmationModal.show()
    },
    closeDeleteModal () {
      this.id = null
      this.$nextTick(() => {
        this.$refs.confirmationModal.hide()
      })
    },
    removeItem () {
      if (!this.id) {
        return
      }
      this.axios.delete('discount/' + this.id)
        .then((response) => {
          this.$refs.productList.doRequest()
          if (response.data.success) {
            this.$toastr.success('Successfully deleted')
          }
          this.$refs.confirmationModal.hide()
        })
    }
  },
  components: {
    ListingComponent
  }
}
</script>

<style scoped>

</style>
