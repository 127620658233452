<template>
  <div>
    <div class="d-flex flex-column flex-grow-1">
      <div class="animated fadeIn">
        <b-row>
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>General Details
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="4">
                          <TextBox v-model="item.name" required :addClass="{'is-invalid': $v.item.name.$error}">Currency name</TextBox>
                          <TextBox v-model="item.code" required :addClass="{'is-invalid': $v.item.code.$error}">Currency code</TextBox>
                        </b-col>
                        <b-col sm="4">
                          <TextBox v-model="item.symbol">Symbol</TextBox>
                          <TextBox v-model="item.exchangeRate" required inputtype="number" :addClass="{'is-invalid': $v.item.exchangeRate.$error}">Exchange rate</TextBox>
                        </b-col>
                        <b-col sm="4">
                          <div class="form-group">
                            <label>Note</label>
                            <textarea v-model="item.note" class="form-control"></textarea>
                          </div>
                          <a-checkbox v-decorator="autoExchange" @input="onExchangeValue">
                            Automatic rate correction from Currencylayer.com
                          </a-checkbox>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
                <div class="add-edit-fields-action-bar">
                  <div class="form-actions">
                    <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
                    <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/settings/currency'">
                      Cancel
                    </router-link>
                  </div>
                </div>
              </b-card>
            </transition>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CurrencyAddEditPage',
  data () {
    return {
      item: {
        name: '',
        code: '',
        symbol: '',
        note: '',
        exchangeRate: '1'
      },
      autoExchange: false
    }
  },
  created () {
    this.loadRelatedData()
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('currency/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return this.$route.params.id ? 'Edit Currency' : 'Add Currency'
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if (this.$v.item.$invalid) {
        this.$v.$touch()
        this.$toastr.error('Please, fill required fields')
        return
      }
      this.$loading.show()
      const _item = { ...this.item }
      let axios
      if (_item.id) {
        axios = this.axios.put('currency', _item)
      } else {
        axios = this.axios.post('currency', _item)
      }
      axios.then(response => {
        if (response.status === 200) {
          this.$toastr.success(response.data)
          this.$router.push('/settings/currency')
        } else {
          this.$toastr.error(response.data.message)
        }
        this.$loading.hide()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$toastr.error(error.response.data.errors.message)
        }
        this.$loading.hide()
      })
    },
    async loadRelatedData () {
      // this.$loading.show()
      // const [categoryReq] = await Promise.all([
      //   this.axios.get('categories/items')
      // ])
      // if (categoryReq && categoryReq.data) {
      //   this.setSelectedItems(categoryReq.data, 'categories')
      // }
      // this.$loading.hide()
    },
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    },
    onExchangeValue (val) {
      if (val) {
        if (this.item.code === '') {
          this.$toastr.error('Enter currency code')
          return
        }
        this.$loading.show()
        this.axios.get('currency/exchangeRate?code=' + this.item.code)
          .then(response => {
            this.item.exchangeRate = response.data
            this.$loading.hide()
          })
          .catch(error => {
            console.log(error.response)
            this.$loading.hide()
            if (error.response && error.response.data.status === 400) {
              this.$toastr.error(error.response.data.message)
            }
          })
      }
    }
  },
  validations: {
    item: {
      name: {
        required
      },
      code: {
        required
      },
      exchangeRate: {
        required
      }
    }
  }
}
</script>

<style scoped>

</style>
