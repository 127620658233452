<template>
  <div class="d-flex justify-content-end align-items-center py-3 px-3"
       style="border-top: 1px solid rgb(235, 237, 241);"
  >
    <div class="column">
      <div>{{ info }}
        <span v-if="hasSelected">{{ selectedInfo }}</span>
      </div>
    </div>
    <div class="column is-narrow has-text-right">
      <b-pagination size="md"
                    :total-rows="totalCount"
                    v-model="currentPage"
                    :per-page="limit"
                    @change="changePage"
                    :disabled="hasCount || loading">
      </b-pagination>
    </div>
  </div>

</template>

<script>

export default {
  name: 'TableFooter',
  props: {
    start: {
      type: Number,
      required: true
    },
    totalCount: {
      type: Number,
      required: true
    },
    limit: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    selected: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      currentPage: (this.start / this.limit) + 1
    }
  },
  computed: {
    hasCount () {
      return this.totalCount <= this.limit
    },
    hasData () {
      return this.totalCount > 0
    },
    hasSelected () {
      return this.selected.length > 0
    },
    startInfo () {
      return this.hasData ? this.start + 1 : 0
    },
    totalInfo () {
      return this.totalCount < this.start + this.limit
    },
    pageInfo () {
      return this.hasData ? (this.totalInfo ? this.totalCount : this.start + this.limit) : 0
    },
    selectedInfo () {
      return this.hasSelected ? `(${this.selected.length} selected)` : ''
    },
    info () {
      return `From ${this.startInfo} to ${this.pageInfo} of ${this.totalCount} `
    }
  },
  methods: {
    changePage (page) {
      const nextPage = (page - 1) * this.limit

      if (this.start === nextPage) {
        return
      }
      this.$emit('change-pagination', nextPage)
    }
  }
}
</script>

<style lang="scss" scoped>
  .pagination {
    padding: 0 0.75em;
    margin: 0;
  }
</style>
