import store from '../store'
import moment from 'moment'

export const beforeEnter = (to, from, next) => {
  if (!store.getters.currentToken) {
    next('/login')
  } else {
    next()
  }
}

const uniqueId = () => {
  const _id = () => {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
  }
  return _id() + '-' + _id()
}

const DEFAULT_FORMAT = 'YYYY-MM-DD'
const LONG_FORMAT = 'YYYY-MM-DD hh:mm'
const dateFormat = (value) => {
  return moment(value).format(DEFAULT_FORMAT)
}
const dateLongFormat = (value) => {
  return moment(value).format(LONG_FORMAT)
}

export default beforeEnter
export {
  uniqueId,
  dateFormat,
  dateLongFormat
}
