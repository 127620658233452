<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> General Details
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Name</label>
                          <div class="summary-field">{{ item.name }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Parent Category</label>
                          <div class="summary-field">{{ item.parent ? item.parent.label : '' }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Status</label>
                          <div class="summary-field">{{ item.status && item.status === 'ACTIVE' ? 'Active' : 'Inactive' }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Description</label>
                          <div class="summary-field">{{ item.description }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <label>Image</label>
                        <div style="margin-top: 0.5rem">
                          <ImageBanner ref="banner" :isSummary="true"/>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row v-show="item.enableMultiStore" style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> Stores
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="6">
                        <b-form-group>
                          <label>Store</label>
                          <div class="summary-field">{{ item.storeNames }}</div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> Visibility
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="3">
                        <b-form-group>
                          <label>Permanent</label>
                          <div class="summary-field">{{ item.visibility === 'PERMANENT' ? 'True' : 'False' }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="3">
                        <b-form-group>
                          <label>Scheduled</label>
                          <div class="summary-field">{{ item.visibility === 'SCHEDULED' ? 'True' : 'False' }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="6" v-if="item.visibility === 'SCHEDULED'">
                        <b-row>
                          <b-col sm="6">
                            <b-form-group>
                              <label>From</label>
                              <div class="summary-field">{{ item.visibilityFrom != null ?  item.visibilityFrom[0] + '/' + item.visibilityFrom[1] + '/' + item.visibilityFrom[2] : ''}}</div>
                            </b-form-group>
                          </b-col>
                          <b-col sm="6">
                            <b-form-group>
                              <label>To</label>
                              <div class="summary-field">{{ item.visibilityTo != null ?  item.visibilityTo[0] + '/' + item.visibilityTo[1] + '/' + item.visibilityTo[2] : ''}}</div>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> Search Engine Optimization
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="12">
                        <SearchEngineOptimization :isSummary="true" ref="SEO" />
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
              <div class="add-edit-fields-action-bar">
                <div class="form-actions">
                  <router-link :to="'/category/item/' + item.id">
                    <b-button type="button" variant="primary" class="mr-2">Edit</b-button>
                  </router-link>
                  <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/category'">
                    Cancel
                  </router-link>
                </div>
              </div>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ImageBanner from '../../components/ImageBanner'
import SearchEngineOptimization from '../../components/SearchEngineOptimization'
export default {
  name: 'CategoryViewPage',
  data () {
    return {
      item: {
        name: '',
        description: '',
        parentId: null
      }
    }
  },
  created () {
    // this.loadRelatedData()
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('categories/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.$refs.banner.setImages(this.item.images)
        this.$refs.SEO.setItem(this.item)
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return this.$route.params.id ? 'Edit Category' : 'Add Category'
    }
  },
  methods: {
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    }
  },
  components: {
    ImageBanner,
    SearchEngineOptimization
  }
}
</script>

<style scoped>

</style>
