import axios from 'axios'
import {
  setUser,
  setAuth,
  resetAuth
} from '../../utils/auth'
import {
  USER,
  BASE_URL,
  API_SERVER,
  AUTHORIZATION
} from '../../constants'

const state = {
  user: JSON.parse(localStorage.getItem(USER)),
  sessionToken: localStorage.getItem(AUTHORIZATION)
}
const mutations = {
  setAuth (state, token) {
    state.sessionToken = token
  },
  setUser (state, data) {
    state.user = data
  },
  resetUser (state) {
    state.user = null
    state.sessionToken = null
  }
}
const actions = {
  async fetch ({ commit, dispatch }, param) {
    if (!param) {
      return null
    }
    const response = await axios({
      url: 'users/fetch',
      headers: { Authorization: 'Bearer ' + param },
      baseURL: API_SERVER + BASE_URL
    })
    if (response.status === 200 && response.data) {
      const user = {
        username: response.data.username,
        email: response.data.email,
        roles: response.data.roles,
        siteUrl: response.data.siteUrl
      }
      commit('setUser', user)
      commit('setAuth', param)
      setAuth(param)
      setUser(user)
    } else {
      dispatch('resetAuth')
    }
    return response
  },
  login ({ commit }, param) {
    return axios.post('users/signin', param)
      .then(response => {
        if (response.status === 200 && response.data) {
          const { data } = response
          commit('setAuth', data.token)
          setAuth(data.token)
        }
        return response
      }).catch(error => {
        return error.response
      })
  },
  signUp ({ commit }, param) {
    return axios.post('users/signup', param)
      .then(response => {
        if (response.status === 200 && response.data) {
          const { data } = response
          commit('setAuth', data.token)
          setAuth(data.token)
        }
        return response
      }).catch(error => {
        return error.response
      })
  },
  signUpValidation ({ commit }, param) {
    return axios.post('users/signup-validation', param)
      .then(response => {
        return response
      }).catch(error => {
        return error.response
      })
  },
  resetAuth ({ commit }) {
    commit('resetUser')
    resetAuth()
  }
}
const getters = {
  currentToken: (state) => {
    return state.sessionToken
  },
  isAuthenticated: (state) => {
    return !!state.user
  },
  getUserFullName: (state) => {
    let fullName = ''
    if (!state.user) {
      return fullName
    }
    if (state.user.username) {
      fullName = state.user.username
    }
    return fullName
  },
  getSiteUrl: (state) => {
    return !state.user ? '' : state.user.siteUrl
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
