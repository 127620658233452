import Vue from 'vue'
import VueRouter from 'vue-router'
import { beforeEnter } from '../utils/helper'

import MainLayout from '@/layout/MainLayout'

const LoginPage = () => import('@/views/LoginPage')
const SignUpPage = () => import('@/views/SignUpPage')
const NotFoundPage = () => import('@/views/NotFoundPage')

const LanguageListPage = () => import('@/views/language/LanguageListPage')
const LanguageAddEditPage = () => import('@/views/language/LanguageAddEditPage')
const LanguageViewPage = () => import('@/views/language/LanguageViewPage')

const CategoryListPage = () => import('@/views/category/CategoryListPage')
const CategoryAddEditPage = () => import('@/views/category/CategoryAddEditPage')
const CategoryViewPage = () => import('@/views/category/CategoryViewPage')

const ProductListPage = () => import('@/views/product/ProductListPage')
const ProductAddEditPage = () => import('@/views/product/ProductAddEditPage')
const ProductViewPage = () => import('@/views/product/ProductViewPage')

const LabelListPage = () => import('@/views/label/LabelListPage')
const LabelAddEditPage = () => import('@/views/label/LabelAddEditPage')
const LabelViewPage = () => import('@/views/label/LabelViewPage')

const CustomerListPage = () => import('@/views/customer/CustomerListPage')
const CustomerAddEditPage = () => import('@/views/customer/CustomerAddEditPage')
const CustomerViewPage = () => import('@/views/customer/CustomerViewPage')

const VendorListPage = () => import('@/views/vendor/VendorListPage')
const VendorAddEditPage = () => import('@/views/vendor/VendorAddEditPage')
const VendorViewPage = () => import('@/views/vendor/VendorViewPage')

const DeliveryPersonListPage = () => import('@/views/deliveryPerson/DeliveryPersonListPage')
const DeliveryPersonAddEditPage = () => import('@/views/deliveryPerson/DeliveryPersonAddEditPage')
const DeliveryPersonViewPage = () => import('@/views/deliveryPerson/DeliveryPersonViewPage')

const StaffListPage = () => import('@/views/staff/StaffListPage')
const StaffAddEditPage = () => import('@/views/staff/StaffAddEditPage')
const StaffViewPage = () => import('@/views/staff/StaffViewPage')

const SalesOrderListPage = () => import('@/views/salesOrder/SalesOrderListPage')
const SalesOrderAddEditPage = () => import('@/views/salesOrder/SalesOrderAddEditPage')
const SalesOrderViewPage = () => import('@/views/salesOrder/SalesOrderViewPage')

const CurrencyListPage = () => import('@/views/currency/CurrencyListPage')
const CurrencyAddEditPage = () => import('@/views/currency/CurrencyAddEditPage')
const CurrencyViewPage = () => import('@/views/currency/CurrencyViewPage')

const TaxListPage = () => import('@/views/tax/TaxListPage')
const TaxAddEditPage = () => import('@/views/tax/TaxAddEditPage')
const TaxViewPage = () => import('@/views/tax/TaxViewPage')

// const UserListPage = () => import('@/views/users/UserListPage')
// const UserAddEditPage = () => import('@/views/users/UserAddEditPage')
const MenuCreator = () => import('@/views/menus/MenuCreator')
// const tree = () => import('@/views/menus/tree')
const MenuListingPage = () => import('@/views/menus/MenuListPage')

const GeneralForm = () => import('@/views/settings/GeneralForm')
const ImageSizePage = () => import('@/views/settings/ImageSizePage')
const PaymentSettingsPage = () => import('@/views/settings/PaymentSettingsPage')

const DiscountListPage = () => import('@/views/discount/DiscountListPage')
const DiscountAddEditPage = () => import('@/views/discount/DiscountAddEditPage')
const DiscountViewPage = () => import('@/views/discount/DiscountViewPage')

const BannerListPage = () => import('@/views/banner/BannerListPage')
const BannerAddEditPage = () => import('@/views/banner/BannerAddEditPage')
const BannerViewPage = () => import('@/views/banner/BannerViewPage')

const AttributeListPage = () => import('@/views/attribute/AttributeListPage')
const AttributeAddEditPage = () => import('@/views/attribute/AttributeAddEditPage')
const AttributeViewPage = () => import('@/views/attribute/AttributeViewPage')

const ThemesPage = () => import('@/views/theme/ThemesPage')
const AddOnPage = () => import('@/views/addOn/AddOnPage')
const TemplateEditorPage = () => import('@/views/template/TemplateEditorPage')

const StoreListPage = () => import('@/views/store/StoreListPage')
const StoreAddEditPage = () => import('@/views/store/StoreAddEditPage')
const StoreViewPage = () => import('@/views/store/StoreViewPage')

const ArticleListPage = () => import('@/views/article/ArticleListPage')
const ArticleAddEditPage = () => import('@/views/article/ArticleAddEditPage')
const ArticleViewPage = () => import('@/views/article/ArticleViewPage')

const PageListForm = () => import('@/views/page/PageListForm')
const PageAddEditForm = () => import('@/views/page/PageAddEditForm')
const PageViewForm = () => import('@/views/page/PageViewForm')

const FaqListForm = () => import('@/views/faq/FaqListForm')
const FaqAddEditForm = () => import('@/views/faq/FaqAddEditForm')
const FaqViewForm = () => import('@/views/faq/FaqViewForm')

const BadgeListPage = () => import('@/views/badge/BadgeListPage')
const BadgeAddEditPage = () => import('@/views/badge/BadgeAddEditPage')
const BadgeViewPage = () => import('@/views/badge/BadgeViewPage')

const GroupListPage = () => import('@/views/group/GroupListPage')
const GroupAddEditPage = () => import('@/views/group/GroupAddEditPage')
const GroupViewPage = () => import('@/views/group/GroupViewPage')

const PaymentMethodListPage = () => import('@/views/paymentMethod/PaymentMethodListPage')
const PaymentMethodPayPalEditPage = () => import('@/views/paymentMethod/PaymentMethodPayPalEditPage')

const ComissionListPage = () => import('@/views/comission/ComissionListPage')
const ComissionEditPage = () => import('@/views/comission/ComissionEditPage')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/category',
    component: MainLayout,
    beforeEnter: beforeEnter,
    children: [
      {
        path: 'category',
        name: 'Category',
        redirect: '/category/list',
        beforeEnter: beforeEnter,
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'list',
            name: 'CategoryList',
            component: CategoryListPage
          },
          {
            path: 'item/:id?',
            name: 'CategoryItem',
            component: CategoryAddEditPage
          },
          {
            path: 'summary/:id?',
            name: 'CategoryView',
            component: CategoryViewPage
          }
        ]
      },
      {
        path: 'product',
        name: 'Product',
        redirect: '/product/list',
        beforeEnter: beforeEnter,
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'list',
            name: 'ProductList',
            component: ProductListPage
          },
          {
            path: 'item/:id?',
            name: 'ProductItem',
            component: ProductAddEditPage
          },
          {
            path: 'summary/:id?',
            name: 'ProductView',
            component: ProductViewPage
          }
        ]
      },
      {
        path: 'label',
        name: 'Label',
        redirect: '/label/list',
        beforeEnter: beforeEnter,
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'list',
            name: 'LabelList',
            component: LabelListPage
          },
          {
            path: 'item/:id?',
            name: 'LabelItem',
            component: LabelAddEditPage
          },
          {
            path: 'summary/:id?',
            name: 'LabelView',
            component: LabelViewPage
          }
        ]
      },
      {
        path: 'sales-order',
        name: 'SalesOrder',
        redirect: '/sales-order/list',
        beforeEnter: beforeEnter,
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'list',
            name: 'SalesOrderList',
            component: SalesOrderListPage
          },
          {
            path: 'item/:id?',
            name: 'SalesOrderItem',
            component: SalesOrderAddEditPage
          },
          {
            path: 'summary/:id?',
            name: 'SalesOrderView',
            component: SalesOrderViewPage
          }
        ]
      },
      {
        path: 'discount',
        name: 'Discounts',
        redirect: '/discount/list',
        beforeEnter: beforeEnter,
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'list',
            name: 'DiscountList',
            component: DiscountListPage
          },
          {
            path: 'item/:id?',
            name: 'DiscountItem',
            component: DiscountAddEditPage
          },
          {
            path: 'summary/:id?',
            name: 'DiscountView',
            component: DiscountViewPage
          }
        ]
      },
      {
        path: 'user',
        name: 'User',
        redirect: '/user/customer/list',
        beforeEnter: beforeEnter,
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          // {
          //   path: 'list',
          //   name: 'UserList',
          //   component: UserListPage
          // },
          // {
          //   path: 'item/:id?',
          //   name: 'UserItem',
          //   component: UserAddEditPage
          // }
          {
            path: 'customer',
            name: 'Customer',
            redirect: '/user/customer/list',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'list',
                name: 'CustomerList',
                component: CustomerListPage
              },
              {
                path: 'item/:id?',
                name: 'CustomerItem',
                component: CustomerAddEditPage
              },
              {
                path: 'summary/:id?',
                name: 'CustomerView',
                component: CustomerViewPage
              }
            ]
          },
          {
            path: 'vendor',
            name: 'Vendor',
            redirect: '/user/vendor/list',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'list',
                name: 'VendorList',
                component: VendorListPage
              },
              {
                path: 'item/:id?',
                name: 'VendorItem',
                component: VendorAddEditPage
              },
              {
                path: 'summary/:id?',
                name: 'VendorView',
                component: VendorViewPage
              }
            ]
          },
          {
            path: 'delivery-person',
            name: 'Delivery Person',
            redirect: '/user/delivery-person/list',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'list',
                name: 'DeliveryPersonList',
                component: DeliveryPersonListPage
              },
              {
                path: 'item/:id?',
                name: 'DeliveryPersonItem',
                component: DeliveryPersonAddEditPage
              },
              {
                path: 'summary/:id?',
                name: 'DeliveryPersonView',
                component: DeliveryPersonViewPage
              }
            ]
          },
          {
            path: 'staff',
            name: 'Staff',
            redirect: '/user/staff/list',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'list',
                name: 'StaffList',
                component: StaffListPage
              },
              {
                path: 'item/:id?',
                name: 'StaffItem',
                component: StaffAddEditPage
              },
              {
                path: 'summary/:id?',
                name: 'StaffView',
                component: StaffViewPage
              }
            ]
          }
        ]
      },
      {
        path: 'storefront',
        name: 'Storefront',
        redirect: '/storefront/theme/themes',
        beforeEnter: beforeEnter,
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'theme',
            name: 'Themes',
            redirect: '/storefront/theme/themes',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'themes',
                name: 'ThemesPage',
                component: ThemesPage
              }
            ]
          },
          {
            path: 'menus',
            name: 'Menus',
            redirect: '/storefront/menus/menu-listing-page',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'menu-listing-page',
                name: 'MenuListingPage',
                component: MenuListingPage
              },
              {
                path: 'item/:id?',
                name: 'MenuSummary',
                component: MenuCreator
              },
              {
                path: 'menu',
                name: 'MenuCreator',
                component: MenuCreator
              }
            ]
          },
          {
            path: 'banner',
            name: 'Banner',
            redirect: '/storefront/banner/list',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'list',
                name: 'BannerList',
                component: BannerListPage
              },
              {
                path: 'item/:id?',
                name: 'BannerItem',
                component: BannerAddEditPage
              },
              {
                path: 'summary/:id?',
                name: 'BannerView',
                component: BannerViewPage
              }
            ]
          },
          {
            path: 'template',
            name: 'Editor',
            redirect: '/storefront/template/editor',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'editor',
                name: 'TemplateEditorPage',
                component: TemplateEditorPage
              }
            ]
          }
        ]
      },
      {
        path: 'content',
        name: 'Content',
        redirect: '/content/article/list',
        beforeEnter: beforeEnter,
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'article',
            name: 'Article',
            redirect: '/content/article/list',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'list',
                name: 'ArticleListPage',
                component: ArticleListPage
              },
              {
                path: 'item/:id?',
                name: 'ArticleItem',
                component: ArticleAddEditPage
              },
              {
                path: 'summary/:id?',
                name: 'ArticleView',
                component: ArticleViewPage
              }
            ]
          },
          {
            path: 'page',
            name: 'Page',
            redirect: '/content/page/list',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'list',
                name: 'PageListForm',
                component: PageListForm
              },
              {
                path: 'item/:id?',
                name: 'PageItem',
                component: PageAddEditForm
              },
              {
                path: 'summary/:id?',
                name: 'PageView',
                component: PageViewForm
              }
            ]
          },
          {
            path: 'faq',
            name: 'Faq',
            redirect: '/content/faq/list',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'list',
                name: 'FaqListForm',
                component: FaqListForm
              },
              {
                path: 'item/:id?',
                name: 'FaqItem',
                component: FaqAddEditForm
              },
              {
                path: 'summary/:id?',
                name: 'FaqView',
                component: FaqViewForm
              }
            ]
          }
        ]
      },
      {
        path: 'store',
        name: 'Stores',
        redirect: '/store/list',
        beforeEnter: beforeEnter,
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'list',
            name: 'StoreListPage',
            component: StoreListPage
          },
          {
            path: 'item/:id?',
            name: 'StoreItem',
            component: StoreAddEditPage
          },
          {
            path: 'summary/:id?',
            name: 'StoreView',
            component: StoreViewPage
          }
        ]
      },
      {
        path: 'settings',
        name: 'Settings',
        redirect: '/settings/general-form',
        beforeEnter: beforeEnter,
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'general-form',
            name: 'General Form',
            component: GeneralForm
          },
          {
            path: 'image-size',
            name: 'Image Size',
            component: ImageSizePage
          },
          {
            path: 'payment-settings',
            name: 'Payment Settings',
            component: PaymentSettingsPage
          },
          {
            path: 'tax',
            name: 'Tax',
            redirect: '/settings/tax/list',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'list',
                name: 'TaxList',
                component: TaxListPage
              },
              {
                path: 'item/:id?',
                name: 'TaxItem',
                component: TaxAddEditPage
              },
              {
                path: 'summary/:id?',
                name: 'TaxView',
                component: TaxViewPage
              }
            ]
          },
          {
            path: 'comission',
            name: 'Comission',
            redirect: '/settings/comission/list',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'list',
                name: 'ComissionList',
                component: ComissionListPage
              },
              {
                path: 'item/:id?',
                name: 'ComissionItem',
                component: ComissionEditPage
              }
            ]
          },
          {
            path: 'payment-method',
            name: 'PaymentMethod',
            redirect: '/settings/payment-method/list',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'list',
                name: 'PaymentMethodList',
                component: PaymentMethodListPage
              },
              {
                path: 'paypal/:id?',
                name: 'PaymentMethodItem',
                component: PaymentMethodPayPalEditPage
              }
            ]
          },
          {
            path: 'currency',
            name: 'Currency',
            redirect: '/settings/currency/list',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'list',
                name: 'CurrencyList',
                component: CurrencyListPage
              },
              {
                path: 'item/:id?',
                name: 'CurrencyItem',
                component: CurrencyAddEditPage
              },
              {
                path: 'summary/:id?',
                name: 'CurrencyView',
                component: CurrencyViewPage
              }
            ]
          },
          {
            path: 'language',
            name: 'Language',
            redirect: '/settings/language/list',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'list',
                name: 'LanguageList',
                component: LanguageListPage
              },
              {
                path: 'item/:id?',
                name: 'LanguageItem',
                component: LanguageAddEditPage
              },
              {
                path: 'summary/:id?',
                name: 'LanguageView',
                component: LanguageViewPage
              }
            ]
          },
          {
            path: 'custom-field',
            name: 'Custom Field',
            redirect: '/settings/custom-field/list',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'list',
                name: 'AttributeList',
                component: AttributeListPage
              },
              {
                path: 'item/:id?',
                name: 'AttributeItem',
                component: AttributeAddEditPage
              },
              {
                path: 'summary/:id?',
                name: 'AttributeView',
                component: AttributeViewPage
              }
            ]
          },
          {
            path: 'badge',
            name: 'Badge',
            redirect: '/settings/badge/list',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'list',
                name: 'BadgeList',
                component: BadgeListPage
              },
              {
                path: 'item/:id?',
                name: 'BadgeItem',
                component: BadgeAddEditPage
              },
              {
                path: 'summary/:id?',
                name: 'BadgeView',
                component: BadgeViewPage
              }
            ]
          },
          {
            path: 'group',
            name: 'Group',
            redirect: '/settings/group/list',
            beforeEnter: beforeEnter,
            component: {
              render (c) {
                return c('router-view')
              }
            },
            children: [
              {
                path: 'list',
                name: 'GroupList',
                component: GroupListPage
              },
              {
                path: 'item/:id?',
                name: 'GroupItem',
                component: GroupAddEditPage
              },
              {
                path: 'summary/:id?',
                name: 'GroupView',
                component: GroupViewPage
              }
            ]
          }
        ]
      },
      {
        path: 'add-on',
        name: 'AddOn',
        redirect: '/add-on/form',
        beforeEnter: beforeEnter,
        component: {
          render (c) {
            return c('router-view')
          }
        },
        children: [
          {
            path: 'form',
            name: 'AddOnPage',
            component: AddOnPage
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/sign-up',
    name: 'SignUpPage',
    component: SignUpPage
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFoundPage
  },
  {
    path: '*',
    component: NotFoundPage
  }
]

const router = new VueRouter({
  mode: 'hash',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

export default router
