<template>
  <label :class="{'required': required }">
    <span><slot/></span>
    <div class="input-group" style="margin-top: 0.5rem">
      <span v-if="required" class="required-bootstrap-field"></span>
      <div class="form-control p-0" style="height: auto">
        <VSelect class="style-chooser" v-bind:value="value" style="height: auto"
                 v-on:input="$emit('input', $event)"
                 ref="lookupRef"
                 @search="searchItem"
                 @search:focus="searchItem"
                 :class="addClass"
                 :disabled="disabled"
                 placeholder="Select"
                 :options="itemList"
                 :multiple="multiple">
          <template #spinner="{ loading }">
            <div v-if="loading" class="loadingio-spinner-spin-9j16vc4v8fu">
              <div class="ldio-sdm0hreb4f">
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
                <div>
                  <div></div>
                </div>
              </div>
            </div>
          </template>
        </VSelect>
      </div>
      <slot name="add-btn"></slot>
    </div>
  </label>
</template>

<script>
import VSelect from 'vue-select'
VSelect.props.components.default = () => ({
  OpenIndicator: {
    render: createElement =>
      createElement('i', {
        class: {
          'fas fa-search text-muted lookup-search-icon': true
        }
      })
  }
})
export default {
  name: 'LookUp',
  props: {
    value: {
      default: () => ''
    },
    url: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    address: {
      type: String,
      default: () => ''
    },
    label: {
      type: String,
      default: () => ''
    },
    customStyle: {
      type: String,
      default: () => ''
    },
    addClass: {
      type: Object
    },
    multiple: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      itemList: [],
      newUrl: this.url,
      filterParams: { params: { name: '', address: '' } }
    }
  },
  methods: {
    searchItem (search) {
      if (search && search.length < 2) {
        return
      }
      this.filterParams.params.name = search
      this.filterParams.params.searchKey = search
      this.filterParams.params.address = this.address
      this.$refs.lookupRef.mutableLoading = true
      this.axios.get(this.newUrl, this.filterParams)
        .then((response) => {
          if (response.data && response.data) {
            this.setLooUpOptions(response.data)
          } else {
            console.log('hhhh')
          }
          this.$refs.lookupRef.mutableLoading = false
        })
    },
    setLooUpOptions (data) {
      this.itemList = data.map(item => ({
        label: item.name,
        value: item.id
      }))
    }
  },
  components: {
    VSelect
  }
}
</script>

<style>
  .style-chooser .vs__dropdown-toggle {
    border: none;
  }
  .is-invalid {
    border: 1px solid #dc3545;
  }
</style>
