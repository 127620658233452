<template>
  <label>
    <span><slot/></span>
    <div style="margin-top: 0.5rem">
      <span v-if="required && customStyle" class="required-bootstrap-field-textArea" :style="customStyle"></span>
      <span v-else-if="required" class="required-bootstrap-field-textArea"></span>
      <b-form-textarea v-if="customStyle"  v-bind="$attrs"
                       :value="value"
                       :class="addClass"
                       :style="customStyle"
                       @input="$emit('input', $event)">
      </b-form-textarea>
      <b-form-textarea v-else v-bind="$attrs"
                       :value="value"
                       :class="addClass"
                       @input="$emit('input', $event)">
      </b-form-textarea>
    </div>
  </label>
</template>

<script>
export default {
  name: 'Textarea',
  props: {
    value: {
      type: String,
      default: () => ''
    },
    customStyle: {
      type: String,
      default: () => ''
    },
    addClass: {
      type: Object
    },
    required: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style>
  .is-invalid {
    border: 1px solid #dc3545;
  }
</style>
