<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit"></i>General Details
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Name</label>
                          <div class="summary-field">{{ item.name }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Identifier</label>
                          <div class="summary-field">{{ item.code }}</div>
                        </b-form-group>
                        <b-row>
                          <b-col sm="6">
                            <b-form-group>
                              <label>From date</label>
                              <div class="summary-field">{{ item.fromDate != null ? item.fromDate[0] + '/' + item.fromDate[1] + '/' + item.fromDate[2] : '' }}</div>
                            </b-form-group>
                          </b-col>
                          <b-col sm="6">
                            <b-form-group>
                              <label>To date</label>
                              <div class="summary-field">{{ item.toDate != null ? item.toDate[0] + '/' + item.toDate[1] + '/' + item.toDate[2] : '' }}</div>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-form-group>
                          <label>Products Display</label>
                          <div class="summary-field">{{ item.productDisplay }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Exclude Out of Stock</label>
                          <div class="summary-field">{{ item.excludeOutStock === true ? 'Yes' : 'No' }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Priority</label>
                          <div class="summary-field">{{ item.priority }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Sort Order</label>
                          <div class="summary-field">{{ item.sortOrder }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Status</label>
                          <div class="summary-field">{{ item.status === 'ACTIVE' ? 'Active' : item.status === 'SCHEDULED' ? 'Scheduled' : item.status === 'INACTIVE' ? 'Inactive' : item.status === 'EXPIRED' ? 'Expired' : '' }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Products</label>
                          <div class="summary-field">{{ item.productNames }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Categories</label>
                          <div class="summary-field">{{ item.categoryNames }}</div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit"></i> Label Design
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="4">
                        <label>Image</label>
                        <div style="margin-top: 0.5rem">
                          <ImageBanner ref="banner" :isSummary="true"/>
                        </div>
                        <div style="margin-top: 0.5rem">
                          <b-form-group>
                            <label>Template</label>
                          </b-form-group>
                        </div>
                        <b-row>
                          <b-col sm="12">
                            <label>Image Size</label>
                          </b-col>
                          <b-col sm="6">
                            <div class="summary-field">{{item.width + 'px'}}</div>
                          </b-col>
                          <b-col sm="6">
                            <div class="summary-field">{{item.height + 'px'}}</div>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Text</label>
                          <div class="summary-field">{{ item.text }}</div>
                        </b-form-group>
                        <b-row>
                          <b-col sm="6">
                            <b-form-group>
                              <label>Font Family</label>
                              <div class="summary-field">{{ item.fontFamily }}</div>
                            </b-form-group>
                          </b-col>
                          <b-col sm="6">
                            <b-form-group>
                              <label>Font Size</label>
                              <div class="summary-field">{{ item.fontSize }}</div>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col sm="6">
                            <label>Text Color</label>
                            <ColourPicker
                              v-model="item.textColour" disabled/>
                          </b-col>
                          <b-col sm="6">
                            <label>Background Color</label>
                            <ColourPicker
                              v-model="item.backgroundColour" disabled/>
                          </b-col>
                        </b-row>
                        <b-form-group>
                          <label>Custom CSS</label>
                          <div class="summary-field">{{ item.customCss }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Position</label>
                          <div class="summary-field">{{ item.position === 'TOP_LEFT' ? 'Top Left' : item.position === 'TOP_CENTER' ? 'Top Center' : item.position === 'TOP_RIGHT' ? 'Top Right' : item.position === 'MIDDLE_LEFT' ? 'Middle Left' : item.position === 'MIDDLE_CENTER' ? 'Middle Center' : item.position === 'MIDDLE_RIGHT' ? 'Middle Right' : item.position === 'BOTTOM_LEFT' ? 'Bottom Left' : item.position === 'BOTTOM_CENTER' ? 'Bottom Center' : item.position === 'BOTTOM_RIGHT' ? 'Bottom Right' : ''}}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Display on</label>
                          <div class="summary-field">{{ item.displayOn === 'ALL_PAGES' ? 'All pages' : item.displayOn === 'HOME_PAGE' ? 'Home page' : item.displayOn === 'CATALOG_PAGE' ? 'Catalog Page' : item.displayOn === 'SYSTEM_PAGES' ? 'System Pages' : ''}}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Customer Group</label>
                          <div class="summary-field">{{ item.customerGroup }}</div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
              <div class="add-edit-fields-action-bar">
                <div class="form-actions">
                  <router-link :to="'/label/item/' + item.id">
                    <b-button type="button" variant="primary" class="mr-2">Edit</b-button>
                  </router-link>
                  <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/label'">
                    Cancel
                  </router-link>
                </div>
              </div>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ImageBanner from '../../components/ImageBanner'
import ColourPicker from 'vue-native-color-picker'

export default {
  name: 'labelAddEditPage',
  data () {
    return {
      item: {
        name: '',
        number: '',
        description: '',
        shortDescription: '',
        sku: '',
        salesPrice: '0',
        purchasePrice: '0',
        weight: '0',
        categoryId: null
      }
    }
  },
  created () {
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('labels/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.$refs.banner.setImages(this.item.images)
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return 'label Summary'
    }
  },
  components: {
    ImageBanner,
    ColourPicker
  }
}
</script>

<style scoped>

</style>
