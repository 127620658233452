<template>
  <div>
    <div class="d-flex flex-column flex-grow-1">
      <div class="animated fadeIn">
        <b-row>
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>General Details
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="6">
                          <TextBox v-model="item.name" @input="onChangeName" required :addClass="{'is-invalid': $v.item.name.$error}">Name</TextBox>
                          <div>
                            <label>Store URL</label>
                            <b-input-group :append="item.currentDomain">
                              <span class="required-bootstrap-field"></span>
                              <b-form-input v-model="item.url" :class="{'is-invalid': $v.item.url.$error}"></b-form-input>
                            </b-input-group>
                          </div>
                        </b-col>
                        <b-col sm="6">
                          <Textarea v-model="item.storeInformation" customStyle="height: 115px">Store Information</Textarea>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row style="padding-top: 20px">
          <b-col lg="6" class="d-flex">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="far fa-address-book mr-2"></i>Store address
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="12">
                          <AddressPanel ref="storeAddress"/>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
          <b-col lg="6">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="far fa-address-book mr-2"></i>Store Settings
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                      <b-row>
                        <b-col sm="6">
                          <div class="form-group">
                            <label>Logo</label>
                            <div class="d-flex justify-content-center p-4">
                              <div class="profile-img-upload">
                                <img v-if="item.logoUrl" :src="item.logoUrl" alt="img">
                                <img v-else src="../../assets/p3.png" alt="img">
                                <input type="file" class="profileImgUpload"
                                       id="profileImgUpload"
                                       @change="onLogoChoose"
                                       accept="image/*">
                                <label for="profileImgUpload">
                                            <span class="profile-img-upload-title">
                                                <i class="fa fa-camera mr-2"></i>
                                                <span>Change</span>
                                            </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </b-col>
                        <b-col sm="6">
                          <div class="form-group">
                            <label>Favicon</label>
                            <div class="d-flex justify-content-center p-4">
                              <div class="profile-img-upload">
                                <img v-if="item.faviconUrl" :src="item.faviconUrl" alt="img">
                                <img v-else src="../../assets/favicon.png" alt="img">
                                <input type="file" class="profileImgUpload"
                                       id="FaviconImgUpload"
                                       @change="onFavIconChoose"
                                       accept="image/*">
                                <label for="FaviconImgUpload">
                                            <span class="profile-img-upload-title">
                                                <i class="fa fa-camera mr-2"></i>
                                                <span>Change</span>
                                            </span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col sm="6">
                          <span>Main Store </span><input type="checkbox" v-model="item.mainStore" value="true"/>
                        </b-col>
                      </b-row>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>Contact details
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="3">
                          <TextBox v-model="item.phone">Phone</TextBox>
                          <TextBox v-model="item.email" placeholder="type..." type="email" style="padding-top: 9px;">
                            Email:
                          </TextBox>
                        </b-col>
                        <b-col sm="3">
                          <label>Youtube</label>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="fab fa-youtube"></i></span>
                            </div>
                            <input v-model="item.youtube" type="text" class="form-control" placeholder="Youtube">
                          </div>
                          <label>Facebook</label>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="fab fa-facebook"></i></span>
                            </div>
                            <input v-model="item.facebook" type="text" class="form-control" placeholder="Facebook">
                          </div>
                        </b-col>
                        <b-col sm="3">
                          <label>Instagram</label>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="fab fa-instagram"></i></span>
                            </div>
                            <input v-model="item.instagram" type="text" class="form-control" placeholder="Instagram">
                          </div>
                          <label>Twitter</label>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="fab fa-twitter"></i></span>
                            </div>
                            <input v-model="item.twitter" type="text" class="form-control" placeholder="Twitter">
                          </div>
                        </b-col>
                        <b-col sm="3">
                          <label>Telegram</label>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="fab fa-telegram"></i></span>
                            </div>
                            <input v-model="item.telegram" type="text" class="form-control" placeholder="Telegram">
                          </div>
                          <label>Tiktok</label>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="fab fa-tiktok"></i></span>
                            </div>
                            <input v-model="item.tiktok" type="text" class="form-control" placeholder="Tiktok">
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row >
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div class="add-edit-fields-action-bar">
                  <div class="form-actions">
                    <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
                    <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/store'">
                      Cancel
                    </router-link>
                  </div>
                </div>
              </b-card>
            </transition>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import AddressPanel from '../../components/AddressPanel'

export default {
  name: 'StoreAddEditPage',
  data () {
    return {
      item: {
        name: '',
        url: '',
        currentDomain: '.mybec.uz',
        storeInformation: '',
        phone: '',
        email: '',
        youtube: '',
        facebook: '',
        instagram: '',
        twitter: '',
        telegram: '',
        tiktok: '',
        mainStore: false,
        faviconId: null,
        faviconUrl: null,
        logoId: null,
        logoUrl: null
      }
    }
  },
  created () {
    this.loadRelatedData()
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      this.axios.get('company/' + 0)
        .then(response => {
          if (response.data && response.data.billAddress) {
            this.$refs.storeAddress.setItem(response.data.billAddress)
          }
          this.$loading.hide()
        })
        .catch(error => {
          if (error.response && error.response.data.status === 400) {
            this.$toastr.error(error.response.data.errors)
          }
          this.$loading.hide()
        })
      return
    }
    this.$loading.show()
    this.axios.get('store/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        if (this.item.storeAddress) {
          this.$refs.storeAddress.setItem(this.item.storeAddress)
        }
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return this.$route.params.id ? 'Edit store' : 'Add store'
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if (this.$v.item.$invalid) {
        this.$v.$touch()
        this.$toastr.error('Please, fill required fields')
        return
      }
      this.$loading.show()
      const _item = { ...this.item }

      const storeAddress = this.$refs.storeAddress.getItem()
      if (storeAddress) {
        _item.storeAddress = storeAddress
      }
      let axios
      if (_item.id) {
        axios = this.axios.put('store', _item)
      } else {
        axios = this.axios.post('store', _item)
      }
      axios.then(response => {
        if (response.status === 200) {
          this.$toastr.success(response.data)
          this.$router.push('/store')
        } else {
          this.$toastr.error(response.data.message)
        }
        this.$loading.hide()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$toastr.error(error.response.data.errors.message)
        }
        this.$loading.hide()
      })
    },
    async loadRelatedData () {
      // this.$loading.show()
      // const [categoryReq] = await Promise.all([
      //   this.axios.get('categories/items')
      // ])
      // if (categoryReq && categoryReq.data) {
      //   this.setSelectedItems(categoryReq.data, 'categories')
      // }
      // this.$loading.hide()
    },
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    },
    onLogoChoose (event) {
      this.selectedFile = event.target.files[0]
      const formData = new FormData()
      formData.append('file', this.selectedFile)

      this.$loading.show()
      this.axios.post('image-banner/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then((response) => {
          if (response.data) {
            this.item.logoId = response.data.id
            this.item.logoUrl = response.data.url
          }
          this.$loading.hide()
        })
    },
    onFavIconChoose (event) {
      this.selectedFile = event.target.files[0]
      const formData = new FormData()
      formData.append('file', this.selectedFile)

      this.$loading.show()
      this.axios.post('image-banner/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
        .then((response) => {
          if (response.data) {
            this.item.faviconId = response.data.id
            this.item.faviconUrl = response.data.url
          }
          this.$loading.hide()
        })
    },
    onChangeName () {
      if (this.item.name) {
        let objectName = ''
        objectName = this.item.name
          .replace(/\u0401/g, 'YO')
          .replace(/\u0419/g, 'I')
          .replace(/\u0426/g, 'TS')
          .replace(/\u0423/g, 'U')
          .replace(/\u041A/g, 'K')
          .replace(/\u0415/g, 'E')
          .replace(/\u041D/g, 'N')
          .replace(/\u0413/g, 'G')
          .replace(/\u0428/g, 'SH')
          .replace(/\u0429/g, 'SCH')
          .replace(/\u0417/g, 'Z')
          .replace(/\u0425/g, 'H')
          .replace(/\u042A/g, '')
          .replace(/\u0451/g, 'yo')
          .replace(/\u0439/g, 'i')
          .replace(/\u0446/g, 'ts')
          .replace(/\u0443/g, 'u')
          .replace(/\u043A/g, 'k')
          .replace(/\u0435/g, 'e')
          .replace(/\u043D/g, 'n')
          .replace(/\u0433/g, 'g')
          .replace(/\u0448/g, 'sh')
          .replace(/\u0449/g, 'sch')
          .replace(/\u0437/g, 'z')
          .replace(/\u0445/g, 'h')
          .replace(/\u044A/g, "'")
          .replace(/\u0424/g, 'F')
          .replace(/\u042B/g, 'I')
          .replace(/\u0412/g, 'V')
          .replace(/\u0410/g, 'a')
          .replace(/\u041F/g, 'P')
          .replace(/\u0420/g, 'R')
          .replace(/\u041E/g, 'O')
          .replace(/\u041B/g, 'L')
          .replace(/\u0414/g, 'D')
          .replace(/\u0416/g, 'ZH')
          .replace(/\u042D/g, 'E')
          .replace(/\u0444/g, 'f')
          .replace(/\u044B/g, 'i')
          .replace(/\u0432/g, 'v')
          .replace(/\u0430/g, 'a')
          .replace(/\u043F/g, 'p')
          .replace(/\u0440/g, 'r')
          .replace(/\u043E/g, 'o')
          .replace(/\u043B/g, 'l')
          .replace(/\u0434/g, 'd')
          .replace(/\u0436/g, 'zh')
          .replace(/\u044D/g, 'e')
          .replace(/\u042F/g, 'Ya')
          .replace(/\u0427/g, 'CH')
          .replace(/\u0421/g, 'S')
          .replace(/\u041C/g, 'M')
          .replace(/\u0418/g, 'I')
          .replace(/\u0422/g, 'T')
          .replace(/\u042C/g, "'")
          .replace(/\u0411/g, 'B')
          .replace(/\u042E/g, 'YU')
          .replace(/\u044F/g, 'ya')
          .replace(/\u0447/g, 'ch')
          .replace(/\u0441/g, 's')
          .replace(/\u043C/g, 'm')
          .replace(/\u0438/g, 'i')
          .replace(/\u0442/g, 't')
          .replace(/\u044C/g, "'")
          .replace(/\u0431/g, 'b')
          .replace(/\u044E/g, 'yu')

        this.item.url = objectName != null && objectName.length > 0 ? objectName.trim().toLocaleLowerCase().replaceAll(' ', '-') : ''
        this.item.url = this.item.url.replaceAll(' ', '')
      }
    }
  },
  validations: {
    item: {
      name: {
        required
      },
      url: {
        required
      },
      email: {
        email
      }
    }
  },
  components: {
    AddressPanel
  }
}
</script>

<style scoped>

</style>
