<template>
  <div>
    <div class="d-flex flex-column flex-grow-1">
      <div class="animated fadeIn">
        <b-row>
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body class="card-themeview">
                <b-collapse id="collapse1" visible>
                  <b-card-body class="card-body-themeview">
                    <div class="add-edit-fields-wrap">
                      <b-row v-for="(row, rowIdx) in list" :key="rowIdx" style="padding-bottom: 20px">
                        <b-col sm="4" v-for="(item, itemIdx) in row" :key="itemIdx">
                          <div class="card-box" :class="{active: item.active}">
                            <div class="card-box-section">
                              <div class="card-box-header">
                                <div class="theme-info">
                                  <div class="text-container">
                                    <h2 class="theme-name">{{ item.name }}</h2>
                                    <p class="theme-tagline">{{ item.description }}</p>
                                  </div>
                                </div>
                                <div class="theme-actions">
                                  <div class="theme-actions-button">
                                    <h5 v-if="item.active" class="card-box-active-text">Active</h5>
                                    <b-button v-else type="button" variant="secondary" class="mr-2" @click="onActive(item)">Activate</b-button>
                                  </div>
                                </div>
                              </div>
                              <div class="card-box-content theme-image">
                                <div class="theme-screenshot">
                                  <img v-if="item.imageUrl" :src="item.imageUrl" alt="Theme screenshot" class="theme-screenshot">
                                </div>
                              </div>
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ThemesPage',
  data () {
    return {
      list: []
    }
  },
  created () {
    this.getThemes()
  },
  methods: {
    getThemes () {
      this.$loading.show()
      this.axios.get('theme')
        .then(response => {
          if (response.data) {
            this.list = response.data.list || []
            if (this.list.length > 0) {
              this.list = this.list.reduce((result, item, idx) => {
                const row = Math.floor(idx / 3)
                result[row] = [].concat((result[row] || []), item)
                return result
              }, [])
            }
          }
          this.$loading.hide()
        })
        .catch(error => {
          if (error.response && error.response.data.status === 400) {
            this.$toastr.error(error.response.data.errors)
          }
          this.$loading.hide()
        })
    },
    onActive (item) {
      this.$loading.show()
      this.axios.post('theme/activate', item)
        .then(response => {
          if (response.status === 200) {
            this.$toastr.success('Activated successfully')
          } else {
            this.$toastr.error(response.data.message)
          }
          this.$loading.hide()
          this.getThemes()
        }).catch(error => {
          if (error.response && error.response.status === 400) {
            this.$toastr.error(error.response.data.errors.message)
          }
          this.$loading.hide()
          this.getThemes()
        })
    }
  }
}
</script>

<style scoped>

</style>
