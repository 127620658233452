<template>
  <div>
    <div class="d-flex flex-column flex-grow-1">
      <div class="animated fadeIn">
        <b-row>
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>General Details
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="6">
                          <b-form-group style="margin-top: 0.5rem">
                            <label>Name</label>
                            <div class="summary-field">{{ item.owner }}</div>
                          </b-form-group>
                          <SelectBox :items="methods" v-model="item.method">Method</SelectBox>
                          <TextBox v-if="item.method=== 'PERCENTAGE'" v-model="item.percentage" inputtype="number">Value</TextBox>
                          <TextBox v-else-if="item.method=== 'FIXED'" v-model="item.fixed" inputtype="number">Value</TextBox>
                          <div v-else-if="item.method === 'PERCENTAGE_FIXED'">
                            <b-row>
                              <b-col sm="6">
                                <TextBox v-model="item.percentage" inputtype="number">Value</TextBox>
                              </b-col>
                              <b-col sm="6">
                                <TextBox v-model="item.fixed" inputtype="number" style="margin-top: 1.2rem;"></TextBox>
                              </b-col>
                            </b-row>
                          </div>
                        </b-col>
                        <b-col sm="6">
                          <SelectBox :items="shippingCostGoesTOes" v-model="item.shippingCostGoesTO">Shipping cost goes to {{item.owner}}</SelectBox>
                          <SelectBox :items="taxGoesTOes" v-model="item.taxGoesTO">Tax goes to {{item.owner}}</SelectBox>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row >
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div class="add-edit-fields-action-bar">
                  <div class="form-actions">
                    <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
                    <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/settings/comission'">
                      Cancel
                    </router-link>
                  </div>
                </div>
              </b-card>
            </transition>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComissionEditPage',
  data () {
    return {
      item: {
        owner: '',
        shippingCostGoesTO: false,
        taxGoesTO: false,
        method: 'PERCENTAGE',
        percentage: null,
        fixed: null
      },
      methods: [
        { text: 'Percentage', value: 'PERCENTAGE' },
        { text: 'Fixed', value: 'FIXED' },
        { text: 'Percentage+Fixed', value: 'PERCENTAGE_FIXED' }
      ],
      taxGoesTOes: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ],
      shippingCostGoesTOes: [
        { text: 'Yes', value: true },
        { text: 'No', value: false }
      ]
    }
  },
  created () {
    this.loadRelatedData()
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('comission/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        if (this.item.method === null) {
          this.item.method = 'PERCENTAGE'
        }
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return this.$route.params.id ? 'Edit Comission' : 'Add Comission'
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      this.$loading.show()
      const _item = { ...this.item }
      let axios
      if (_item.id) {
        if (this.item.method === 'PERCENTAGE') {
          _item.fixed = null
        } else if (this.item.method === 'FIXED') {
          _item.percentage = null
        }
        axios = this.axios.put('comission', _item)
        axios.then(response => {
          if (response.status === 200) {
            this.$toastr.success(response.data)
            this.$router.push('/settings/comission')
          } else {
            this.$toastr.error(response.data.message)
          }
          this.$loading.hide()
        }).catch(error => {
          if (error.response && error.response.status === 400) {
            this.$toastr.error(error.response.data.errors.message)
          }
          this.$loading.hide()
        })
      }
    },
    async loadRelatedData () {
      // this.$loading.show()
      // const [categoryReq] = await Promise.all([
      //   this.axios.get('categories/items')
      // ])
      // if (categoryReq && categoryReq.data) {
      //   this.setSelectedItems(categoryReq.data, 'categories')
      // }
      // this.$loading.hide()
    },
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    }
  }
}
</script>

<style scoped>

</style>
