<template>
  <thead>
  <tr>
    <th v-for="(column, index) in columnConfig"
        :class="{'action-column': column.columnCode === 'action'}" :key="index">
      <template v-if="column.columnCode === 'action'">
        <span>{{ column.columnName }}</span>
      </template>
      <template v-else>
        <span class="is-clickable" style="cursor: pointer"
              @click="toggleSort(column.columnCode)">
          {{ column.columnName }}
        </span>
      </template>
    </th>
  </tr>
  </thead>
</template>

<script>
export default {
  name: 'TableHeader',
  props: {
    columnConfig: {
      type: Array,
      required: true
    },
    sortField: {
      type: String,
      required: true
    },
    order: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      pageSelected: false
    }
  },
  methods: {
    toggleSort (columnCode) {
      this.$emit('sort-update', columnCode)
    }
  }
}
</script>

<style lang="scss" scoped>
  .action-column {
    width: 50px;
  }
</style>
