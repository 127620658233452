import store from '../store'
import router from '../router'
import axios from 'axios'
import VueAxios from 'vue-axios'

import {
  BASE_URL,
  API_SERVER,
  AUTHORIZATION
} from '../constants'

axios.defaults.baseURL = API_SERVER + BASE_URL
axios.interceptors.request.use(config => {
  if (store.getters.currentToken) {
    config.headers.common[AUTHORIZATION] = 'Bearer ' + store.getters.currentToken
  }
  return config
}, error => {
  return Promise.reject(error)
})
axios.interceptors.response.use(response => {
  if (response.status === 401) {
    store.dispatch('resetAuth')
    router.push('/login').catch(e => {})
  }
  return response
}, error => {
  if (!error.response || error.response.status === 401) {
    store.dispatch('resetAuth')
    router.push('/login').catch(e => {})
  }
  return Promise.reject(error)
})

export default {
  install (Vue) {
    Vue.use(VueAxios, axios)
  }
}
