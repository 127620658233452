import { render, staticRenderFns } from "./FaqListForm.vue?vue&type=template&id=594c5043&scoped=true&"
import script from "./FaqListForm.vue?vue&type=script&lang=js&"
export * from "./FaqListForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "594c5043",
  null
  
)

export default component.exports