<template>
  <ckeditor
    @ready="onReady"
    :readOnly="disabled"
    v-model="content"
    :config="editorOption"
  />
</template>

<script>
import $ from 'jquery'
export default {
  name: 'RichTextEditor',
  props: {
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    return {
      content: '',
      editorOption: {
        fontSize_sizes: '12/12px; 14/14px; 16/16px; 18/18px; 20/20px; 22/22px; 24/24px; 26/26px; 28/28px',
        extraPlugins: 'font, justify',
        toolbar: [
          ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript'],
          ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Print', 'Scayt'],
          ['Link', 'Unlink'],
          ['NumberedList', 'BulletedList', '-', 'Blockquote', 'Table'],
          ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
          ['Styles', 'Format', 'Font', 'FontSize'],
          ['Maximize']
        ]
      }
    }
  },
  methods: {
    setData (data) {
      this.content = data
    },
    onReady (instance) {
      instance.on('dialogShow', () => {
        $('.cke_dialog_container')
          .addClass('layout-container')
          .attr('data-background-color', this.$store.getters.getThemeColor)
      })
    }
  }
}
</script>

<style scoped>

</style>
