<template>
  <div class="d-flex flex-column flex-grow-1">
    <ListingComponent class="bg-white"
                      ref="badgeList"
                      :addNewButton="addNewButton"
                      :requestProvider="requestProvider"
                      :columnConfig="columnConfig">
    </ListingComponent>
    <ConfirmationModal ref="confirmationModal"
                       content="Do you really want to delete"
                       @cancel="closeDeleteModal"
                       @yes="removeItem">
    </ConfirmationModal>
  </div>
</template>

<script>
import ListingComponent from '../../components/listing/ListingComponent'
export default {
  name: 'BadgeListPage',
  data () {
    return {
      columnConfig: [
        {
          columnCode: 'action',
          columnName: 'Action',
          value: item => {
            return (
              <b-dropdown variant="button" toggle-class="btn btn-sm btn-light " class="dropdown-options">
                <b-dropdown-item href={'#/settings/badge/summary/' + item.id}>Summary</b-dropdown-item>
                <b-dropdown-item href={'#/settings/badge/item/' + item.id}>Edit</b-dropdown-item>
                <b-dropdown-item onClick={() => this.showDeleteModal(item.id)}>Delete</b-dropdown-item>
              </b-dropdown>
            )
          }
        },
        {
          columnCode: 'icon',
          columnName: 'Icon',
          customizeColumn: true,
          value: item => {
            if (!item.iconUrl) {
              return <img alt='article-image' class='td-centered' src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACcAAAAnBAMAAAB+jUwGAAAAElBMVEXd3d0AAADBwcGlpaWKiopubm7m/BpWAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAQ0lEQVQokWNgGAX0AUpAiEyDAJOxs7EBEg0VdWFwYGYwANNwQRNjpQAGFygNBw5KDiBBMA0308hEAagdTNPEJyMcAAA93wilouyRBgAAAABJRU5ErkJggg=='}/>
            }
            return <img class='td-centered' src={item.iconUrl}/>
          }
        },
        {
          columnCode: 'title',
          columnName: 'Title',
          link: item => {
            return '/settings/badge/summary/' + item.id
          },
          value: item => {
            return item.title
          }
        },
        {
          columnCode: 'appliesTo',
          columnName: 'Applies To',
          value: item => {
            return item.appliesTo === 'PRODUCT' ? 'Products' : item.appliesTo === 'CUSTOMER' ? 'Customers' : item.appliesTo === 'VENDOR' ? 'Vendors' : item.appliesTo === 'DELIVERY_PERSON' ? 'Delivery Persons' : ''
          }
        },
        {
          columnCode: 'createdBy',
          columnName: 'Created by',
          value: item => {
            return item.createdByName
          }
        },
        {
          columnCode: 'createdDate',
          columnName: 'Created date',
          value: item => {
            return item.createdDate != null ? item.createdDate[0] + '/' + item.createdDate[1] + '/' + item.createdDate[2] : ''
          }
        },
        {
          columnCode: 'updatedBy',
          columnName: 'Updated by',
          value: item => {
            return item.lastModifiedByName
          }
        },
        {
          columnCode: 'updatedDate',
          columnName: 'Updated date',
          value: item => {
            return item.lastModifiedDate != null ? item.lastModifiedDate[0] + '/' + item.lastModifiedDate[1] + '/' + item.lastModifiedDate[2] : ''
          }
        }
      ]
    }
  },
  methods: {
    requestProvider (payload) {
      return this.axios.get('badge', payload)
    },
    addNewButton () {
      return (
        <router-link class="btn btn-success mr-3" to="/settings/badge/item">
          <div class="fa fa-plus"></div>
        </router-link>
      )
    },
    showDeleteModal (id) {
      if (!id) return
      this.id = id
      this.$refs.confirmationModal.show()
    },
    closeDeleteModal () {
      this.id = null
      this.$nextTick(() => {
        this.$refs.confirmationModal.hide()
      })
    },
    removeItem () {
      if (!this.id) {
        return
      }
      this.axios.delete('badge/' + this.id)
        .then((response) => {
          this.$refs.badgeList.doRequest()
          if (response.data.success) {
            this.$toastr.success('Successfully deleted')
          }
          this.$refs.confirmationModal.hide()
        })
    }
  },
  components: {
    ListingComponent
  }
}
</script>

<style scoped>

</style>
