<template>
  <div class="login-page-wrapper d-flex align-items-center justify-content-center">
    <b-jumbotron header="" lead="Please enter the login and password">
      <a-form :form="form" layout="vertical" :label-col="{ span: 24 }" :wrapper-col="{ span: 24 }" @submit="onSubmit">
        <TextBoxFormItem name="username" label="Username" required error-message="Please input your username!">
          <a-icon slot="prefix" type="user" style="color:rgba(0,0,0,.25)" />
        </TextBoxFormItem>
        <TextBoxFormItem name="password" label="Password" type="password" required error-message="Please input your password!">
          <a-icon slot="prefix" type="lock" style="color:rgba(0,0,0,.25)" />
        </TextBoxFormItem>
        <a-form-item class="m-0">
          <a-button block type="primary" html-type="submit">
            Submit
          </a-button>
        </a-form-item>
        <div class="my-3 text-center">Don't have an account yet? <span @click="redirectToSignUp" style="color: #1890ff;cursor: pointer">Sign Up Now</span></div>
      </a-form>
    </b-jumbotron>
  </div>
</template>

<script>
import { AUTHORIZATION } from '../constants'
import TextBoxFormItem from '../components/ui/TextBoxFormItem'
export default {
  name: 'LoginPage',
  components: { TextBoxFormItem },
  data () {
    return {
      formLayout: 'vertical',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      username: '',
      password: ''
    }
  },
  created () {
    this.$store.dispatch('resetAuth')
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      this.$loading.show(true)
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch('login', {
            username: values.username,
            password: values.password
          }, { root: true }).then(response => {
            if (response && response.status === 200 && response.data) {
              this.$store.dispatch('fetch',
                localStorage.getItem(AUTHORIZATION), { root: true })
                .then(response => {
                  if (response.status === 200 && response.data) {
                    this.$router.push('/')
                  }
                })
            } else {
              this.$toastr.error(response.data.message || 'Incorrect params')
            }
            this.$loading.hide()
          }, () => {
            this.$loading.hide()
            this.$toastr.error('Incorrect params')
          })
        }
      })
    },
    redirectToSignUp () {
      this.$router.push('/sign-up')
    }
  }
}
</script>

<style scoped>
.login-page-wrapper {
  height: 100vh;
  background-image: url("../../static/images/user-login-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
