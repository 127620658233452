import { render, staticRenderFns } from "./LanguageListPage.vue?vue&type=template&id=4843259e&scoped=true&"
import script from "./LanguageListPage.vue?vue&type=script&lang=js&"
export * from "./LanguageListPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4843259e",
  null
  
)

export default component.exports