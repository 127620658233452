<template>
  <div>
    <div class="d-flex flex-column flex-grow-1">
      <div class="animated fadeIn">
        <b-row>
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>General Details
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="4">
                          <TextBox v-model="item.number" style="padding-top: 20px;" disabled>Number</TextBox>
                          <LookUp style="padding-top: 18px;" url="customers/items" v-model="selectedCustomer" required
                                  :addClass="{'is-invalid': $v.selectedCustomer.$error}">Customer</LookUp>
                        </b-col>
                        <b-col sm="4">
                          <a-form-item label="Date">
                            <a-date-picker class="form-control" size="large" v-model="item.orderDate" />
                          </a-form-item>
                          <SelectBox :items="sources" v-model="selectedSource" required :addClass="{'is-invalid': $v.selectedSource.$error}">Source</SelectBox>
                        </b-col>
                        <b-col sm="4">
                          <LookUp style="padding-top: 20px;" url="currency/items" v-model="selectedCurrency"
                                  required :addClass="{'is-invalid': $v.selectedCurrency.$error}">Currency</LookUp>
                          <SelectBox :items="statuses" v-model="selectedStatus" style="padding-top: 18px;">Status</SelectBox>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="far fa-address-book mr-2"></i>Items Ordered
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="12">
                          <table class="table table-bordered table-sm">
                            <thead class="thead-light">
                            <tr>
                              <th width="4%" scope="col" class="text-center">#</th>
                              <th width="15%" scope="col">Product</th>
                              <th width="15%" scope="col">Description</th>
                              <th width="10%" scope="col">Quantity</th>
                              <th width="10%" scope="col">Price</th>
                              <th width="9%" scope="col">Discount</th>
                              <th width="10%" scope="col">Net Amount</th>
                              <th width="14%" scope="col">Tax Rate</th>
                              <th width="10%" scope="col">Row Total</th>
                              <th width="4%" style="min-width: 90px" scope="col" class="text-center">Action</th>
                            </tr>
                            </thead>
                            <tbody v-for="(orderItem, index) in orderItems" :key="index">
                            <tr>
                              <td class="index text-center" style="vertical-align: middle;"><span>{{ +index + 1 }}</span></td>
                              <td>
                                <LookUp  url="products/items" required v-model="orderItem.product" @input="calculateRow(orderItem, 'product', true)"></LookUp>
                              </td>
                              <td>
                                  <textarea v-model="orderItem.description" class="form-control"></textarea>
                              </td>
                              <td>
                                <TextBox v-model="orderItem.qty" required inputtype="number" placeholder="0" @input="calculateRow(orderItem, 'qty', true)"></TextBox>
                              </td>
                              <td>
                                <TextBox v-model="orderItem.price" required inputtype="number" placeholder="0" @input="calculateRow(orderItem, 'price', true)" ></TextBox>
                              </td>
                              <td>
                                <TextBox v-model="orderItem.discount" inputtype="number" @input="calculateRow(orderItem, 'discount', true)" ></TextBox>
                              </td>
                              <td class="text-center" style="vertical-align: middle;">
                                <span >{{ orderItem.netAmount }}</span>
                              </td>
                              <td>
                                <LookUp url="tax/items" v-model="orderItem.tax" @input="onChangeTax(orderItem)"></LookUp>
                              </td>
                              <td class="text-center" style="vertical-align: middle;">
                                <span>{{ orderItem.total }}</span>
                              </td>

                              <td class="text-center" style="vertical-align: middle;">
                                <b-button variant="success" size="sm" class="m-2" @click="addRow(index)">
                                  <i class="fa fa-plus"></i>
                                </b-button>
                                <b-button variant="danger" size="sm" @click="removeRow(index)">
                                  <i class="fa fa-window-close"></i>
                                </b-button>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row style="padding-top: 20px">
          <b-col lg="6">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="far fa-address-book mr-2"></i>Billing Address
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="12">
                          <AddressPanel ref="billAddress"/>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
          <b-col lg="6">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="far fa-address-book mr-2"></i>Shipping Address
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="12">
                          <AddressPanel ref="shipAddress"/>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fas fa-link mr-2"></i>Attachments
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="12">
                          <AttachmentComponent ref="uploadItem"/>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
                <div class="add-edit-fields-action-bar">
                  <div class="form-actions">
                    <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
                    <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/sales-order'">
                      Cancel
                    </router-link>
                  </div>
                </div>
              </b-card>
            </transition>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import AttachmentComponent from '../../components/ui/AttachmentComponent'
import AddressPanel from '../../components/AddressPanel'

export default {
  name: 'CustomerAddEditPage',
  data () {
    return {
      item: {
        number: '',
        orderDate: new Date()
      },
      selectedSource: 'BACKEND',
      sources: [
        { text: 'Storefront', value: 'STOREFRONT' },
        { text: 'Backend', value: 'BACKEND' }
      ],
      statuses: [
        { text: 'New', value: 'NEW' },
        { text: 'Processing', value: 'PROCESSING' },
        { text: 'Pending Payment', value: 'PENDING_PAYMENT' },
        { text: 'Payment Review', value: 'PAYMENT_REVIEW' },
        { text: 'On Hold', value: 'ON_HOLD' },
        { text: 'Open', value: 'OPEN' },
        { text: 'Completed', value: 'COMPLETED' },
        { text: 'Suspected', value: 'SUSPECTED' },
        { text: 'Canceled', value: 'CANCELLED' }
      ],
      selectedStatus: 'NEW',
      selectedCustomer: null,
      selectedCurrency: null,
      orderItems: [
        { id: null, product: null, productId: null, productName: null, description: null, qty: null, price: null, discount: null, netAmount: 0, tax: null, vatRate: null, total: 0 }
      ],
      orderItem: {
        id: null,
        product: null,
        productId: null,
        productName: null,
        description: null,
        qty: null,
        price: null,
        discount: null,
        netAmount: 0,
        tax: null,
        vatRate: null,
        total: 0
      }
    }
  },
  created () {
    this.loadRelatedData()
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      this.$loading.show()
      this.axios.get('orders/generate-number')
        .then(response => {
          if (response && response.data) {
            this.item.longNumber = response.data.id
            this.item.number = response.data.name
          }
          this.$loading.hide()
        })
        .catch(error => {
          if (error.response && error.response.data.status === 400) {
            this.$toastr.error(error.response.data.errors)
          }
          this.$loading.hide()
        })

      return
    }
    this.$loading.show()
    this.axios.get('orders/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.selectedSource = this.item.source
        this.selectedStatus = this.item.status
        if (this.item.customer) {
          this.selectedCustomer = { label: this.item.customer.firstName + ' ' + this.item.customer.lastName, value: this.item.customer.id }
        }
        if (this.item.currencyId) {
          this.selectedCurrency = { label: this.item.currencyName, value: this.item.currencyId }
        }
        this.$refs.uploadItem.setData(this.item.files)
        if (this.item.billAddress) {
          this.$refs.billAddress.setItem(this.item.billAddress)
        }
        if (this.item.shipAddress) {
          this.$refs.shipAddress.setItem(this.item.shipAddress)
        }
        if (this.item.items) {
          const list = this.item.items.map((item) => {
            const newItem = {
              product: { value: item.productId, label: item.productName },
              description: item.description,
              qty: item.qty + '',
              price: item.price + '',
              netAmount: item.netAmount,
              discount: item.discount + '',
              vatRate: item.vatRate,
              total: item.total
            }
            return newItem
          })
          if (list.length === 0) {
            list.unshift(this.item.items)
          }
          this.orderItems = list
        }
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return this.$route.params.id ? 'Edit Customer' : 'Add Customer'
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if (this.$v.selectedCustomer.$invalid || this.$v.selectedSource.$invalid) {
        this.$v.$touch()
        this.$toastr.error('Please, fill required fields')
        return
      }
      this.$loading.show()
      const _item = { ...this.item }
      _item.status = this.selectedStatus
      _item.source = this.selectedSource
      _item.number = this.item.number
      if (this.selectedCustomer) {
        _item.customerId = this.selectedCustomer.value
      }
      if (this.selectedCurrency) {
        _item.currencyId = this.selectedCurrency.value
      }
      _item.files = this.$refs.uploadItem.fileList
      if (this.orderItems) {
        const list = this.orderItems.map((item) => {
          if (item.product != null && item.product.value) {
            const newItem = {
              productId: item.product != null ? item.product.value : null,
              productName: item.product != null ? item.product.label : null,
              description: item.description,
              qty: item.qty,
              price: item.price,
              netAmount: item.netAmount,
              discount: item.discount,
              vatRate: item.vatRate,
              total: item.total
            }
            return newItem
          }
        })
        if (list.length === 0) {
          list.unshift(this.item.items)
        }
        _item.items = list
      }
      const billAddress = this.$refs.billAddress.getItem()
      if (billAddress) {
        _item.billAddress = billAddress
      }
      const shipAddress = this.$refs.shipAddress.getItem()
      if (shipAddress) {
        _item.shipAddress = shipAddress
      }
      let axios
      if (_item.id) {
        axios = this.axios.put('orders', _item)
      } else {
        axios = this.axios.post('orders', _item)
      }
      axios.then(response => {
        if (response.status === 200) {
          this.$toastr.success(response.data)
          this.$router.push('/sales-order')
        } else {
          this.$toastr.error(response.data.message)
        }
        this.$loading.hide()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$toastr.error(error.response.data.errors.message)
        }
        this.$loading.hide()
      })
    },
    async loadRelatedData () {
      // this.$loading.show()
      // const [categoryReq] = await Promise.all([
      //   this.axios.get('categories/items')
      // ])
      // if (categoryReq && categoryReq.data) {
      //   this.setSelectedItems(categoryReq.data, 'categories')
      // }
      // this.$loading.hide()
    },
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    },
    openModalManufacturer () {
      return this.$refs['manufacturer-modal'].show()
    },
    removeRow (index) {
      if (this.orderItems.length > 1) {
        const itemIndex = this.orderItems[index]
        if (itemIndex) {
          this.orderItems.splice(index, 1)
        }
      } else {
        this.$toastr.error(this.$t('youCannotDeleteYouHave1Message'))
      }
    },
    addRow (index) {
      this.orderItems = [
        ...this.orderItems.slice(0, index + 1),
        Object.assign({}, this.orderItem),
        ...this.orderItems.slice(index + 1)
      ]
    },
    calculateRow (item, field, isCalculation) {
      if (item.product) {
        if (field === 'qty') {
          if (item.qty && item.qty < 0) {
            item.qty = null
          }
        } else if (field === 'price') {
          if (item.price && item.price < 0) {
            item.price = null
          }
        } else if (field === 'discount') {
          if (item.discount && item.discount < 0) {
            item.discount = null
          }
        }
        if (isCalculation) {
          item.netAmount = item.qty != null && item.price != null ? item.qty * item.price : 0
          if (item.discount) {
            item.netAmount = (item.netAmount * (100 - item.discount)) / 100
          }

          item.total = item.vatRate != null ? item.netAmount * (1 + item.vatRate) : item.netAmount
          // if (item.item) {
          //   this.calculation()
          // }
        }
      }
    },
    onChangeTax (item) {
      if (item.tax && item.tax.value) {
        this.axios.get('tax/' + item.tax.value)
          .then(response => {
            if (response.data) {
              item.vatRate = response.data.rate / 100
              this.calculateRow(item, 'tax', true)
            }
            this.$loading.hide()
          })
          .catch(error => {
            if (error.response && error.response.data.status === 400) {
              this.$toastr.error(error.response.data.errors)
            }
            this.$loading.hide()
          })
      } else {
        this.calculateRow(item, 'tax', true)
      }
    }
  },
  validations: {

    selectedCustomer: {
      required
    },
    selectedSource: {
      required
    },
    selectedCurrency: {
      required
    }
  },
  components: {
    AttachmentComponent,
    AddressPanel
  }
}
</script>

<style scoped>

</style>
