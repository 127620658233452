<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i>General Details
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="4">
                        <b-form-group>
                          <label>Type</label>
                          <div class="summary-field">{{ item.type === 'COMPANY' ? 'Company' : item.type === 'INDIVIDUAL' ? 'Individual' : '' }}</div>
                        </b-form-group>
                        <b-form-group v-if="item.type === 'COMPANY'">
                          <label>Company Name</label>
                          <div class="summary-field">{{ item.companyName}}</div>
                        </b-form-group>
                        <b-row v-if="item.type === 'INDIVIDUAL'">
                          <b-col sm="6">
                            <b-form-group>
                              <label>First Name</label>
                              <div class="summary-field">{{ item.firstName}}</div>
                            </b-form-group>
                          </b-col>
                          <b-col sm="6">
                            <b-form-group>
                              <label>Last Name</label>
                              <div class="summary-field">{{ item.lastName}}</div>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-form-group>
                          <label>Phone</label>
                          <div class="summary-field">{{ item.phone}}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Email</label>
                          <div class="summary-field">{{ item.email}}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <b-row>
                          <b-col sm="6">
                            <b-form-group>
                              <label>Username</label>
                              <div class="summary-field">{{ item.username}}</div>
                            </b-form-group>
                          </b-col>
                          <b-col sm="6">
                            <b-form-group>
                              <label>Password</label>
                              <div class="summary-field">{{ item.password}}</div>
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-form-group>
                          <label>Registered date</label>
                          <div class="summary-field">{{ item.registeredDate != null ? item.registeredDate[0] + '/' + item.registeredDate[1] + '/' + item.registeredDate[2] : '' }}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Group</label>
                          <div class="summary-field">{{ item.groupName}}</div>
                        </b-form-group>
                        <b-form-group>
                          <label>Status</label>
                          <div class="summary-field">{{ item.status === 'ACTIVE' ? 'Active' : item.status === 'INACTIVE' ? 'Inactive' : item.status === 'SUSPENDED' ? 'Suspended' : '' }}</div>
                        </b-form-group>
                      </b-col>
                      <b-col sm="4">
                        <label>Image</label>
                        <div style="margin-top: 0.5rem">
                          <ImageBanner ref="banner" :isSummary="true"/>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>
          </transition>
        </b-col>
      </b-row>
      <b-row style="padding-top: 20px">
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fas fa-link mr-2"></i>Attachments
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="12">
                        <AttachmentComponent ref="uploadItem" :isSummary="true"/>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
              <div class="add-edit-fields-action-bar">
                <div class="form-actions">
                  <router-link :to="'/user/vendor/item/' + item.id">
                    <b-button type="button" variant="primary" class="mr-2">Edit</b-button>
                  </router-link>
                  <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/user/vendor'">
                    Cancel
                  </router-link>
                </div>
              </div>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import AttachmentComponent from '../../components/ui/AttachmentComponent'
import ImageBanner from '../../components/ImageBanner'
export default {
  name: 'VendorAddEditPage',
  data () {
    return {
      item: {
        name: ''
      }
    }
  },
  created () {
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('vendors/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.$refs.uploadItem.setData(this.item.files)
        this.$refs.banner.setImages(this.item.images)
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return 'Vendor Summary'
    }
  },
  components: {
    AttachmentComponent,
    ImageBanner
  }
}
</script>

<style scoped>

</style>
