<template>
  <div>
    <div class="d-flex flex-column flex-grow-1">
      <div class="animated fadeIn">
        <b-row>
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>General Details
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="4">
                          <b-form-group>
                            <label>Discount code</label>
                            <div class="summary-field">{{ item.code}}</div>
                          </b-form-group>
                          <b-form-group>
                            <label>Name</label>
                            <div class="summary-field">{{ item.name}}</div>
                          </b-form-group>
                          <b-form-group>
                            <label>Description</label>
                            <div class="summary-field">{{ item.description}}</div>
                          </b-form-group>
                        </b-col>
                        <b-col sm="4">
                          <b-form-group>
                            <label>Type</label>
                            <div class="summary-field">{{
                              item.type === 'PERCENTAGE' ? 'Percentage' : item.type === 'FIXED_AMOUNT' ? 'Fixed Amount' : item.type === 'FREE_SHIPPING' ? 'Free Shipping' : item.type === 'BUY_X_GET_Y' ? 'Buy X get Y' : ''}}</div>
                          </b-form-group>
                          <div v-if="item.type === 'PERCENTAGE'">
                            <b-form-group>
                              <label>Value</label>
                              <div class="summary-field">{{ item.percentage + '%'}}</div>
                            </b-form-group>
                          </div>
                          <div v-else-if="item.type === 'FIXED_AMOUNT'">
                            <b-form-group>
                              <label>Value</label>
                              <div class="summary-field">{{ item.fixedAmount }}</div>
                            </b-form-group>
                          </div>
                          <b-form-group>
                            <label>Provide</label>
                            <div class="summary-field">{{
                              item.provide === 'AUTOMATICALLY' ? 'Automatically' : item.provide === 'BY_DISCOUNT_CODE' ? 'By Discount Code' : ''}}</div>
                          </b-form-group>
                          <b-row>
                            <b-col sm="6">
                              <b-form-group>
                                <label>From date</label>
                                <div class="summary-field">{{ item.fromDate}}</div>
                              </b-form-group>
                            </b-col>
                            <b-col sm="6">
                              <b-form-group>
                                <label>To date</label>
                                <div class="summary-field">{{ item.toDate }}</div>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col sm="4">
                          <div v-if="item.type === 'FREE_SHIPPING'">
                            <b-form-group>
                              <label>Applies To</label>
                              <div class="summary-field">{{ item.appliesTo === 'ALL_COUNTRIES' ? 'All countries' : item.appliesTo === 'SPECIFIC_COUNTRIES' ? 'Specific Countries' : '' }}</div>
                            </b-form-group>
                          </div>
                          <div v-else>
                            <b-form-group>
                              <label>Applies To</label>
                              <div class="summary-field">{{ item.appliesTo === 'ENTIRE_ORDER' ? 'Entire Order' : item.appliesTo === 'SPECIFIC_CATEGORIES' ? 'Specific Categories' : item.appliesTo === 'SPECIFIC_PRODUCTS' ? 'Specific Products' : item.appliesTo === 'SHIPPING_AMOUNT' ? 'Shipping Amount' : '' }}</div>
                            </b-form-group>
                          </div>
                          <div v-if="item.appliesTo === 'SPECIFIC_CATEGORIES'">
                            <b-form-group>
                              <label>Categories</label>
                              <div class="summary-field">{{ item.categoryNames }}</div>
                            </b-form-group>
                          </div>
                          <div v-else-if="item.appliesTo === 'SPECIFIC_PRODUCTS'">
                            <b-form-group>
                              <label>Products</label>
                              <div class="summary-field">{{ item.productNames }}</div>
                            </b-form-group>
                          </div>
                          <div v-else-if="item.appliesTo === 'SPECIFIC_COUNTRIES'">
                            <b-form-group>
                              <label>Countries</label>
                              <div class="summary-field">{{ item.countryNames }}</div>
                            </b-form-group>
                          </div>
                          <b-form-group>
                            <label>Status</label>
                            <div class="summary-field">{{ item.status === 'ACTIVE' ? 'Active' : item.status === 'SCHEDULED' ? 'Scheduled' : item.status === 'INACTIVE' ? 'Inactive' : item.status === 'EXPIRED' ? 'Expired' : '' }}</div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>Requirements, Eligibility and Limits
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="4">
                          <b-form-group>
                            <label>Minimum Requirements</label>
                            <div class="summary-field">{{ item.minRequiredType === 'NONE' ?  'None' : item.minRequiredType === 'MIN_PURCHASE_AMOUNT' ? 'Minimum Purchase Amount' : item.minRequiredType === 'MIN_QTY_ITEMS' ? 'Minimum Quantity of Items' : ''}}</div>
                          </b-form-group>
                        </b-col>
                        <b-col sm="4">
                          <b-form-group>
                            <label>Customer Eligibility</label>
                            <div class="summary-field">{{ item.customerEligibility === 'ALL_CUSTOMER' ?  'All Customers' : item.customerEligibility === 'SPECIFIC_GROUPS' ? 'Specific Groups' : item.customerEligibility === 'SPECIFIC_CUSTOMERS' ? 'Specific Customers' : ''}}</div>
                          </b-form-group>
                        </b-col>
                        <b-col sm="4">
                          <b-form-group>
                            <label>Usage limits</label>
                            <div class="summary-field">{{ item.numberOfTimesUsed != null ? 'Number of times can be used : ' + item.numberOfTimesUsed :   'Number of times can be used : false' }}</div>
                            <div class="summary-field">{{ 'One use per Customer: ' + item.oneUsePerCustomer }}</div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </transition>
          </b-col>
        </b-row>
        <b-row style="padding-top: 20px">
          <b-col lg="12">
            <transition name="fade">
              <b-card no-body>
                <div slot="header">
                  <i class="fa fa-edit mr-2"></i>Labels and Messages
                  <div class="card-header-actions">
                    <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                      <i class="icon-arrow-up"></i>
                    </b-link>
                  </div>
                </div>
                <b-collapse id="collapse1" visible>
                  <b-card-body>
                    <div class="add-edit-fields-wrap">
                      <b-row>
                        <b-col sm="6">
                          <b-form-group>
                            <label>Title</label>
                            <div class="summary-field">{{ item.title }}</div>
                          </b-form-group>
                          <b-form-group>
                            <label>Subtitle</label>
                            <div class="summary-field">{{ item.subtitle }}</div>
                          </b-form-group>
                          <b-form-group>
                            <label>Tag</label>
                            <div class="summary-field">{{ item.tag }}</div>
                          </b-form-group>
                          <b-form-group>
                            <label>Color</label>
                            <div class="summary-field">{{ item.color }}</div>
                          </b-form-group>
                        </b-col>
                        <b-col sm="6">
                          <b-form-group>
                            <label>Success Message</label>
                            <div class="summary-field">{{ item.successMessage }}</div>
                          </b-form-group>
                          <b-form-group>
                            <label>Warning Message</label>
                            <div class="summary-field">{{ item.warningMessage }}</div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                </b-collapse>
                <div class="add-edit-fields-action-bar">
                  <div class="form-actions">
                    <router-link :to="'/discount/item/' + item.id">
                      <b-button type="button" variant="primary" class="mr-2">Edit</b-button>
                    </router-link>
                    <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/discount'">
                      Cancel
                    </router-link>
                  </div>
                </div>
              </b-card>
            </transition>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DiscountViewPage',
  data () {
    return {
      item: {
        name: ''
      }
    }
  },
  created () {
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('discount/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return 'Discount Summary'
    }
  }
}
</script>

<style scoped>

</style>
