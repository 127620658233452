import LookUp from '@/components/ui/LookUp'
import TextBox from '@/components/ui/TextBox'
import Textarea from '@/components/ui/Textarea'
import SelectBox from '@/components/ui/SelectBox'
import MultiSelectBox from '@/components/ui/MultiSelectBox'
import TextBoxFormItem from '@/components/ui/TextBoxFormItem'
import DatePicker from '@/components/ui/DatePicker'

import Render from '@/components/helper/Render'
import ConfirmationModal from '@/components/modal/ConfirmationModal'

export default {
  install (Vue) {
    Vue.component('LookUp', LookUp)
    Vue.component('TextBox', TextBox)
    Vue.component('Textarea', Textarea)
    Vue.component('SelectBox', SelectBox)
    Vue.component('MultiSelectBox', MultiSelectBox)
    Vue.component('Render', Render)
    Vue.component('ConfirmationModal', ConfirmationModal)
    Vue.component('TextBoxFormItem', TextBoxFormItem)
    Vue.component('DatePicker', DatePicker)
  }
}
