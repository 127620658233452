<template>
    <header class="app-header">
      <b-navbar toggleable="lg" type="dark" variant="success">
        <b-navbar-brand href="#">
          <b-button variant="outline-secondary"
                    v-b-toggle.sidebar-1
                    @click="sidebarToggle">
            <i class="fa fa-bars"/>
          </b-button>
        </b-navbar-brand>
        <b-navbar-brand href="#">
          <h2>Logo</h2>
        </b-navbar-brand>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-dropdown  :text="selectedLang" right toggle-class="btn-sm"  class="w-35px">
              <b-dropdown-item @click="() => changeLanguage('ru')">Русский</b-dropdown-item>
              <b-dropdown-item @click="() => changeLanguage('en')">English</b-dropdown-item>
              <b-dropdown-item @click="() => changeLanguage('uz')">O'zbek</b-dropdown-item>
            </b-dropdown>
          </b-navbar-nav>

            <div class="nav-header user-info">
              <b-dropdown right size="lg"  variant="link" toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <img class="user-profile img" src="../../../static/images/no-profile-img.png" alt="img">
                </template>
                <b-dropdown-item v-if="getSiteUrl" target="_blank" :href="'https://' + getSiteUrl">{{ getSiteUrl }}</b-dropdown-item>
                <b-dropdown-item href="#">Profile</b-dropdown-item>
                <b-dropdown-item href="#" @click.prevent="logout">Sign Out</b-dropdown-item>
              </b-dropdown>
            </div>
        </b-collapse>
      </b-navbar>
    </header>
</template>

<script>
import $ from 'jquery'
import { mapGetters } from 'vuex'
export default {
  name: 'HeaderBar',
  data () {
    return {
      selectedLang: 'en',
      show: false
    }
  },
  methods: {
    sidebarToggle () {
      this.show = !this.show
      if (this.show) {
        $('body').addClass('sidebar-lg-show')
      } else {
        $('body').removeClass('sidebar-lg-show')
      }
    },
    logout () {
      this.$router.push('/login')
    },
    changeLanguage (lang) {
      this.selectedLang = lang
    }
  },
  created () {
    this.sidebarToggle()
  },
  computed: {
    ...mapGetters({
      getSiteUrl: 'getSiteUrl',
      getUserFullName: 'getUserFullName'
    })
  }
}
</script>

<style scoped>

</style>
