<template>
  <div class="table-wrapper d-flex flex-column flex-grow-1" v-if="initialized">
    <TableHeaderPanel :loading="loading"
                      :addNewButton="addNewButton"
                      :filterButton="filterButton"
                      @reset="resetPreferences"
                      @update-limit="updateLimit"
                      @search="searchPage"/>
    <div class="table-body-wrapper table-responsive flex-grow-1 d-flex flex-column px-3">
      <FilterComponent :showFilterPanel="showFilterPanel"
                       ref="filterModal"
                       :filterConfig="filterConfig"
                       @cancelButton="onClickFilterButton"
                       @filterSubmit="filterSubmit"/>
      <table class="listing-table">
        <TableHeader :columnConfig="columnConfig"
                     :sortField="sortField"
                     :order="order"
                     @sort-update="updateSort"
                     @select-page="selectPage"
                     ref="header"/>
        <TableBody v-on="$listeners"
                   :list="list"
                   :columnConfig="columnConfig"
                   :start="start"
                   :selected="selected"
                   ref="body"
                   v-if="hasContent"/>
      </table>
    </div>
    <TableFooter class="bottom-controls"
                 :loading="loading"
                 :totalCount="totalCount"
                 :start="start"
                 :limit="limit"
                 :selected="selected"
                 @change-pagination="changePagination"/>
  </div>
</template>

<script>
import TableHeaderPanel from './TableHeaderPanel.vue'
import TableHeader from './TableHeader.vue'
import TableBody from './TableBody.vue'
import TableFooter from './TableFooter.vue'
import FilterComponent from './FilterComponent'

export default {
  name: 'ListingComponent',
  components: {
    TableHeaderPanel,
    TableHeader,
    TableBody,
    TableFooter,
    FilterComponent
  },
  props: {
    showFilterButton: {
      type: Boolean,
      required: false,
      default: () => false
    },
    requestProvider: {
      type: Function,
      required: true
    },
    columnConfig: {
      type: Array,
      required: true,
      default: () => []
    },
    addNewButton: {
      type: Function
    },
    filterConfig: {
      type: Object,
      default: () => {}
    },
    filterOption: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      initialized: false,
      list: [],
      totalCount: 0,
      searchKey: '',
      chatId: null,
      start: 0,
      limit: 20,
      sortField: '',
      order: 0,
      selected: [],
      showModal: false,
      startDate: null,
      endDate: null,
      showFilterPanel: false
    }
  },
  computed: {
    hasContent () {
      return this.totalCount
    }
  },
  created () {
    this.onInitialize()
  },
  methods: {
    onInitialize () {
      this.$nextTick(() => {
        this.initialized = true
      })
      this.doRequest()
    },
    resetPreferences () {
      this.start = 0
      this.searchKey = ''
      this.chatId = null
      this.startDate = null
      this.endDate = null
      this.onInitialize()
    },
    doRequest () {
      this.loading = true
      this.$loading.show()
      this.requestProvider(this.getRequestParams())
        .then(({ data }) => {
          if (data && data.list) {
            this.loading = false

            this.list = data.list
            this.totalCount = data.count || 0
          }
          this.$loading.hide()
        }).catch(() => {
          this.loading = false
          this.$loading.hide()
        })
    },
    getSortOrder () {
      if (this.order === 0) {
        return 'asc'
      }
      return 'desc'
    },
    getRequestParams () {
      const params = {
        start: this.start,
        limit: this.limit,
        name: this.searchKey,
        chatId: this.chatId,
        sortField: this.sortField,
        status: this.$route.params.tabId ? this.$route.params.tabId.toUpperCase() : null,
        sortOrder: this.getSortOrder()
      }
      const _header = this.$refs.headerPanel
      if (_header && _header.filterOption) {
        params[_header.filterOption.code] = _header.params[_header.filterOption.code]
      }
      if (this.$refs.filterModal && this.$refs.filterModal.filterData && this.filterConfig) {
        for (const field of this.filterConfig.fields) {
          if (this.$refs.filterModal.filterData[field.code] && this.$refs.filterModal.filterData[field.toCode] && (field.type === 'DATE_PICKER_FROM_TO')) {
            params[field.code] = this.$refs.filterModal.filterData[field.code]
            params[field.toCode] = this.$refs.filterModal.filterData[field.toCode]
          } else if (this.$refs.filterModal.filterData[field.code] && (field.type === 'DATE_PICKER' || field.type === 'FORM_INPUT')) {
            params[field.code] = this.$refs.filterModal.filterData[field.code]
          } else {
            if (this.$refs.filterModal.filterData[field.code]) {
              params[field.code] = this.$refs.filterModal.filterData[field.code].value
            }
          }
        }
      }
      return { params }
    },
    updateSort (sortField) {
      if (this.sortField === sortField) {
        this.order = this.order === 0 ? 1 : 0
      } else if (sortField === '') {
        this.order = 0
      }
      this.sortField = sortField
      this.doRequest()
    },
    searchPage () {
      this.start = 0

      this.doRequest()
    },
    updateLimit (limit) {
      this.limit = limit

      this.doRequest()
    },
    selectPage (state) {
      this.$refs.body.selectPage(state)
    },
    changePagination (start) {
      this.start = start

      this.doRequest()
    },
    filterSubmit () {
      this.$nextTick(() => {
        this.showFilterPanel = false
        this.doRequest()
      })
    },
    filterButton () {
      if (this.showFilterButton) {
        return <button class="btn btn-secondary mr-2 btn btn-primary" onClick={this.onClickFilterButton}><i class="fas fa-filter"/></button>
      } else {
        return false
      }
    },
    onClickFilterButton () {
      this.showFilterPanel = !this.showFilterPanel
    }
  }
}
</script>

<style scoped>

</style>
