<template>
  <div class="d-flex flex-column flex-grow-1">
    <div class="animated fadeIn">
      <b-row>
        <b-col lg="12">
          <transition name="fade">
            <b-card no-body>
              <div slot="header">
                <i class="fa fa-edit mr-2"></i> General Details
                <div class="card-header-actions">
                  <b-link class="card-header-action btn-minimize" v-b-toggle.collapse1>
                    <i class="icon-arrow-up"></i>
                  </b-link>
                </div>
              </div>
              <b-collapse id="collapse1" visible>
                <b-card-body>
                  <div class="add-edit-fields-wrap">
                    <b-row>
                      <b-col sm="4">
                        <TextBox v-model="item.title" required :addClass="{'is-invalid': $v.item.title.$error}">Title</TextBox>
                        <SelectBox :items="appliesToes" v-model="item.appliesTo">Applies To</SelectBox>
                      </b-col>
                      <b-col sm="4">
                        <Textarea v-model="item.description" customStyle="height: 114px">Description</Textarea>
                      </b-col>
                      <b-col sm="4">
                        <label>Icon</label>
                        <div style="margin-top: 0.5rem">
                          <ImageBanner ref="banner"/>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </b-card-body>
              </b-collapse>
              <div class="add-edit-fields-action-bar">
                <div class="form-actions">
                  <b-button right type="submit" variant="primary" class="mr-3" @click="onSubmit">Save</b-button>
                  <router-link type="cancel" class="btn btn-secondary" variant="secondary" :to="'/settings/badge'">
                    Cancel
                  </router-link>
                </div>
              </div>
            </b-card>
          </transition>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import ImageBanner from '../../components/ImageBanner'

export default {
  name: 'badgeAddEditPage',
  data () {
    return {
      item: {
        title: '',
        description: '',
        appliesTo: ''
      },
      appliesToes: [
        { text: 'Select', value: '' },
        { text: 'Products', value: 'PRODUCT' },
        { text: 'Customers', value: 'CUSTOMER' },
        { text: 'Vendors', value: 'VENDOR' },
        { text: 'Delivery Persons', value: 'DELIVERY_PERSON' }
      ]
    }
  },
  created () {
    // this.loadRelatedData()
    if (!this.$route.params.id || this.$route.params.id <= 0) {
      return
    }
    this.$loading.show()
    this.axios.get('badge/' + this.$route.params.id)
      .then(response => {
        this.item = response.data
        this.$refs.banner.setImages(this.item.icons)
        this.$loading.hide()
      })
      .catch(error => {
        if (error.response && error.response.data.status === 400) {
          this.$toastr.error(error.response.data.errors)
        }
        this.$loading.hide()
      })
  },
  computed: {
    pageName () {
      return this.$route.params.id ? 'Edit Badge' : 'Add Badge'
    }
  },
  methods: {
    onSubmit (event) {
      event.preventDefault()
      if (this.$v.item.$invalid) {
        this.$v.$touch()
        this.$toastr.error('Please, fill required fields')
        return
      }
      this.$loading.show()
      const _item = { ...this.item }
      const bannerItems = this.$refs.banner.getImages()
      if (bannerItems.length > 0) {
        _item.icons = bannerItems
      }
      let axios
      if (_item.id) {
        axios = this.axios.put('badge', _item)
      } else {
        axios = this.axios.post('badge', _item)
      }
      axios.then(response => {
        if (response.status === 200) {
          this.$toastr.success(response.data)
          this.$router.push('/settings/badge')
        } else {
          this.$toastr.error(response.data.message)
        }
        this.$loading.hide()
      }).catch(error => {
        if (error.response && error.response.status === 400) {
          this.$toastr.error(error.response.data.errors.message)
        }
        this.$loading.hide()
      })
    },
    setSelectedItems (items, code) {
      const _items = items.map(item => {
        return { text: item.name, value: item.id }
      })
      _items.unshift({ value: null, text: 'Select' })
      this[code] = _items
    }
  },
  validations: {
    item: {
      title: {
        required
      }
    }
  },
  components: {
    ImageBanner
  }
}
</script>

<style scoped>

</style>
