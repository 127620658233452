<template>
  <div class="main-wrapper">
    <div class="main-body p-3">
      <Spinner v-if="$loading.isShow"
               :class="{'preloader-overlay--white': $loading.isWhite}"
      />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Spinner from '../Spinner'
export default {
  name: 'MainContent',
  components: { Spinner }
}
</script>

<style scoped>

</style>
